import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GATEWAY_API_URL } from "../api";
import { createUserInterface } from "../components/Interfaces/Interfaces";
import { getAuthorizationHeader } from "../utilities/utilities";

export const createUserApi = createApi({
  reducerPath: "createUser",
  baseQuery: fetchBaseQuery({
    baseUrl: GATEWAY_API_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = getAuthorizationHeader();
      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set("authorization", `${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    createUser: builder.mutation({
      query: (body: createUserInterface) => {
        return {
          url: "/api/User/CreatePortalUser",
          method: "post",
          body,
        };
      },
    }),
  }),
});

export const { useCreateUserMutation } = createUserApi;
