// React Library
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
// React Bootstrap Library
import Col from "react-bootstrap/Col";
import { Button, Card, Form } from "react-bootstrap";

// Other Library
import { Field, Formik, FormikErrors, FormikHelpers } from "formik";
import * as Yup from "yup";
import axios from "axios";

// Project File Imports
import {
  PasswordError,
  ResetPasswordInterface,
} from "../Interfaces/Interfaces";
import { checkPasswordMatch, isObjectEmpty } from "../../utilities/utilities";
import {
  ACCEPT_POLICY_ERROR,
  APPLICATION_NAME,
  ERRORS,
  PASSWORD_NOT_MATCHED,
  REQUIRED_ERROR,
  RESET_PASSWORD_ERROR,
} from "../../utilities/constants";
import { useVerifyResetTokenMutation } from "../../services/verifyResetTokenApi";
import { useAppDispatch } from "../../app/hooks";
import { useOktaAuth } from "@okta/okta-react";
import { logout } from "../../features/authSlice";
import { validations } from "../../utilities/validations";
import { GATEWAY_API_URL } from "../../api";
import { TextInputField } from "../Forms/FormFields";

const UpdatePasswordURL = `${GATEWAY_API_URL}/api/User/UpdatePassword`;

const initialValues = {
  password: "",
  confirm_password: "",
};

/**
 * This method will handle the submittion of Create Password form
 * @param values
 * @returns Promise which is having the status of user's creating password activity
 */
const handleSubmit = async (values: ResetPasswordInterface, token: any) => {
  const headers = {
    Accept: "text/plain",
    "Content-Type": "application/json",
  };
  const response = await axios(UpdatePasswordURL, {
    method: "post",
    headers,
    data: {
      applicationName: APPLICATION_NAME,
      newPassword: values.password,
      resetToken: token,
    },
  });
  const { data } = response;
  return data;
};

const PasswordForm = (props: any) => {
  const { token } = props;
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { oktaAuth } = useOktaAuth();
  const [passwordError, setPasswordError] = useState<PasswordError>({
    isError: false,
    message: "",
  });
  const resetError = () => {
    setPasswordError({
      isError: false,
      message: ''
    })
  }
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validations.passwordMatchValidation}
      onSubmit={(
        values: ResetPasswordInterface,
        { setSubmitting }: FormikHelpers<ResetPasswordInterface>
      ) => {
        handleSubmit(values, token)
          .then((data: any) => {
            if (data?.status === "ACTIVE") {
              setPasswordError({
                isError: false,
                message: "",
              });
              dispatch(logout({ oktaAuth, history }));
            } else {
              if (data.errors.length > 0) {
                const errorTypes = data?.errors[0] || "";
                const errorDescription = errorTypes?.description || "";
                setPasswordError({
                  isError: true,
                  message: errorDescription,
                });
              } else {
                setPasswordError({
                  isError: true,
                  message: RESET_PASSWORD_ERROR,
                });
              }
            }
          })
          .catch((e) =>
            setPasswordError({
              isError: true,
              message: RESET_PASSWORD_ERROR,
            })
          );
        setSubmitting(false);
      }}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
        } = props;

        return (
          <Col>
            <Form onSubmit={handleSubmit}>
              <Card
                className="m-0"
                style={{ border: "0px", backgroundColor: "transparent" }}
              >
                <Card.Body className="p-0">
                  <Card.Text>
                    <TextInputField
                      name="password"
                      label="Password:"
                      type="password"
                      onChange={(e: any) => {
                        handleChange(e);
                        resetError();
                      }}
                    />
                    <TextInputField
                      name="confirm_password"
                      label="Confirm Password:"
                      type="password"
                      onChange={(e: any) => {
                        handleChange(e);
                        resetError();
                      }}
                    />
                  </Card.Text>
                </Card.Body>
                <Card.Footer
                  className="text-muted p-0"
                  style={{ border: "0px", backgroundColor: "transparent" }}
                >
                  {passwordError.isError && (
                    <div className="input-feedback">
                      {passwordError.message}
                    </div>
                  )}
                  <Button
                    className="button button--primary"
                    type="submit"
                    disabled={
                      !isObjectEmpty(errors) ||
                      !touched ||
                      passwordError.isError
                    }
                  >
                    Reset Password
                  </Button>
                </Card.Footer>
              </Card>
            </Form>
          </Col>
        );
      }}
    </Formik>
  );
};

export default PasswordForm;
