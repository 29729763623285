import { PasswordError } from "../components/Interfaces/Interfaces";
import { PASSWORD_NOT_MATCHED } from "./constants";

export const isObjectEmpty = (obj: any) => {
    if (obj === null) return true;
    return Object.keys(obj).length === 0
};

export function formatToArray(entries: {
    [key: string]: string;
}): {
    label: string;
    value: string;
}[] {
    return Object.entries(entries).map((entry: string[]) => ({ label: entry[1], value: entry[0] }));
}

export function checkPasswordMatch(values: any, setPasswordError: any, passwordError: PasswordError) {
    if(values.password !== values.confirm_password) {
        setPasswordError({...passwordError, isError: true, message: PASSWORD_NOT_MATCHED});
      } else {
        setPasswordError({...passwordError, isError: false, message: ''})
      }
}


export const oktaTokenStorageKey = 'okta-token-storage';

export const getAuthorizationHeader = () => {
  // get the authentication token from local storage if it exists
  const oktaToken = sessionStorage.getItem(oktaTokenStorageKey);
  const token = oktaToken && JSON.parse(oktaToken)?.accessToken?.accessToken;

  if (!token) return null;

  return `Bearer ${token}`;
};

export const checkDateValidity = (date: string) => {
  if(!date.length) return false;
  if(date.length < 10) return true;
  const dateArray = date.split("-");
  if(Number(dateArray[0]) > 1900 && (Number(dateArray[1]) >= 1 || Number(dateArray[2]) >= 1)) return true;
  return false;
}