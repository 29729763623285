import React from 'react';
import { Button, Modal, ModalProps } from 'react-bootstrap';

export interface TimeExpirationModalProps {
  body?: string | JSX.Element;
  enableCancel?: boolean;
  footer?: string | JSX.Element;
  header?: string | JSX.Element;
  modalProps?: ModalProps;
}

export const TimeExpirationModal = (props: TimeExpirationModalProps) => {
  return (
    <Modal centered size='lg' {...props.modalProps}>
      {props.header && <Modal.Header>{props.header}</Modal.Header>}
      {props.body && <Modal.Body>{props.body}</Modal.Body>}
      {(props.footer || props.enableCancel) && (
        <Modal.Footer>
          {props.footer}
          {props.enableCancel && <Button onClick={props.modalProps?.onHide}>Close</Button>}
        </Modal.Footer>
      )}
    </Modal>
  );
};
