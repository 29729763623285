import React, { useEffect, useState } from "react";

const BAA = (props: any) => {
  return (
    <div className="container link-text">
      <span className="link-paragraph-heading">
        BUSINESS ASSOCIATE AGREEMENT:
      </span>
      <span className="link-paragraph-text">
        <br />
        This Business Associate Agreement (BAA) between CareMetx LLC and you
        (“Covered Entity”) is entered into for the purposes of complying with
        the Health Insurance Portability and Accountability Act of 1996
        (“HIPAA”), Public Law 104-191, the Health Information Technology for
        Economic and Clinical Health Act (the “HITECH Act”), Public Law 111-005,
        and the regulations promulgated thereunder; 45 C.F.R. Parts 160 and Part
        164, Subparts A, C, D and E (Subpart E, together with the definitions in
        Subpart A is known as the “Standards for Privacy of Individually
        Identifiable Health Information” (the “Privacy Rule”) and Subpart C,
        together with the definitions in Subpart A, is known as the “Security
        Standards for the Protection of Electronic Protected Health Information”
        (the “Security Rule”) Subpart D, together with the definitions in
        Subpart A is known as the “Breach Notification Rule” (“Breach
        Notification Rule”) (the Privacy Rule, Breach Notification Rule and the
        Security Rule are collectively called the “Privacy and Security Rules”).
        &nbsp;Use of CareMetx’s tools and services by Covered Entity indicate
        Covered Entities acceptance of the terms and conditions of this BAA, and
        delivery of such tools and performance of such services to Covered
        Entity by CareMetx indicate acceptance of the same by CareMetx.
        <br />
        <br />
      </span>
      <span className="link-paragraph-heading">
        CAREMETX’S RESPONSIBILITIES WITH RESPECT TO USE AND DISCLOSURE OF
        PROTECTED HEALTH INFORMATION (PHI)
      </span>
      <span className="link-paragraph-text">
        . CAREMETX HEREBY AGREES, WITH REGARD TO ITS USE AND/OR DISCLOSURE OF
        THE PHI, TO DO THE FOLLOWING: <br />
        CareMetx may Use and Disclose PHI for the purposes of performing patient
        insurance eligibility, benefit verification, prior authorization
        services, and patient eligibility for financial support services, as
        permitted or required by this BAA or as required by law. CareMetx shall
        not Use or Disclose PHI in any manner that would constitute a violation
        of Subpart E of 45 C.F.R. Part 164 if so Used or Disclosed by Covered
        Entity. Without limiting the generality of the foregoing, CareMetx is
        permitted to (i) Use PHI for the proper management and administration of
        CareMetx; and (ii) Use and Disclose PHI to carry out the legal
        responsibilities of CareMetx, provided that with respect to any such
        disclosure either: (a) the disclosure is required by law; or (b)
        CareMetx obtains reasonable assurances from the person to whom the PHI
        is to be disclosed that such person will hold the PHI in confidence and
        will not Use and further Disclose such PHI except as required by law and
        for the purpose(s) for which it was disclosed by CareMetx to such
        person, and that such person will notify CareMetx of any instances of
        which it is aware in which the confidentiality of the PHI has been
        breached. <br />
        <br />
        To report to Covered Entity, in writing, any material use and/or
        disclosure of PHI by CareMetx that is not permitted or required by this
        agreement of which CareMetx becomes aware; <br />
        To use commercially reasonable efforts to maintain the security of the
        PHI and to prevent its Use and/or Disclosures contrary to this BAA;{" "}
        <br />
        To the extent that CareMetx creates, receives, maintains or transmits
        electronic protected health information (ePHI) as that term is defined
        by the security rule, on behalf of Covered Entity to report to Covered
        Entity any security incident of which CareMetx becomes aware to the
        extent such incidents represent successful unauthorized access, use,
        disclosure, modification, or destruction of information or interference
        with system operations in an information system that contains or has
        access to the ePHI of Covered Entity, and upon request by Covered
        Entity, report all unsuccessful attempts for which CareMetx has records;
        and <br />
        To require all of CareMetx’s subcontractors and agents utilized in
        providing the services which use and/or Disclose the PHI to agree, in
        writing, to adhere to equivalent restrictions and conditions on the Use
        and/or Disclosure of the PHI that apply to CareMetx pursuant to this
        BAA. <br />
        <br />
      </span>
      <span className="link-paragraph-heading">SAFEGUARDS.</span>
      <span className="link-paragraph-text">
        {" "}
        CareMetx shall employ appropriate administrative, technical and physical
        safeguards, consistent with the size and complexity of CareMetx’s
        operations, to protect the confidentiality of PHI and to prevent the Use
        or Disclosure of PHI in any manner inconsistent with the terms of this
        BAA, including meeting the requirements of 45 C.F.R. §§ 164.308,
        164.310, 164.312, 164.314, and 164.316, which includes <br />
        CareMetx’s obligation to have written policies and procedures in place
        to document its administrative, technical and physical safeguards.{" "}
        <br />
        <br />
      </span>
      <span className="link-paragraph-heading">ACCESS REQUESTS.</span>
      <span className="link-paragraph-text">
        {" "}
        CareMetx shall process Covered Entity’s requests to access records in
        the Designated Record Set and identified by Covered Entity so that
        Covered Entity can comply with 45 C.F.R. § 164.524. <br />
        <br />
      </span>
      <span className="link-paragraph-heading">AMENDMENT REQUESTS.</span>
      <span className="link-paragraph-text">
        {" "}
        CareMetx shall process Covered Entity’s requests for amendment of the
        PHI in CareMetx’s possession, solely upon Covered Entity’s request and
        in a manner that allows Covered Entity to comply with 45 C.F.R. §
        164.526 and in a manner that is consistent with the manner in which
        Covered Entity is amending the PHI in Covered Entity’s possession.{" "}
        <br />
        ACCOUNTING OF DISCLOSURES. The Parties agree that CareMetx shall track
        and keep a record of all <br />
        Disclosures of PHI, and that CareMetx shall provide to Covered Entity
        the information necessary for Covered Entity to provide an accounting of
        Disclosures, in a manner compliant with 45 C.F.R. §164.528, to
        individuals who request an accounting. In each case CareMetx shall
        provide at least the following information with respect to each such
        Disclosure: (a) the date of the Disclosure; (b) the name of the entity
        or person who received the PHI; (c) a brief description of the PHI
        disclosed; (d) a brief statement of the purpose of such Disclosure which
        includes an explanation of the basis for such Disclosure. In the event
        that CareMetx receives a request for an accounting directly from an
        individual, CareMetx shall forward such request to Covered Entity in
        writing. <br />
        <br />
      </span>
      <span className="link-paragraph-heading">
        MEET COVERED ENTITY OBLIGATIONS WHERE APPROPRIATE.
      </span>
      <span className="link-paragraph-text">
        {" "}
        If CareMetx will perform a service for Covered Entity that is an
        obligation of Covered Entity under the Privacy Rule, to meet the
        applicable requirements in the performance of that service; <br />
        <br />
      </span>
      <span className="link-paragraph-heading">
        DEIDENTIFICATION/DATA AGGREGATION.
        <br />
      </span>
      <span className="link-paragraph-text">
        CareMetx may de-identify PHI for lawful purposes, so long as such
        de-identification conforms to the requirements of 45 C.F.R. § 164.514,
        as may be amended from time to time. Additionally CareMetx may provide
        data aggregation services relating to the health care operations of
        Covered Entity.
        <br />
        <br />
      </span>
      <span className="link-paragraph-heading">
        REQUESTS FROM SECRETARY OF HEALTH AND HUMAN SERVICES.
      </span>
      <span className="link-paragraph-text">
        {" "}
        If CareMetx receives a request, made by or on behalf of the Secretary of
        the United States Department of Health and Human Services (the
        “Secretary”), requiring CareMetx to make its internal practices, books,
        and records relating to the Use and Disclosure of the PHI created or
        received by CareMetx on behalf of Covered Entity available to the
        Secretary for the purpose of determining Covered Entity’s and/or
        CareMetx’s compliance with HIPAA, then CareMetx shall make its internal
        practices, books and records available to the Secretary or the
        Secretary’s authorized representative. <br />
        <br />
      </span>
      <span className="link-paragraph-heading">MINIMUM NECESSARY.</span>
      <span className="link-paragraph-text">
        {" "}
        Covered Entity shall provide, and CareMetx shall request, Use and
        Disclose, only the minimum amount of PHI necessary to accomplish the
        purpose of the request, Use or Disclosure. The Parties acknowledge that
        the Secretary may issue guidance with respect to the definition of
        “minimum necessary” from time to time, and agree to stay informed of any
        relevant changes to the definition. <br />
        <br />
      </span>
      <span className="link-paragraph-heading">
        REPORTING OF SECURITY BREACHES.
      </span>
      <span className="link-paragraph-text">
        {" "}
        In the event of a “Breach” of any “Unsecured” PHI that CareMetx
        accesses, maintains, retains, modifies, records, stores, destroys, or
        otherwise holds or uses on behalf of Covered Entity, CareMetx shall
        report such Breach to Covered Entity as soon as practicable, but in no
        event later than ten (10) business days after the date on which the
        Breach is discovered. “Breach” shall mean the unauthorized acquisition,
        access, Use, or Disclosure of PHI which compromises the security or
        privacy of such information, except where an unauthorized person to whom
        the information is disclosed would not reasonably have been able to
        retain such information. “Unsecured PHI” shall mean PHI that is not
        rendered unusable, unreadable, or indecipherable to unauthorized
        individuals through the use of a technology or methodology specified by
        the Secretary (e.g., encryption). Notice of a Breach shall include, to
        the extent such information is available: (i) the identification of each
        individual whose PHI has been, or is reasonably believed to have been,
        accessed, acquired, or disclosed during the Breach, (ii) the date of the
        Breach, if known, and the date of discovery of the Breach, (iii) the
        scope of the Breach, and (iv) CareMetx’s response to the Breach. <br />
        <br />
      </span>
      <span className="link-paragraph-heading">
        RESPONSIBILITIES OF COVERED ENTITY.
      </span>
      <span className="link-paragraph-text">
        {" "}
        With regard to the Use and/or Disclosure of the PHI by CareMetx, Covered
        Entity hereby agrees: <br />
        that the Uses and Disclosures of the PHI by CareMetx pursuant to this
        BAA are, at the time of execution and throughout the term of this BAA
        will be, consistent with the form of notice of privacy practices (the
        “Notice”) that Covered Entity provides to individuals pursuant to 45
        C.F.R. § 164.520. <br />
        <ol>
            <li>To notify CareMetx, in
        writing and in a timely manner, of any arrangements permitted or
        required of Covered
        Entity under 45 C.F.R. parts 160 and 164 that may impact in any manner
        the Use and/or Disclosure of the PHI by CareMetx under this BAA
        including, but not limited to, restrictions on Use and/or Disclosure of the PHI as provided for
        in 45 C.F.R. § 164.522 agreed to by Covered Entity, and to hold CareMetx
        harmless from the financial impact of any such agreement by Covered
        Entity; and</li>
        <li>To obtain
        any consent or authorization that may be required under HIPAA or state
        law prior to furnishing the PHI to CareMetx. </li>
        </ol>
      </span>
      <span className="link-paragraph-heading">TERM.</span>
      <span className="link-paragraph-text">
        {" "}
        Unless otherwise terminated as provided below, this BAA shall become
        effective on the Effective Date and shall have a term that shall run
        concurrently with that of any oral or written agreement by CareMetx to
        provide services to Covered Entity and will terminate without any
        further action of the Parties upon the termination of all such
        agreements. <br />
        <br />
      </span>
      <span className="link-paragraph-heading">
        TERMINATION
        <br />
      </span>
      <span className="link-paragraph-text">
        If either Party determines that the other Party has engaged in a pattern
        of activity that constitutes a material breach of the other Party’s
        obligations under this BAA, the non-breaching Party shall, within twenty
        (20) days of that determination, notify the breaching Party and the
        breaching Party shall have thirty (30) days from receipt of that notice
        to cure the breach or end the violation. If the breaching Party fails to
        take reasonable steps to effect such a cure within such a time period,
        the non-breaching Party may terminate all or part of the service
        relationship. In no event shall such termination have any effect on sums
        due from Covered Entity for any services provided by CareMetx under the
        engagement. <br />
        <br />
        Where either Party has knowledge of a material breach by the other
        Party, and cure is not possible, the nonbreaching Party shall terminate
        the portion of the arrangement for Services affected by the breach.{" "}
        <br />
        <br />
      </span>
      <span className="link-paragraph-heading">EFFECT OF TERMINATION.</span>
      <span className="link-paragraph-text">
        {" "}
        Upon the event of termination of this BAA, CareMetx agrees, where
        feasible, to return or destroy the PHI, which CareMetx still maintains
        in any form. Prior to doing so, CareMetx further agrees, to the extent
        feasible, to request the destruction of the PHI that is in the
        possession of its subcontractors or agents. If in CareMetx’s opinion,
        itis not feasible for CareMetx or any subcontractors to return or
        destroy portions of the PHI, CareMetx shall, upon Covered Entity’s
        written request, inform Covered Entity as to the specific reasons that
        make such return or destruction infeasible and limit any further use or
        disclosures to the purposes that make the return or destruction of those
        portions of the PHI infeasible and provide the protections described
        herein to that PHI. <br />
        <br />
      </span>
      <span className="link-paragraph-heading">THIRD PARTY BENEFICIARIES.</span>
      <span className="link-paragraph-text">
        {" "}
        Nothing in this BAA shall be construed to create any third party
        beneficiary rights in any person. <br />
        <br />
      </span>
      <span className="link-paragraph-heading">COUNTERPARTS.</span>
      <span className="link-paragraph-text">
        {" "}
        This BAA may be executed in any number of counterparts, each of which
        shall be deemed an original. Facsimile copies thereof shall be deemed to
        be originals. <br />
        <br />
      </span>
      <span className="link-paragraph-heading">INFORMAL RESOLUTION.</span>
      <span className="link-paragraph-text">
        {" "}
        If any controversy, dispute or claim arises between the Parties with
        respect to this BAA, the Parties shall make good faith efforts to
        resolve such matters informally. <br />
        <br />
      </span>
      <span className="link-paragraph-heading">LIMITATION ON LIABILITY.</span>
      <span className="link-paragraph-text">
        {" "}
        Neither Party shall be liable to the other party for any incidental,
        consequential or punitive damages of any kind or nature, whether such
        liability is asserted on the basis of contract, tort (including
        negligence or strict liability), or otherwise, even if the other Party
        has been advised of the possibility of such loss or damages. <br />
        <br />
      </span>
      <span className="link-paragraph-heading">NOTICES.</span>
      <span className="link-paragraph-text">
        {" "}
        All notices, requests, approvals, demands and other communications
        required or permitted to be given under this BAA shall be in writing and
        delivered either personally, or by certified mail with postage prepaid
        and return receipt requested, or by overnight courier to the party to be
        notified. All communications will be deemed given when received. The
        addresses of the parties shall be as follows; or as otherwise designated
        by any party through notice to the other party: <br />
        <br /> &nbsp; &nbsp; &nbsp;If to CareMetx: <br /> &nbsp; &nbsp; &nbsp;
        &nbsp; &nbsp; &nbsp;CareMetx <br /> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        &nbsp;6931 Arlington Road, Suite 400 <br /> &nbsp; &nbsp; &nbsp; &nbsp;
        &nbsp; &nbsp;Bethesda, MD 20814 <br /> &nbsp; &nbsp; &nbsp; &nbsp;
        &nbsp; &nbsp;Attn: Chief Privacy Officer <br />
        <br />
        Notices to CareMetx may also be sent via e-mail to{" "}
      </span>
      <span className="link-paragraph-text" style={{color: "#24226A", textDecoration: "underline"}}>
      <a href="mailto: privacy@caremetx.com">privacy@caremetx.com</a>
        <br />
      </span>
      <span className="link-paragraph-text">
        <br />
      </span>
      <span className="link-paragraph-heading">INTERPRETATION.</span>
      <span className="link-paragraph-text">
        {" "}
        The provisions of this BAA shall prevail over any provisions in any
        other agreements between CareMetx and Covered Entity that may conflict
        or appear inconsistent with any provision of this BAA. This BAA shall be
        interpreted as broadly as necessary to implement and comply with HIPAA
        and the HITECH Act. The Parties agree that any ambiguity in this BAA
        shall be resolved in favor of a meaning that complies with and is
        consistent with HIPAA and the HITECH Act. <br />
        <br />
      </span>
    </div>
  );
};

export default BAA;
