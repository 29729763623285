import React from "react";
import { ErrorMessage, Field, useField, useFormikContext } from "formik";
import { Typeahead } from "react-bootstrap-typeahead";
import {
  DOBFieldInterface,
  InitialDoseDOBFieldInterface,
  InputFieldInterface,
  TypeaheadInterface,
} from "../Interfaces/Interfaces";
import { AppTooltip } from "../AppTooltip";
import { formatPhoneNumber } from "../../utilities/helper";
import moment from "moment";
import { checkDateValidity } from "../../utilities/utilities";
import { checkUniqueNpi } from "../../services/helper";
import { useState } from "react";
import { DEFAULT_ERROR_MESSAGE, NPI_EXISTS } from "../../utilities/constants";
import { Spinner } from 'react-bootstrap';
// import DatePicker from "react-datepicker";

export const onKeyPress = function (event: any) {
  if (!/^[0-9]{0,10}$/.test(event.key)) {
    event.preventDefault();
  }
}

export const onPaste = function (event: any) {
  if (!/^[0-9]{0,10}$/.test((event.clipboardData).getData('text')?.slice(0, 10))) {
    event.preventDefault();
  }
}


export const TextInputField = (props: InputFieldInterface) => {
  const [field, meta] = useField(props);
  const { className, name, label, optional } = props;
  return (
    <div className="form-group">
      <label className="form-label" htmlFor={name}>
        <span>
          {label}
          {props?.tooltipContent && (
            <AppTooltip content={props?.tooltipContent} placement="top" />
          )}
        </span>
        {optional && <span className="optional-text">(optional)</span>}
      </label>
      <Field
        {...field}
        {...props}
        className={`form-control ${className} ${meta.touched && !!meta.error ? "is-invalid" : ""
          }`}
      />
      <ErrorMessage component="div" name={name} className="invalid-feedback" />
    </div>
  );
};

export const TextNPIInputField = (props: InputFieldInterface) => {
  const [apiError, setApiError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [field, meta] = useField(props);
  let { setIsUniqueNpi } = props;

  const { className, name, label, optional } = props;
  return (
    <div className="form-group">
      <label className="form-label" htmlFor={name}>
        <span>
          {label}
          {props?.tooltipContent && (
            <AppTooltip content={props?.tooltipContent} placement="top" />
          )}
        </span>
        {optional && <span className="optional-text">(optional)</span>}
      </label>
      <Field
        {...field}
        {...props}
        className={`form-control ${className} ${meta.touched && !!meta.error ? "is-invalid" : ""}`}
        onBlurCapture={(e: any) => {
          if (e.target.value.length == 10) {
            setLoading(true);
            checkUniqueNpi(e.target.value).then((response: any) => {
              if (response?.data?.isUniqueNpi) {
                setIsUniqueNpi(true);
                setApiError(null);
              } else {
                setIsUniqueNpi(false);
                setApiError(NPI_EXISTS);
              }
            })
              .catch((e) => {
                setApiError(DEFAULT_ERROR_MESSAGE,);
              })
              .finally(() => {
                setLoading(false);
              });
          }
          else {
            setApiError(null);
          }
        }}
      />
      {loading && <Spinner animation='border' style={{ position: "absolute", top: "204px", left: "570px" }} size='sm' />}
      <ErrorMessage component="div" name={name} className="invalid-feedback" />
      {apiError && <p className="alert alert-warning">{apiError}</p>}
    </div>
  );
};


export const DOBDateInputField = (props: DOBFieldInterface) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(props);
  const { className, name, label, optional, isValidDOB, setIsValidDOB } = props;

  return (
    <div className="form-group">
      <label className="form-label" htmlFor={name}>
        <span>
          {label}
          {props?.tooltipContent && (
            <AppTooltip content={props?.tooltipContent} placement="top" />
          )}
        </span>
        {optional && <span className="optional-text">(optional)</span>}
      </label>
      <Field
        {...field}
        {...props}
        max={moment().format("YYYY-MM-DD")}
        onChange={(e: any) => {
          if (props.type === "date") {
            if (e.target.value.length <= 10) {
              setIsValidDOB(checkDateValidity(e.target.value));
              setFieldValue(name, e.target.value);
            }
          }
        }}
        placeholder={
          props?.placeholder ? props.placeholder : optional ? "optional" : ""
        }
        className={`form-control ${className} ${meta.touched && !!meta.error || (isValidDOB === false) ? "is-invalid" : ""
          }`}
      />
      {(isValidDOB === false) && !meta.error && <div className="invalid-feedback">{'Patient DOB is invalid'}</div>}
      <ErrorMessage component="div" name={name} className="invalid-feedback" />
    </div>
  );
};

export const InitialDoseDateInputField = (props: InitialDoseDOBFieldInterface) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(props);
  const { className, name, label, optional, isValidInitialDose, setIsValidInitialDose } = props;

  return (
    <div className="form-group">
      <label className="form-label" htmlFor={name}>
        <span>
          {label}
          {props?.tooltipContent && (
            <AppTooltip content={props?.tooltipContent} placement="top" />
          )}
        </span>
        {optional && <span className="optional-text">(optional)</span>}
      </label>
      <Field
        {...field}
        {...props}
        onChange={(e: any) => {
          if (props.type === "date") {
            if (e.target.value.length <= 10) {
              setIsValidInitialDose(checkDateValidity(e.target.value));
              setFieldValue(name, e.target.value);
            }
          }
        }}
        placeholder={
          props?.placeholder ? props.placeholder : optional ? "optional" : ""
        }
        className={`form-control ${className} ${meta.touched && !!meta.error || (isValidInitialDose === false) ? "is-invalid" : ""
          }`}
      />
      {(isValidInitialDose === false) && !meta.error && <div className="invalid-feedback">{'Initial Dose Date is invalid'}</div>}
      <ErrorMessage component="div" name={name} className="invalid-feedback" />
    </div>
  );
};

export const FormattedPhoneNumber = (props: any) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(props);
  const { className, name, label, optional } = props;
  return (
    <div className="form-group">
      <label className="form-label" htmlFor={name}>
        <span>
          {label}
          {props?.tooltipContent && (
            <AppTooltip content={props?.tooltipContent} placement="top" />
          )}
        </span>
        {optional && <span className="optional-text">(optional)</span>}
      </label>
      <Field
        {...field}
        {...props}
        value={formatPhoneNumber(field.value)}
        onChange={(e: any) => {
          if (e.target.value.length <= 14) {
            setFieldValue(name, e.target.value);
          }
        }}
        placeholder="(XXX) XXX-XXXX"
        className={`form-control ${className} ${meta.touched && !!meta.error ? "is-invalid" : ""
          }`}
      />
      <ErrorMessage
        component="div"
        name={name}
        className="invalid-feedback"
      />
    </div>
  );
};

export const SelectField = (props: InputFieldInterface) => {
  const [field, meta] = useField(props);
  const { className, name, label, options: opts, optional } = props;
  const options = [{ label: props?.selectDisplayText ?? `Select ${label?.replace(":", "")}`, value: null }, ...opts];
  return (
    <div className="form-group">
      <label className="form-label" htmlFor={name}>
        <span>
          {label}
          {props?.tooltipContent && (
            <AppTooltip content={props?.tooltipContent} placement="top" />
          )}
        </span>
        {optional && <span className="optional-text">(optional)</span>}
      </label>
      <Field
        as="select"
        {...field}
        {...props}
        className={`form-control form-select ${className} ${meta.touched && !!meta.error ? "is-invalid" : ""
          }`}
      >
        {options.map((option: { label?: any; value?: any }) => {
          return (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          );
        })}
      </Field>
      <ErrorMessage component="div" name={name} className="invalid-feedback" />
    </div>
  );
};

export const InputTypeahead = (props: TypeaheadInterface) => {
  const [field, meta, helper] = useField(props);
  return (
    <div className="form-group">
      <label className="form-label" htmlFor={props.name}>
        {props.label}
      </label>
      <Typeahead
        id={props.name}
        multiple={false}
        onChange={(selected: any) => {
          const s = selected.length > 0 ? selected[0] : "";
          helper.setValue(s.value);
        }}
        onInputChange={(text) => helper.setValue(text)}
        onBlur={() => helper.setTouched(true)}
        allowNew={false}
        options={props.options}
        labelKey="label"
        {...(meta.touched && meta.error
          ? { isInvalid: true, className: "is-invalid" }
          : {})}
      />
      <ErrorMessage
        component="div"
        name={props.name}
        className="invalid-feedback"
      />
    </div>
  );
};

export const RadioField = (props: InputFieldInterface) => {
  const [field, meta] = useField(props);
  const { className, name, label, options: opts, optional } = props;
  const options = [{ label: "Select", value: null }, ...opts];
  return (
    <div className="form-group">
      <label className="form-label" htmlFor={name}>
        {label}
        {optional && <span className="optional-text">(optional)</span>}
      </label>
      {opts.map((item: any) => {
        return (
          <label className="label__radio">
            <Field
              className="form-check-input"
              type="radio"
              name={name}
              value={item?.value}
            />
            {item?.label}
          </label>
        );
      })}
      <ErrorMessage component="div" name={name} className="invalid-feedback" />
    </div>
  );
};

export const NPIInputField = (props: InputFieldInterface) => {
  const inputProps = {
    onKeyPress: onKeyPress,
    onPaste: onPaste,
    ...props
  }
  return <TextNPIInputField {...inputProps} />;
}
