import React, {createContext, useState} from "react";
import {RegistrationForm} from "./RegistrationForm";
import {RegistrationBreadcrumb} from "./RegistrationBreadcrumb";
import logo from "./../assets/images/Logo.png"
import {BreadcrumbInterface} from "./Interfaces/Interfaces";
import {ContactUs} from "./ContactUs";
import {Link} from "react-router-dom";
import {Footer} from "../templates/Footer";
import {ViewRenderer} from "./ViewRenderer";
import {EligibilityBreadcrumb} from "./EligibilityBreadcrumb";
import {SideMenu} from "./SideMenu";

export const FormContext = createContext<BreadcrumbInterface>({step: 0});

export const RegistrationWrapper = () => {
    const [step, setStep] = useState<number>(0);
    return (
        <FormContext.Provider value={{step: step, setStep: setStep}}>
            <ViewRenderer
                bodyComponent={(
                    <>
                        <RegistrationForm/>
                        <ContactUs/>
                    </>
                )}
                navComponent={<RegistrationBreadcrumb/>}
            />
        </FormContext.Provider>
    )
}