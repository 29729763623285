// React Library
import React, {useEffect, useState} from "react";
import {Link, useHistory, useParams} from "react-router-dom";
import Col from "react-bootstrap/Col";
import {Card, Form} from "react-bootstrap";
import {OktaAuth} from '@okta/okta-auth-js';

// Other Library
import {Formik, FormikErrors, FormikHelpers} from "formik";
import axios from "axios";

// Project File Imports
import {LoginValuesInterface} from "../Interfaces/Interfaces";
import {isObjectEmpty} from "../../utilities/utilities";
import {
    ACCOUNT_LOCKED_OUT_ERROR,
    APPLICATION_NAME,
    CALLBACK_PATH,
    LOGIN_UNSUCCESSFUL,
    NO_SPACE_ERROR,
    REQUIRED_ERROR,
} from "../../utilities/constants";
import {useGetClientIdQuery, useLoginUserMutation} from "../../services/authApi";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {selectAuth, setUser} from "../../features/authSlice";
import {useOktaAuth} from "@okta/okta-react";
import {TextInputField} from "../Forms/FormFields";


const initialValues = {
    username: "",
    password: "",
};

export const LoginRightPanel = () => {
    const {oktaAuth, authState} = useOktaAuth();
    const [loginError, setLoginError] = useState<string | null>(null);
    const history = useHistory();
    const dispatch = useAppDispatch();
    const {authMappingDetail} = useAppSelector(selectAuth);
    const errorMessage: any = {
        ERROR: LOGIN_UNSUCCESSFUL,
        LOCKED_OUT: ACCOUNT_LOCKED_OUT_ERROR
    }
    const [
        loginUser,
        {
            data: loginData,
            isSuccess: isLoginSuccess,
            isError: isLoginError,
            error: loginErrorMessage,
        },
    ] = useLoginUserMutation();

    /**
     * This method will handle the submittion of login form
     * @param values
     * @returns Promise which is having the status of user's login activity
     */
    const handleSubmit = async (values: LoginValuesInterface) => {
        const {username, password} = values;
        const userName = username;
        const applicationName = APPLICATION_NAME;
        await loginUser({userName, password, applicationName});
    };

    useEffect(() => {
        if (isLoginSuccess) {
            if (loginData?.loginResponse?.status === "SUCCESS") {
                localStorage.setItem("loggin_in", 'true');
                const user = loginData?.loginResponse?._Embedded?.user;
                dispatch(setUser({user, token: loginData?.loginResponse?.sessionToken}))
                setLoginError(null);
                const config = {
                    issuer: `${authMappingDetail?.authUrl}/oauth2/default`,
                    clientId: authMappingDetail?.authClientId,
                    redirectUri: `${window.location.protocol}//${window.location.host}${CALLBACK_PATH}`,
                    scopes: ['openid', 'profile', 'email'],
                    responseType: ['code', 'token', 'id_token'],
                    pkce: true,
                    tokenManager: {
                        autoRenew: true,
                        expireEarlySeconds: 120,
                        storage: 'sessionStorage'
                    }
                };

                const oktaAuth = new OktaAuth(config);

                try {
                    const payload = {
                        sessionToken: loginData?.loginResponse?.sessionToken
                    };
                    oktaAuth.token?.getWithRedirect({
                        responseType: ['code', 'token', 'id_token'],
                        sessionToken: payload.sessionToken ?? ''
                    })
                    .catch(function(err) {
                        // eslint-disable-next-line
                        console.error('Error OKTA login redirect', err)
                    });
                    history.push("/eligibility");
                } catch (error: any) {
                    console.log(error?.message)
                }
            } else {
                setLoginError(loginData?.loginResponse?.status ?? 'ERROR');
            }
        } else if (isLoginError) setLoginError('true');
    }, [isLoginSuccess, loginData, isLoginError]);

    return (
        <Formik
            initialValues={initialValues}
            validate={(values: LoginValuesInterface) => {
                let errors: FormikErrors<LoginValuesInterface> = {};
                if (!values.username) {
                    errors.username = REQUIRED_ERROR;
                }
                if (values.username && values.username.includes(" ")) {
                    errors.username = `Username ${NO_SPACE_ERROR}`
                }
                if (!values.password) {
                    errors.password = REQUIRED_ERROR;
                }
                return errors;
            }}
            onSubmit={(
                values: LoginValuesInterface,
                {setSubmitting}: FormikHelpers<LoginValuesInterface>
            ) => handleSubmit(values)}
        >
            {(props) => {
                const {
                    values,
                    touched,
                    errors,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                } = props;

                return (
                    <Col className="login__wrapper">
                        <h2>Log in to your Account</h2>
                        <Form onSubmit={handleSubmit}>
                            <TextInputField
                                name="username"
                                label="Username:"
                                type="text"
                            />
                            <TextInputField
                                name="password"
                                label="Password:"
                                type="password"
                            />
                            {loginError && (
                                <div className="input-feedback">{errorMessage[loginError] ?? LOGIN_UNSUCCESSFUL}</div>
                            )}
                            <button
                                className="btn button button--primary login__btn"
                                type="submit"
                                disabled={!isObjectEmpty(errors)}>
                                Log in
                            </button>
                            <Link className='d-block hyperlink-color' to="/forgot-password">
                                Forgot Password?
                            </Link>
                            <Link className='d-block hyperlink-color' to="/forgot-username">
                                Forgot Username?
                            </Link>
                            <div className="line-1"></div>
                            <p>
                                Don’t have an account?&nbsp;
                                <Link to="/registration" className="hyperlink-color">
                                    Register Now
                                </Link>
                            </p>
                        </Form>
                    </Col>
                );
            }}
        </Formik>
    );
};
