import React from "react";
import { FormattedPhoneNumber, NPIInputField, SelectField, TextInputField } from "./Forms/FormFields";
import { OrganizationNPITypeahead } from "./Forms/OrganizationNPITypeahead";
import { states } from "../utilities/data";

export interface FormStateInterface {
    touched: any,
    errors: any,
    isSubmitting: any,
    values: any,
    setIsUniqueNpi: any;
}

export const HospitalPharmacyInfoForm = (props: FormStateInterface) => {
    return (
        <div>
            <div className='mb-4'>
                <h2>Register your Organization</h2>
                <p>As the administrator for your Organization, please provide basic information below that will help
                    verify the Organization's account. <i>All fields are required unless noted as optional.</i></p>
            </div>
            <div className='col-6'>
                <NPIInputField
                    name="organizationNPI"
                    label="Organization NPI:"
                    maxLength='10'
                    {...props}
                />
                <TextInputField
                    name="organizationName"
                    label="Organization Name:"
                    type="text"
                />
                <TextInputField
                    name="organizationTaxId"
                    label="Organization Tax ID:"
                    type="text"
                    optional={true}
                />
                <TextInputField
                    name="address"
                    label="Address:"
                    type="text"
                />
                <TextInputField
                    name="address2"
                    label="Address #2:"
                    type="text"
                    optional={true}
                />
                <TextInputField
                    name="city"
                    label="City:"
                    type="text"
                />
                <div className="row">
                    <div className='col-6'>
                        <SelectField
                            name="state"
                            label="State:"
                            options={states}
                        />
                    </div>
                    <div className='col-6'>
                        <TextInputField
                            name="zipCode"
                            label="Zip Code:"
                            type="text"
                            placeholder="XXXXX"
                        />
                    </div>
                </div>
                <FormattedPhoneNumber
                    name="organizationPhoneNumber"
                    label="Phone Number:"
                    type="text"
                    {...props}
                />
                <FormattedPhoneNumber
                    name="faxNumber"
                    label="Fax Number:"
                    type="text"
                    {...props}
                />
            </div>
        </div>
    )
}