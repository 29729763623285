import axios from "axios";
import { GATEWAY_API_URL } from "../api";
import { APPLICATION_NAME } from "../utilities/constants";

export const checkUniqueNpi = async (npiNumber: string) => {
  return await axios.get(GATEWAY_API_URL + "/api/User/IsUniqueNpi", {
    params: { npiNumber },
  });
};

export const checkUniqueEmail = async (emailAddress: string) => {
  return await axios.get(GATEWAY_API_URL + "/api/User/IsUniqueEmail", {
    params: { emailAddress, applicationName: APPLICATION_NAME },
  });
};

export const checkUniqueUsername = async (userName: string) => {
  return await axios.get(GATEWAY_API_URL + "/api/User/IsUniqueUserName", {
    params: { userName, applicationName: APPLICATION_NAME },
  });
};

export const checkUserActivity = () => {
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const isLoggingIn = localStorage.getItem("loggin_in");
  const sesssionOktaToken = JSON.parse(sessionStorage.getItem("okta-token-storage") || "{}");
  if(user.token) {
    if(!('accessToken' in sesssionOktaToken) && isLoggingIn !== 'true') {
      localStorage.clear();
      sessionStorage.removeItem("okta-token-storage")
      window.location.href = window.location.origin + "/login";
    }
  } else {
    localStorage.clear();
    sessionStorage.removeItem("okta-token-storage")
    window.location.href = window.location.origin + "/login";
  }
}
