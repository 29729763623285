import React from "react";
import {FormStateInterface} from "./HospitalPharmacyInfoForm";
import {FormattedPhoneNumber, SelectField, TextInputField} from "./Forms/FormFields";
import {suffix} from "../utilities/data";

export const UserInformation = (props: FormStateInterface) => {
    return (
        <div>
            <div className='mb-4'>
                <h2>Next, we'll create your user account</h2>
                <p>Please fill in the basic information below to set-up your individual user account. <i>All fields are
                    required unless noted as optional.</i></p>
            </div>
            <div className='row'>
                <div className='col-4'>
                    <TextInputField
                        name="firstName"
                        label="First Name:"
                        type="text"
                    />
                </div>

                <div className='col-4'>
                    <TextInputField
                        name="lastName"
                        label="Last Name:"
                        type="text"
                    />
                </div>

                <div className='col-3'>
                    <SelectField
                        name="suffix"
                        label="Suffix:"
                        options={suffix}
                        optional={true}
                    />
                </div>

                <div className='col-4'>
                    <TextInputField
                        name="emailAddress"
                        label="Email Address:"
                        type="text"
                    />
                </div>

                <div className='col-4'>
                    <FormattedPhoneNumber
                        name="userPhoneNumber"
                        label="Phone Number:"
                        type="text"
                    />
                </div>
            </div>
            <div className="row">
                <div className='form-group col-4'>
                    <TextInputField
                        name="username"
                        label="Username:"
                        type="text"
                    />
                </div>

                <p className='col-8 registration-form__info-text'>This will be the administrator for the
                    organization</p>
            </div>
        </div>
    )
}