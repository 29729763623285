// React Imports
import * as React from "react";
import { Card } from "react-bootstrap";
// Project File Imports
import PasswordFormHeader from "../../templates/PasswordFormHeader";
import PasswordForm from "./PasswordForm";
import {PasswordComplexity} from "../PasswordComplexity";

export const ResetPasswordForm = (props: any) => {
  const {token} = props;
  const title = "Forgot your password?";
  const subtitle = `Please, enter your new password and then confirm it. Once you have created your new password, please log in to submit an eligibility request. `;
  const htmlText = `All fields are required unless noted as optional.`;
  return (
    <div className='row'>
      <div className="col-12">
        <PasswordFormHeader title={title} subtitle={subtitle} htmlText={htmlText} />
      </div>
      <div className="col-5">
        <PasswordForm token = {token}/>
      </div>
      <div className="offset-1 col-6">
        <PasswordComplexity/>
      </div>
    </div>
  );
};
