import {createApi,fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import { GATEWAY_API_URL } from '../api';

export const verifyResetTokenApi = createApi({
    reducerPath: 'verifyResetTokenApi',
    baseQuery: fetchBaseQuery({
        baseUrl: GATEWAY_API_URL
    }),
    endpoints: (builder) => ({
        verifyResetToken: builder.mutation({
            query: (body: {resetToken: string, applicationName: string}) => {
                return {
                    url: '/api/User/VerifyResetToken',
                    method: 'post',
                    body
                };
            },
        }),
      }),
});

export const {useVerifyResetTokenMutation} = verifyResetTokenApi;