import React, { createContext, useState } from "react";
import {useOktaAuth} from "@okta/okta-react";
import {useDispatch} from "react-redux";
import { useAppSelector } from "../app/hooks";

import { ViewRenderer } from "../components/ViewRenderer";
import { SideMenu } from "../components/SideMenu";
import { EligibilityForm } from "../components/EligibilityForm";
import { NeedHelp } from "../components/NeedHelp";
import { EligibilityBreadcrumb } from "../components/EligibilityBreadcrumb";
import {
  BreadcrumbInterface,
  EligibilityResult,
} from "../components/Interfaces/Interfaces";
import { useSelector } from "react-redux";
import {PageLogin} from "./PageLogin";
import {REACT_APP_ISLUCKYORANGE, REACT_APP_RELEASE_DATE} from "../utilities/constants";
import { isObjectEmpty } from "../utilities/utilities";
import { logout } from "../features/authSlice";
import { useHistory } from "react-router-dom";
import { LuckyOrange } from "../components/LuckyOrange";
import { PrivateLayout } from "../components/PrivateLayout";

export const EligibilityFormContext = createContext<BreadcrumbInterface>({
    step: 0,
    });

const Homepage = () => {
  const [step, setStep] = useState<number>(0);
  const dispatch = useDispatch();
  const { oktaAuth, authState } = useOktaAuth();
  const history = useHistory();
  const token = JSON.parse(sessionStorage.getItem("okta-token-storage") || "");
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  localStorage.removeItem("loggin_in");
  if (!isObjectEmpty(token) && user?.token) {
    localStorage.setItem("okta-token-storage", JSON.stringify(token));
  } else {
    dispatch(logout({oktaAuth, history}));
  }

  const displayEligibilityForm = () => {
    const releaseDate = new Date(REACT_APP_RELEASE_DATE);
    const date = new Date();
    return date < releaseDate;
  };

  return authState?.isAuthenticated ? (
    <PrivateLayout>
      <EligibilityFormContext.Provider value={{ step: step, setStep: setStep }}>
        {displayEligibilityForm() ? (
          <ViewRenderer
            bodyComponent={<EligibilityComingSoon />}
            navComponent={<SideMenu />}
          />
        ) : (
          <ViewRenderer
            bodyComponent={<HomepageContent />}
            navComponent={<HomepageSidenav />}
          />
        )}
      </EligibilityFormContext.Provider>
    </PrivateLayout>
  ) : authState?.isAuthenticated ? (
    <p>Loading details...</p>
  ) : (
    <PageLogin />
  );
};

const HomepageContent = () => {
  const userProfile = useAppSelector((state) => state?.auth?.user?.profile);
  const isLuckyOrange = Boolean(REACT_APP_ISLUCKYORANGE === 'true');
  return (
    <div className="eligibility">
      {isLuckyOrange && (<LuckyOrange />)}
      <div className="eligibility__welcome-banner alert">
        <h2>
          Welcome back,{" "}
          <span className="user-first-name">{userProfile?.firstName}</span>!
        </h2>
        <p>
          Thank you for returning to the ServierONE Eligibility Ordering Portal.
        </p>
      </div>
      <div className="row">
        <div className="col-8">
          <EligibilityForm />
        </div>
        <div className="offset-1 col-3">
          <NeedHelp />
        </div>
      </div>
    </div>
  );
};

const HomepageSidenav = () => {
  return (
    <>
      <EligibilityBreadcrumb />
      <SideMenu />
    </>
  );
};

const EligibilityComingSoon = () => {
  return (
    <div className='eligibility__coming-soon-wrapper login-left-panel'>
        <h2>Welcome to the ServierONE Eligibility Portal</h2>
        <p>Coming December 1st, you can submit your ONCASPAR Eligibility request to the ServierOne Eligibility Portal.</p>
    </div>
  );
};

export default Homepage;
