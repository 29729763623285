// React Library
import React, {useEffect, useState} from "react";
import {useUserInfoQuery} from "../../services/userInfoApi";
import AssistanceBox from "../../templates/AssistanceBox";
import {Footer} from "../../templates/Footer";
import Sidebar from "../../templates/Sidebar";
import AddUser from "./AddUser";
import ChangePassword from "./ChangePassword";
import UsersGrid from "./UsersGrid";
import {ContactUs} from "../ContactUs";
import EligibilitySpinner from "../../templates/EligibilitySpinner";
import {EligibilityError} from "../EligibilityError";

export const AdminUserWrapper = () => {
    const [isAdmin, setIsAdmin] = useState(false);
    const {
        data: userInfoData,
        isLoading,
        isFetching,
        isSuccess,
        isError,
        error,
    } = useUserInfoQuery("");

    if (isLoading) return <EligibilitySpinner/>;

    if (isError)
        return <EligibilityError />;

    if (isSuccess && userInfoData) {
        const isAdmin = userInfoData?.UserRole === "Admin";
        return (
            <>
                <div>
                    {isAdmin ? <UsersGrid/> : null}
                    <div className="mt-5">{isAdmin ? <AddUser/> : null}</div>
                    <div className="mt-5">
                        <ChangePassword/>
                    </div>
                </div>
                <div className="mt-5">
                    <ContactUs/>
                </div>
            </>
        )
    } else {
        return null;
    }
};
