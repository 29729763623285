import React, { HTMLAttributes, useEffect, useState } from 'react';

const convertMillisecondsToMinSec = (time: number) => {
      // Calculate minutes and seconds
    const totalSeconds = Math.floor(time / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
  
    // Add leading zeros if needed
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
    return formattedMinutes.toString().padStart(2, '0') + ':' + formattedSeconds.toString().padStart(2, '0');
    // Return formatted time
};

interface TimeCountdownProps extends HTMLAttributes<HTMLSpanElement> {
  timeout: number;
  onTimeout?: () => void;
}

export const TimeCountdown = ({ timeout, onTimeout, ...props }: TimeCountdownProps) => {
  const [remainingTime, setRemainingTime] = useState<number>(timeout);

  useEffect(() => {
    let interval: number;

    const clearTimer = () => {
      clearTimeout(interval);
    };

    const startTimer = () => {
      clearTimer();
      interval = window.setTimeout(updateTime, 1000);
    };

    const updateTime = () => {
      setRemainingTime((s) => {
        const newTime = s - 1000;

        if (newTime === 0) {
          onTimeout && onTimeout();
        } else {
          startTimer();
        }

        return newTime;
      });
    };

    startTimer();

    return () => {
      clearTimer();
    };
  }, [onTimeout, timeout]);

  return <span {...props}>{convertMillisecondsToMinSec(remainingTime)} remaining</span>;
};
