// React Imports
import * as React from "react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
// React Bootstrap Imports
import { Card } from "react-bootstrap";
import { AgGridColumn, AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import { EligibilityGrid } from "../../utilities/EligibilityGrid";
import EditUserDetails from "../EditUserDetails/EditUserDetails";
import { GeneralObject } from "../Interfaces/Interfaces";
import { useGetUsersQuery } from "../../services/usersApi";
import { DEFAULT_GET_USER_ERROR } from "../../utilities/constants";
import EligibilitySpinner from "../../templates/EligibilitySpinner";
import { PrivateLayout } from "../PrivateLayout";

const initialValue = { email: '', phone: '' };

const UsersGrid = () => {
  const [formData, setFormData] = useState(initialValue);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  
  const {
    data: getUsersData,
    isLoading,
    isFetching,
    isSuccess,
    isError,
    error,
  } = useGetUsersQuery("");

  const defaultColDef = {
    resizable: true,
    sortable: true,
    width: 150
  };

  const handleClickOpen = (data: any) => {
    setFormData(data);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if(isLoading || !getUsersData) return <EligibilitySpinner />

  if(isError) return <p className="text-danger text-center">{DEFAULT_GET_USER_ERROR}</p>

  return (
    <>
      <h2>Existing Users</h2>
      <div className="ag-theme-alpine" >
      <EligibilityGrid
          rowData={JSON.parse(JSON.stringify(getUsersData?.users || []))}
          pagination>
          <AgGridColumn field='lastName' headerName="Last Name" />
          <AgGridColumn field='firstName' headerName="First Name" />
          <AgGridColumn field='email' headerName="Email" />
          <AgGridColumn field='userName' headerName="User name"/>
          <AgGridColumn
            cellRenderer='IsAdminRenderer'
            colId='isAdmin'
            field='isAdmin'
            filter='false'
            headerName="Admin"
            maxWidth={180}
          />
          <AgGridColumn
            cellRenderer='UserStatusRenderer'
            field='isActive'
            headerName="Status"
            colId='isActive'
            filter='false'
            maxWidth={200}
          />
          <AgGridColumn
            cellRenderer='EditUserRenderer'
            onCellClicked={(params: any) => handleClickOpen(params?.data)}
            filter={false}
            sortable={false}
            headerName="Edit"
          />
        </EligibilityGrid>

        {open && (
        <EditUserDetails
          isLoading={loading}
          error={error}
          handleClose={handleClose}
          data={formData}
        />
      )}
      </div>
    </>
  );
};

export default UsersGrid;
