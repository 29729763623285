export const formatDate = (value?: string) => {
    if (!value || value.length <= 0) return null;
    const dateArray = value.split('-');
    return `${dateArray[1]}/${dateArray[2]}/${dateArray[0]}`
}

export const getCurrentDate = () => {
    const date = new Date();
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
}
