import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfo} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {AppTooltipInterface} from "./Interfaces/Interfaces";



export const AppTooltip = (props: AppTooltipInterface) => {
    const {content, placement} = props;
    const popover = (
        <Popover id="popover-basic">
            <Popover.Body>
                {content}
            </Popover.Body>
        </Popover>
    );

    return <OverlayTrigger trigger={['hover', 'focus']} placement={placement} overlay={popover}>
        <span className='info-icon'>
            <FontAwesomeIcon icon={faInfo}/>
        </span>
    </OverlayTrigger>;
};