import React from 'react';
import { Button } from 'react-bootstrap';
import { ContactUs } from '../components/ContactUs';
import { EligibilityBreadcrumb } from '../components/EligibilityBreadcrumb';
import { EligibilityResult } from '../components/Interfaces/Interfaces';
import { NeedHelp } from '../components/NeedHelp';
import { SideMenu } from '../components/SideMenu';
import { ViewRenderer } from '../components/ViewRenderer';
import { PageLogin } from './PageLogin';
import {useOktaAuth} from "@okta/okta-react";
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { ROUTE_PATHS } from '../utilities/constants';
import { EligibilityFormContext } from './Homepage';
import PrintButton from '../utilities/PrintButton';
import { formatDate, getCurrentDate } from '../utilities/DateFormater';
import { PrivateLayout } from '../components/PrivateLayout';

export type EligibilityResultPageProps = {
    formData: any;
    resultResponse: EligibilityResult;
};

const EligibilityResultPage = () => {
    const { authState } = useOktaAuth();
    const {state} = useLocation<any>();
    const eligibilityResult = state?.eligibilityResult;
    const formData = state?.formData;

    return authState?.isAuthenticated ? (!state || !state?.formData || !state?.eligibilityResult) 
    ? (<Redirect to={ROUTE_PATHS.eligibility} />) : (
            <EligibilityFormContext.Provider value={{ step: 1, setStep: (step) => {} }}>
            <PrivateLayout>
                <ViewRenderer
                    bodyComponent={eligibilityResult && eligibilityResult?.isEligible 
                        ? <EligibilityPositiveView formData={formData} resultResponse={eligibilityResult} /> 
                        : <EligibilityNegativeView formData={formData}  resultResponse={eligibilityResult} />}
                    navComponent={(
                        <>
                            <EligibilityBreadcrumb />
                            <SideMenu />
                        </>
                    )}
                />
            </PrivateLayout>
            </EligibilityFormContext.Provider>
    ) : (authState?.isAuthenticated) ? (
    <p>Loading details...</p>
    ) : (
    <PageLogin />
    )
}
const EligibilityPositiveView = ({formData, resultResponse}: EligibilityResultPageProps) => {
    const eligibilityResult: EligibilityResult = resultResponse;
    return (
        <div className='eligibility-result'>
            <h2 className='highlighted-primary-text'>We've finished processing your request</h2>
            <div>
                <p className="eligibility-result__heading">Based on the answers you've provided, we've determined your patient is <span className='highlighted-primary-text'>eligible</span>! Below is the information that you will need to provide to Cardinal Health, to finish processing this request. Please remember to save the unique Ordering Code for future orders of ONCASPAR.</p>
                <div className='row eligibility-result__body mb-3'>
                    <div className="col-8 table-wrapper">
                        <table className='table-contain'>
                            <tbody>
                                <tr>
                                    <td className='table-contain__label'><strong>Ordering Code:</strong></td>
                                    <td className='table-contain__value highlighted-primary-text'>{eligibilityResult?.orderCode}</td>
                                </tr>
                                <tr>
                                    <td className='table-contain__label'><strong>Patient ID:</strong></td>
                                    <td className='table-contain__value highlighted-primary-text'>{formData?.hospitalId}</td>
                                </tr>
                                <tr>
                                    <td className='table-contain__label'><strong>Date of Birth:</strong></td>
                                    <td className='table-contain__value highlighted-primary-text'>{formatDate(formData?.dataOfBirth)}</td>
                                </tr>
                                {/* <tr>
                                    <td className='table-contain__label'><strong>Drug Prescribed before 12/1/2022:</strong></td>
                                    <td className='table-contain__value highlighted-primary-text'>{formData?.isAdministered ? 'Yes' : 'No'}</td>
                                </tr>
                                <tr>
                                    <td className='table-contain__label'><strong>Date of Initial Dose:</strong></td>
                                    <td className='table-contain__value highlighted-primary-text'>{formatDate(formData?.initialDoseDate)}</td>
                                </tr> */}
                                <tr>
                                    <td className='table-contain__label'><strong>Dosage Quantity by Vials:</strong></td>
                                    <td className='table-contain__value highlighted-primary-text'>{formData?.totalAnticipatedDose}</td>
                                </tr>
                                <tr>
                                    <td className='table-contain__label'><strong>Eligibility Determination Date:</strong></td>
                                    <td className='table-contain__value highlighted-primary-text'>{getCurrentDate()}</td>
                                </tr>
                                <tr>
                                    <td className='table-contain__label'><strong>Ordering Code Expiration Date:</strong></td>
                                    <td className='table-contain__value highlighted-primary-text'>{eligibilityResult?.orderingCodeExpirationDate}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="offset-1 col-3 col-md-3">
                        <NeedHelp />
                        <div className="row">
                            <div className="col-8">
                                <PrintButton />
                                {/* <Button className="button button--primary button--dense" type="button">
                                    Print
                                </Button> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="eligibility-result__footer">
                    <div>
                        <p>
                            To finish placing your order, please <strong>call Cardinal Health at (855) 740-1867</strong>. 
                            <br /><br />For those who are new to working with Cardinal Health Specialty Pharmaceutical Distribution (SPD), please call (855) 740-1867 to set up an account.
                        </p>
                    </div>
                    <p className="primary-color"><strong>Provide Cardinal Health with the Ordering Code above and they will guide you the rest of the way.</strong></p>
                    <p>
                        <strong>For residents of Puerto Rico</strong>, you can place your order by calling Cardinal Health Puerto Rico at (787) 625-4200. You can also contact them by emailing <a href="mailto: gmb-pr-cuserv@cardinalhealth.com" className='hyperlink-color'>gmb-pr-cuserv@cardinalhealth.com</a>, or fax them at (787) 625-4398.
                    </p>
                </div>
                <ContactUs />
            </div>
        </div>
    )
}
const EligibilityNegativeView = ({formData, resultResponse}: EligibilityResultPageProps) => {
    const eligibilityResult: EligibilityResult = resultResponse;
    return (
        <div className='eligibility-result'>
            <h2 className='highlighted-primary-text'>We've finished processing your request</h2>
            <div>
                <p className="eligibility-result__heading">Based on the answers you've provided, we've determined your patient is currently <span className='highlighted-secondary-text'>not eligible</span>.</p>
                <div className='row eligibility-result__body mb-3'>
                    <div className="col-8 table-wrapper">
                        <table className='table-contain'>
                            <tbody>
                                <tr>
                                    <td className='table-contain__label'><strong>Patient ID:</strong></td>
                                    <td className='table-contain__value highlighted-primary-text'>{formData?.hospitalId}</td>
                                </tr>
                                <tr>
                                    <td className='table-contain__label'><strong>NPI #:</strong></td>
                                    <td className='table-contain__value highlighted-primary-text'>{eligibilityResult?.npi}</td>
                                </tr>
                                <tr>
                                    <td className='table-contain__label'><strong>Dosage Quantity by Vials:</strong></td>
                                    <td className='table-contain__value highlighted-primary-text'>{formData?.totalAnticipatedDose}</td>
                                </tr>
                                <tr>
                                    <td className='table-contain__label'><strong>Eligibility Determination Date:</strong></td>
                                    <td className='table-contain__value highlighted-primary-text'>{getCurrentDate()}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="offset-1 col-3">
                        <NeedHelp />
                    </div>
                </div>
                <div className="eligibility-result__footer">
                    <h2 className='highlighted-primary-text'>If you have additional questions or need assistance</h2>
                    <div>
                        <p>
                            <strong>Call ServierONE at (800) 813-5905</strong>. 
                            <br /><br />Please provide us with the information above and one of our representatives can ensure this eligibility result is correct. You can also visit our <a href="https://www.servierone.com/s/hcp/oncaspar/oncaspar-ordering" target="_blank" className='hyperlink-color'>FAQ's</a> for additional information.
                        </p>
                    </div>
                </div>
                <ContactUs />
            </div>
        </div>
    );
}

export default EligibilityResultPage;
