import {createApi,fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import { GATEWAY_API_URL } from '../api';
import { getAuthorizationHeader } from '../utilities/utilities';

export const usersEligibilityListApi = createApi({
    reducerPath: 'usersEligibilityListApi',
    baseQuery: fetchBaseQuery({
        baseUrl: GATEWAY_API_URL,
        prepareHeaders: (headers, { getState }) => {
          const token = getAuthorizationHeader();
          if (token) headers.set("authorization", `${token}`);
          return headers;
        },
      }),
      endpoints: (builder) => ({
        eligibilityList: builder.query({
            query: (arg) => `/api/User/PatientEligibilityDetails`,
          })
      }),
});

export const {useEligibilityListQuery} = usersEligibilityListApi;