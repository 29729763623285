// React Library
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "../../app/hooks";
import { useVerifyResetTokenMutation } from "../../services/verifyResetTokenApi";
// Project File Imports
import AssistanceBox from "../../templates/AssistanceBox";
import EligibilitySpinner from "../../templates/EligibilitySpinner";
import { Footer } from "../../templates/Footer";
import Sidebar from "../../templates/Sidebar";
import { APPLICATION_NAME } from "../../utilities/constants";
import { ResetPasswordForm } from "./ResetPasswordForm";
import {ContactUs} from "../ContactUs";
import {ViewRenderer} from "../ViewRenderer";

export const ResetPasswordWrapper = () => {
  const [tokenStatus, setTokenStatus] = useState("");
  const { token } = useParams<{ token: string }>();
  const [verifyResetToken, { data, isSuccess, isError, error, isLoading }] =
    useVerifyResetTokenMutation();
  const verifyToken = async () => {
    await verifyResetToken({
      applicationName: APPLICATION_NAME,
      resetToken: token || "",
    });
  };

  useEffect(() => {
    if (isSuccess) {
      setTokenStatus(data.tokenStatus);
    }
  }, [isSuccess]);

  useEffectOnce(() => {
    if (token) {
      verifyToken();
    }
  });

  if (isLoading) return <EligibilitySpinner />;

  if (error) {
    return (
      <p className="text-center">There is an error in fetching details. Please reload the screen</p>
    );
  }

  if (tokenStatus === null)
    return <h2 className="text-danger text-center">Token Expired</h2>;

  return <ViewRenderer bodyComponent={(
      <>
        {isLoading === false && <ResetPasswordForm token={token} />}
        <div className='mt-5'>
          <ContactUs />
        </div>
      </>
    )}
  />;
};
