// React Library
import React, {useEffect} from "react";

// React Bootstrap Library
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import {Link, useHistory} from "react-router-dom";

import "../styles/main.scss";
import {LoginLeftPanel, LoginRightPanel} from "../components/LoginComponent";
import {Footer} from "../templates/Footer";
import {ROUTE_PATHS} from "../utilities/constants";
import logo from "../assets/images/Logo.png";

export const PageLogin = () => {
    let history = useHistory();
    const token = sessionStorage.getItem("okta-token-storage");
    const user = JSON.parse(localStorage.getItem("user") || "{}"); 

    if (token && user?.token) {
        history.push(ROUTE_PATHS.eligibility);
    }
    return (
        <div className='layout'>
            <div className='layout__wrapper'>
                <div className='layout__body offset-1 col-7 pb-0 login-left-panel'>
                    <LoginLeftPanel/>
                </div>
                <div className='layout__nav col-4 p-0 sticky-top'>
                    <div className='layout__content'>
                        <LoginRightPanel/>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </div>
    );
};
