import React from "react";
import {ViewRenderer} from "../components/ViewRenderer";
import {SideMenu} from "../components/SideMenu";
import {EligibilityError} from "../components/EligibilityError";
import { PrivateLayout } from "../components/PrivateLayout";

export const EligibilityErrorPage = () => {
    return <PrivateLayout> 
                <ViewRenderer
                bodyComponent={<EligibilityError/>}
                navComponent={<SideMenu/>}    />
            </PrivateLayout>
}