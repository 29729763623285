import React, {useEffect, useState} from 'react';
import './App.scss';
import { useAppDispatch, useAppSelector } from './app/hooks';
import { LuckyOrange } from './components/LuckyOrange';
import {setAuthDetails, setUser } from './features/authSlice';
import {AppRoutes} from "./Routes";
import { useGetClientIdQuery } from './services/authApi';
import EligibilitySpinner from './templates/EligibilitySpinner';
import { APPLICATION_NAME } from './utilities/constants';
import {EligibilityError} from "./components/EligibilityError";
import { AppInsights } from './components/AppInsights';


function App() {
  const dispatch = useAppDispatch();
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const [authDetail, setAuthDetail] = useState({});
  const content = <div className='pt-5'><EligibilityError/></div>
  const {
    data: clientIdData,
    isLoading,
    isFetching,
    isSuccess,
    error,
  } = useGetClientIdQuery(APPLICATION_NAME);

  useEffect(() => {
    // If user logout from one tab then this code will log out user from all the tabs which has opened

    window.addEventListener("storage", e => {
      const user = e?.storageArea?.getItem("user");  
      const keyValue = e?.key; 
      // car-4489 condtion modified to fix glitch    
      if( keyValue && keyValue === 'user' && user && user.length >= 2  ) {         
       
       window.location.reload();
        
      }
    });
  }, []);

  useEffect(() => {
    if (isSuccess) {
      if (clientIdData?.authMappingDetail) {
        const authMappingDetail = clientIdData?.authMappingDetail;
        setAuthDetail(authMappingDetail);
        dispatch(setAuthDetails(authMappingDetail));
      }
    }
  }, [clientIdData, isSuccess]);


  useEffect(() => {
    dispatch(setUser(user))
  }, []);

  if (error) return content; 

  if (isLoading) return <EligibilitySpinner />

  return (
    <>
    <AppRoutes authDetail = {authDetail}/>
    <AppInsights />
    </>
  );
}

export default App;
