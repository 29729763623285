import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GATEWAY_API_URL } from "../api";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: GATEWAY_API_URL,
  }),
  endpoints: (builder) => ({
    loginUser: builder.mutation({
      query: (body: {
        userName: string;
        password: string;
        applicationName: string;
      }) => {
        return {
          url: "/api/Auth/LoginUser",
          method: "post",
          body,
        };
      },
    }),
    getClientId: builder.query({
      query: (applicationName: string) =>
        `/api/Auth?applicationName=${applicationName}`,
    })
  }),
});

export const { useLoginUserMutation, useGetClientIdQuery } = authApi;
