import React from "react";

export const ContactUs = () => {
    return (
      <div className='contact'>
          <p><b>For&nbsp;assistance, contact&nbsp;us&nbsp;at:</b></p>
          <p><b>Phone: </b> (800)&nbsp;813&#8209;5905</p>
          <p><b>Email: </b><a href="mailto: USPatientServices@servier.com" className="hyperlink-color">USPatientServices@servier.com</a></p>
          <p>Monday - Friday &nbsp;8:00&nbsp;AM&nbsp;to&nbsp;8:00&nbsp;PM&nbsp;(ET)</p>
      </div>
    );
}