// React Imports
import React, { useCallback, useEffect, useState } from "react";
import { Switch, Route, BrowserRouter, Redirect, RouteComponentProps, useHistory } from "react-router-dom";
import { LoginCallback, Security, SecureRoute } from "@okta/okta-react";

// Project File Imports
import { ForgotPassword } from "./pages/ForgotPassword";
import { ForgotUsername } from "./pages/ForgotUsername";
import { PageLogin } from "./pages/PageLogin";
import { PageRegistration } from "./pages/PageRegistration";
import { CreatePassword } from "./pages/CreatePassword";
import { PageRegistrationSubmitted } from "./pages/PageRegistrationSubmitted";
import { ResetPassword } from "./pages/ResetPassword";
import Homepage from "./pages/Homepage";
import UsersGrid from "./components/AdminUser/UsersGrid";
import AddUser from "./components/AdminUser/AddUser";
import ChangePassword from "./components/AdminUser/ChangePassword";
import { AdminUser } from "./pages/AdminUser";
import { OktaAuth } from '@okta/okta-auth-js';
import { isObjectEmpty } from "./utilities/utilities";
import { CALLBACK_PATH, ROUTE_PATHS } from "./utilities/constants";
import { AnyType } from "./interfaces";
import { EligibilityLoginCallback } from "./components/EligibilityLoginCallback";
import { useSelector } from "react-redux";
import { selectUserProfile } from "./features/userProfileSlice";
import {EligibilityErrorPage} from "./pages/EligibilityErrorPage";
import EligibilityResultPage from "./pages/EligibilityResultPage";
import PrivacyPolicy from "./components/FooterLinks/PrivacyPolicy";
import { checkUserActivity } from "./services/helper";
import {PagePatientEligibility} from "./pages/PagePatientEligibility";
import { LuckyOrange } from "./components/LuckyOrange";

export interface ProtectedRouteInterface {
  user: any;
  redirectPath: string;
  children: any;
}

export const FeatureGuard = ({ disabledHandler = () => <></>, ...props }: any) => {
  return <>{props.children}</>
};

interface AppRoute {
  component: AnyType;
  path: string;
}

const renderHomeRedirect = () => <Redirect to={ROUTE_PATHS.eligibility} />;

const PUBLIC_ROUTES: AppRoute[] = [
  { path: ROUTE_PATHS.loginCallback, component: EligibilityLoginCallback },
  { path: ROUTE_PATHS.login, component: PageLogin },
  { path: ROUTE_PATHS.registration, component: PageRegistration },
  { path: ROUTE_PATHS.registrationSubmitted, component: PageRegistrationSubmitted },
  { path: ROUTE_PATHS.forgotPassword, component: ForgotPassword },
  { path: ROUTE_PATHS.forgotUsername, component: ForgotUsername },
  { path: ROUTE_PATHS.createPassword, component: CreatePassword },
  { path: ROUTE_PATHS.resetPassword, component: ResetPassword },
  { path: ROUTE_PATHS.privacyPolicy, component: PrivacyPolicy }
];

const PRIVATE_ROUTES: AppRoute[] = [
  { path: ROUTE_PATHS.eligibility, component: Homepage },
  { path: ROUTE_PATHS.eligibilityResult, component: EligibilityResultPage },
  { path: ROUTE_PATHS.users, component: UsersGrid },
  { path: ROUTE_PATHS.addUser, component: AddUser },
  { path: ROUTE_PATHS.changePassword, component: ChangePassword },
  { path: ROUTE_PATHS.userManagement, component: AdminUser },
  { path: ROUTE_PATHS.eligibilityError, component: EligibilityErrorPage },
  { path: ROUTE_PATHS.patientEligibility, component: PagePatientEligibility },
];

const ADMIN_ONLY_ROUTES: AppRoute[] = [
];

const buildPublicRoutes = mapToRoutes(PUBLIC_ROUTES, Route);
const buildPrivateRoutes = mapToRoutes(PRIVATE_ROUTES, SecureRoute);
const buildAdminOnlyRoutes = mapToAdminRoutes(ADMIN_ONLY_ROUTES, SecureRoute);


function mapToRoutes(routes: AppRoute[], RouteComponent: typeof Route | typeof SecureRoute) {
  return routes.map((route) => {
    return (
      <RouteComponent
        exact
        key={route.path}
        path={route.path}
        render={(routeProps: any) => (
          <FeatureRouteHandler component={route.component} routeProps={routeProps} />
        )}
      />
    );
  });
}



function mapToAdminRoutes(routes: AppRoute[], RouteComponent: typeof Route | typeof SecureRoute) {
  return routes.map((route) => {
    return (
      <RouteComponent
        exact
        key={route.path}
        path={route.path}
        render={(routeProps: any) => (
          <FeatureGuard disabledHandler={renderHomeRedirect}>
            <AdminRouteHandler component={route.component} routeProps={routeProps} />
          </FeatureGuard>
        )}
      />
    );
  });
}



const AdminRouteHandler = ({ component: Component, ...props }: CustomRouteHandlerProps) => {
  const userProfile = useSelector(selectUserProfile);
  const isAdmin = userProfile?.user_role === 'Admin'

  if (!userProfile || (userProfile && !isAdmin)) return renderHomeRedirect();

  return <Component {...props.routeProps} />;
};

interface CustomRouteHandlerProps {
  component: typeof Route;
  routeProps: RouteComponentProps;
}



const FeatureRouteHandler = ({ component: Component, ...props }: CustomRouteHandlerProps) => {
    return (
      <Component {...props.routeProps} />
    );
};

export const AppRoutes = (props: any) => {
  const oktaTokenStorage = JSON.parse(localStorage.getItem("okta-token-storage") || "{}");
  const sesssionOktaToken = JSON.parse(sessionStorage.getItem("okta-token-storage") || "{}");
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  if (!isObjectEmpty(oktaTokenStorage) && user?.token && isObjectEmpty(sesssionOktaToken)) {
    sessionStorage.setItem("okta-token-storage", JSON.stringify(oktaTokenStorage));
  }

  const history = useHistory();
  const onAuthRequired = useCallback(async () => {
    checkUserActivity();
  }, [history]);

   const {authDetail} = props;
   const config = {
    issuer: `${authDetail?.authUrl}/oauth2/default`,
    clientId: authDetail?.authClientId,
    redirectUri:`${window.location.protocol}//${window.location.host}${CALLBACK_PATH}`,
    scopes: ['openid', 'profile', 'email'],
    responseType: ['code', 'token', 'id_token'],
    pkce: true,
    tokenManager: {
      autoRenew: true,
      expireEarlySeconds: 120,
      storage: 'sessionStorage'
    }
  };

  if (authDetail && !isObjectEmpty(authDetail)) {
    const oktaAuth = new OktaAuth(config);
    return (
      <BrowserRouter>
      <Security oktaAuth={oktaAuth} onAuthRequired={onAuthRequired}>
      <Switch>
          {buildPublicRoutes}
          {buildPrivateRoutes}
          {buildAdminOnlyRoutes}
          <Route path='*'>
                <Redirect to={ROUTE_PATHS.login} />
          </Route>
      </Switch>
      </Security>
      </BrowserRouter>
    )
  } else {
    return  <p>Loading configuration.... </p>;
  }


};
