// React Library
import React, { useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Col from "react-bootstrap/Col";
import { Button, Card, Form } from "react-bootstrap";
import { useAppDispatch } from "../../app/hooks";
import { useOktaAuth } from "@okta/okta-react";

// Other Library
import { Field, Formik, FormikErrors, FormikHelpers } from "formik";
import axios from "axios";
import { validations } from "../../utilities/validations";

// Project File Imports
import {
  CreatePasswordInterface
} from "../Interfaces/Interfaces";
import { isObjectEmpty } from "../../utilities/utilities";
import { APPLICATION_NAME, ERRORS, PASSWORD_NOT_MATCHED, RESET_PASSWORD_ERROR } from "../../utilities/constants";
import { logout } from "../../features/authSlice";
import { GATEWAY_API_URL } from "../../api";
import {TextInputField} from "../Forms/FormFields";

const UpdatePasswordURL = `${GATEWAY_API_URL}/api/User/UpdatePassword`;

const initialValues = {
  password: "",
  confirm_password: ""
};

/**
 * This method will handle the submittion of Create Password form
 * @param values
 * @returns Promise which is having the status of user's creating password activity
 */
const handleSubmit = async (values: CreatePasswordInterface, token: any) => {
  const headers = {
    Accept: "text/plain",
    "Content-Type": "application/json",
  };
  const response = await axios(UpdatePasswordURL, {
    method: "post",
    headers,
    data: {
      applicationName: APPLICATION_NAME,
      newPassword: values.password,
      resetToken: token,
    },
  });
  const { data } = response;
  return data;
};

const PasswordForm = () => {
  const { token } = useParams<{ token: string }>();
  const history= useHistory();
  const dispatch = useAppDispatch();
  const {oktaAuth} = useOktaAuth();
  const [passwordError, setPasswordError] = useState({
    isError: false,
    message: ''
  });

  const resetError = () => {
      setPasswordError({isError: false, message: ''})
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validations.passwordMatchValidation}
      onSubmit={(
        values: CreatePasswordInterface,
        { setSubmitting }: FormikHelpers<CreatePasswordInterface>
      ) => {
        handleSubmit(values, token)
          .then((data: any) => {
            if (data?.status === "ACTIVE") {
              setPasswordError({...passwordError, isError: false, message: ''});
              dispatch(logout({oktaAuth, history}));
            } else {
              if(data.errors.length > 0) {
                const errorCode = data.errors[0]?.code || ""
                const errorDescription = ERRORS[errorCode];
                setPasswordError({...passwordError, isError: true, message: errorDescription});
              } else {
                setPasswordError({...passwordError, isError: true, message: RESET_PASSWORD_ERROR});
              }
            }
          })
          .catch((e) => setPasswordError({...passwordError, isError: true, message: RESET_PASSWORD_ERROR}));
        setSubmitting(false);
      }}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
        } = props;

        return (
          <Col>
            <Form onSubmit={handleSubmit}>
              <TextInputField
                  name="password"
                  label="Password:"
                  type="password"
                  onChange={(e) => {
                    handleChange(e);
                    resetError()
                  }}
              />
              <TextInputField
                  name="confirm_password"
                  label="Confirm Password:"
                  type="password"
                  onChange={(e) => {
                    handleChange(e);
                    resetError()
                  }}
              />
              <p className="comply_text">
                By creating my password, I agree to be bound by and comply with the Terms of Use, Privacy Policy, and BAA.
              </p>
              {passwordError.isError && (
                <p className="input-feedback">
                  {passwordError.message}
                </p>
              )}
              <Button
                className="button button--primary"
                type="submit"
                disabled={!isObjectEmpty(errors) || !touched || passwordError.isError}
              >
                Create Password
              </Button>
            </Form>
          </Col>
        );
      }}
    </Formik>
  );
};

export default PasswordForm;
