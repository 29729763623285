import {ICellRendererParams} from 'ag-grid-community';
import React, {useCallback, useState} from 'react';
import {Spinner} from 'react-bootstrap';
import {ToggleOff, ToggleOn} from 'react-bootstrap-icons';
import {useSelector} from 'react-redux';
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {APPLICATION_NAME, DEFAULT_ERROR_MESSAGE} from "../../constants";
import {ChangePasswordInterface, setState} from "../../../components/Interfaces/Interfaces";
import axios from "axios";
import {GATEWAY_API_URL} from "../../../api";
import {logout} from "../../../features/authSlice";

export const UserStatusRenderer = (params: ICellRendererParams) => {
    const isActive = params.data.isActive;
    const StatusIcon = isActive ? ToggleOn : ToggleOff;
    const userProfile = useAppSelector((state) => state?.auth?.user?.profile);
    const isCurrentUser = userProfile?.login === params.data.userName;
    const applicationName = APPLICATION_NAME;
    const token = JSON.parse(sessionStorage.getItem('okta-token-storage') || '');
    const dispatch = useAppDispatch();
    const [apiError, setApiError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const onClick = useCallback(() => {

        if (isCurrentUser) return;

        const nextStatus = isActive ? 'disable' : 'enable';

        if (window.confirm(`Are you sure you want to ${nextStatus} ${params.data.firstName} ${params.data.lastName}?`)) {
            const newValue = !isActive;

            const headers = {
                'Accept': 'text/plain',
                'Content-Type': 'application/json',
                'authorization': token?.accessToken?.tokenType + ' ' + token?.accessToken?.value
            };
            const payload = {
                applicationName: applicationName,
                userName: params.data.userName,
                activate: nextStatus === "enable"
            };
            setLoading(true);
            axios.post(
                GATEWAY_API_URL + '/api/User/SetState',
                payload,
                {
                    headers: headers
                })
                .then((response: any) => {
                    if (response?.data?.isSuccess && response?.data?.errors?.length < 1) {
                        setApiError(null);
                        params.setValue(newValue);
                        return;
                    } else {
                        const error = response?.data?.errors?.map((e: any) => e?.description).join(' ') || DEFAULT_ERROR_MESSAGE;
                        alert(error);
                    }
                }).catch((e) => {
                setApiError(DEFAULT_ERROR_MESSAGE);
            }).finally(() => {
                setLoading(false);
            });
        }
    }, [isCurrentUser, isActive]);

    return (
        <div onClick={onClick}>
            <StatusIcon className='mr-2 text-info' size={20}/><br/>{isActive ? 'Active' : 'Inactive'}
            {loading && <Spinner animation='border' style={{position: "absolute", top: "5px"}} size='sm'/>}
        </div>
    );
};
