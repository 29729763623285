import {AnyType} from "../interfaces";

export const APPLICATION_NAME = 'EligibilityProviderPortal';
export const PROGRAM_ID = 200; // needs to be pulled from API once API is ready

// Error Messages
export const RESET_PASSWORD_ERROR = 'Sorry, we are unable to set your password at this moment. Please contact to administrator.'
export const PASSWORD_NOT_MATCHED = 'Passwords do NOT match'
export const NEW_PASSWORD_NOT_MATCHED = 'Confirmed Password do not match with New Password'
export const CURRENT_PASSWORD_AS_NEW = 'New Password matches with Current Password'
export const PASSWORD_MATCHED = 'Passwords match'
export const LOGIN_UNSUCCESSFUL = 'Login unsuccessful. The username or password provided is incorrect.'
export const REQUIRED_ERROR = 'This is a required field.'
export const FORGOT_PASSWORD_RESPONSE = 'If the information entered is correct, you will receive an email with instructions to reset your password.'
export const FORGOT_USERNAME_RESPONSE = 'If the information entered is correct, you will receive an email with your username.'
export const ACCEPT_POLICY_ERROR = 'Please accept terms and conditions'
export const NO_SPACE_ERROR = 'cannot contain spaces'
export const NPI_EXISTS = 'The NPI is already registered.  Please check with your organization’s administrator or use a different NPI to create your account.'
export const EMAIL_EXISTS = 'This email address already exists.'
export const USERNAME_EXISTS = 'This username already exists.'
export const ACCOUNT_LOCKED_OUT_ERROR = 'You have reached the maximum amount of login attempts, please use the “Forgot Password“ link below to unlock your account.'

export const ERRORS: any = {
    "E0113" : "Password requirements were not met."
}

// Registration
export const DEFAULT_ERROR_MESSAGE = 'There was a problem registering the user. Please contact support.';

// Add User
export const DEFAULT_ADD_USER_ERROR = "There was a problem while adding the user. Please contact to administrator."

// Get User
export const DEFAULT_GET_USER_ERROR = "There was a problem while loading the users. Please contact to administrator."

// Get Eligibility
export const DEFAULT_GET_ELIGIBILITY_ERROR = "There was a problem while loading the Patients Eligibility. Please contact to administrator."

// Update User
export const DEFAULT_UPDATE_USER_ERROR = 'There was a problem while updating the user. Please contact to administrator.'

export const CALLBACK_PATH = '/implicit/callback';

export const ROUTE_PATHS = {
    loginCallback: CALLBACK_PATH,
    landingPage: '/',
    login: '/login',
    eligibility: '/eligibility',
    eligibilityResult: '/eligibility-result',
    registration: '/registration',
    forgotPassword: '/forgot-password',
    forgotUsername: '/forgot-username',
    registrationSubmitted: '/registration-submitted',
    createPassword: '/create-password/:token',
    resetPassword: '/reset-password/:token',
    users: '/users',
    addUser: '/add-user',
    changePassword: '/change-password',
    userManagement: '/user-management',
    eligibilityError: '/eligibility-assistance',
    privacyPolicy: '/privacyPolicy',
    patientEligibility: '/patientEligibility'
  };


// Tooltip
export const LAST_DOSE = 'The total anticipated number of vials is based on the number of doses and the patient\'s BSA per treatment duration.';

export const PRIVACY_POLICY = 'Privacy Statement';
export const TERMS_OF_USE = 'Terms of Use';
export const BUSINESS_ASSOCIATION_AGREEMENT = 'Business Association Agreement';

export const REACT_APP_RELEASE_DATE = String((window as AnyType).REACT_APP_RELEASE_DATE);
export const REACT_APP_ISLUCKYORANGE = String((window as AnyType).REACT_APP_ISLUCKYORANGE);
export const REACT_APP_INSIGHTS_CONN_STR = String((window as AnyType).REACT_APP_INSIGHTS_CONN_STR);
export const REACT_APP_SESSION_TIMEOUT_IN_MINUTES = String((window as AnyType).REACT_APP_SESSION_TIMEOUT_IN_MINUTES);
export const REACT_APP_SESSION_WARNING_IN_MINUTES = String((window as AnyType).REACT_APP_SESSION_WARNING_IN_MINUTES);