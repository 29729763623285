import {
  ColDef,
  ColumnApi,
  GridApi,
  GridReadyEvent,
  PaginationChangedEvent,
  RowNode,
} from "ag-grid-community";
import { AgGridReact, AgGridReactProps } from "ag-grid-react";
import { debounce } from "lodash";
import React, { Component } from "react";
import { Button, Col, Dropdown, Row } from "react-bootstrap";
import { ChevronDoubleLeft, ChevronDoubleRight } from "react-bootstrap-icons";
import ReactPaginate from "react-paginate";
import { AnyType } from "../components/Interfaces/Interfaces";
import {
  globalDefaultColDefs,
  eligibilityFrameworkComponents,
} from "./ag-grid";

interface EligibilityGridState {
  columnApi?: ColumnApi | undefined;
  gridApi?: GridApi | undefined;
  paginationPageSize: number;
  paginationCurrentPage: number;
  paginationFirstRowIndex?: number;
  paginationLastRowIndex?: number;
}

export interface EligibilityGridProps extends AgGridReactProps {
  children?: AnyType;
  className?: string;
  filterComparator?: (
    node: RowNode,
    value: string | AnyType | undefined,
    columns?: ColDef[]
  ) => unknown;
  // This is used solely as a key by the component to dictate when to tell the grid the filters have changed
  filterValue?: string | AnyType;
  isFilterActive?: boolean;
  pageSizes?: number[];
}

export class EligibilityGrid extends Component<
  EligibilityGridProps,
  EligibilityGridState
> {
  static defaultProps = {
    isFilterActive: false,
  };

  onWindowResizeDebounced: AnyType;

  constructor(props: EligibilityGridProps) {
    super(props);

    this.onWindowResizeDebounced = debounce((event: Event) => {
      this.onWindowResize(event);
    }, 500);

    this.state = {
      paginationPageSize:
        (this.props.pageSizes && this.props.pageSizes[0]) ?? 10,
      paginationCurrentPage: 1,
    };
  }

  UNSAFE_componentWillMount() {
    window.addEventListener("resize", this.onWindowResizeDebounced);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onWindowResizeDebounced);
  }

  componentDidUpdate(prevProps: EligibilityGridProps) {
    if (
      this.props.isFilterActive !== prevProps.isFilterActive ||
      this.props.filterValue !== prevProps.filterValue
    ) {
      this.state.gridApi?.onFilterChanged();
    }
  }

  onWindowResize = (event?: Event) => {
    const target = (event?.target as Window) ?? window;

    if (target.innerWidth > 1040) {
      this.gridApi?.sizeColumnsToFit();
      return;
    }

    const allColumnIds: string[] = [];
    this.state.columnApi?.getAllColumns().forEach(function (column) {
      if (!(column.getDefinition() as ColDef).suppressAutoSize) {
        allColumnIds.push(column.getColId());
      }
    });
    this.state.columnApi?.autoSizeColumns(allColumnIds, false);
  };

  get gridApi(): GridApi | undefined {
    return this.state.gridApi;
  }

  isExternalFilterPresent = () => {
    return (
      this.props.isFilterActive === true &&
      this.props.filterComparator !== undefined
    );
  };

  doesExternalFilterPass = (node: RowNode): boolean => {
    return (
      (this.props.filterComparator &&
        this.props.filterComparator(
          node,
          this.props.filterValue,
          this.gridApi?.getColumnDefs()
        )) === true
    );
  };

  render() {
    return (
      <div
        className={`ag-theme-alpine ${this.props.className ?? ""}`.trim()}
        data-testid="eligibility-grid-wrapper"
      >
        <Row className="mb-3">
          <Col>
            <AgGridReact
              data-testid="eligibility-grid"
              doesExternalFilterPass={this.doesExternalFilterPass}
              domLayout="autoHeight"
              enableBrowserTooltips={true}
              isExternalFilterPresent={this.isExternalFilterPresent}
              onGridReady={this.onGridReady}
              onPaginationChanged={this.onPaginationChange}
              paginationPageSize={this.state.paginationPageSize}
              suppressPaginationPanel={
                this.props.suppressPaginationPanel ?? true
              }
              frameworkComponents={eligibilityFrameworkComponents}
              defaultColDef={this.props.defaultColDef ?? globalDefaultColDefs}
              {...this.props}
            />
          </Col>
        </Row>
        {this.renderPaginationLayout()}
      </div>
    );
  }

  renderPaginationLayout() {
    return (
      <>
        {this.props.pagination && (
          <Row className="justify-content-between align-items-center">
            <div className="col-3">{this.renderPaginationInfo()}</div>
            <div className="col-6">{this.renderPagination()}</div>
            <Col className="col-2">{this.renderPageSize()}</Col>
          </Row>
        )}
      </>
    );
  }

  renderPageSize() {
    const pageSizes = this.props.pageSizes ?? [5, 10, 25, 50];
    return (
      <Dropdown className="d-flex justify-content-end">
        <Dropdown.Toggle
          variant="outline-primary"
          className="w-sm-20 py-1 px-3 grid-button"
        >
          {this.state.paginationPageSize}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {pageSizes.map((pageSizeOption: number, i: number) => (
            <Dropdown.Item
              key={i}
              eventKey={`${pageSizeOption}`}
              active={this.state.paginationPageSize === pageSizeOption}
              onClick={(ekey: any) =>
                this.updatePageSize(ekey.target.innerText)
              }
            >
              {pageSizeOption}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  renderPagination() {
    const totalPages = this.gridApi?.paginationGetTotalPages() || 0;

    if (totalPages <= 1) return null;

    return (
      <div className="d-flex justify-content-center">
        <Button
          variant="outline-primary"
          className="px-2 mr-2 grid-button"
          onClick={() => this.gridApi?.paginationGoToFirstPage()}
          style={{ marginRight: "10px" }}
        >
          <ChevronDoubleLeft />
          First
        </Button>
        <ReactPaginate
          activeClassName="active bg-black-50 m-1"
          breakClassName="page-item"
          breakLinkClassName="page-link border-0"
          breakLabel={"..."}
          containerClassName="pagination m-0 align-items-center"
          forcePage={this.state.paginationCurrentPage}
          marginPagesDisplayed={2}
          nextClassName="page-item ml-2"
          nextLabel=">"
          nextLinkClassName="btn btn-outline-primary m-1"
          onPageChange={this.onPaginateNavigationChange}
          pageClassName="page-item"
          pageCount={totalPages}
          pageLinkClassName="page-link border-0"
          pageRangeDisplayed={5}
          previousClassName="page-item m-2"
          previousLabel="<"
          previousLinkClassName="btn btn-outline-primary"
        />
        <Button
          variant="outline-primary"
          className="px-2 ml-2 grid-button"
          onClick={() => this.gridApi?.paginationGoToLastPage()}
          style={{ marginLeft: "10px" }}
        >
          Last
          <ChevronDoubleRight />
        </Button>
      </div>
    );
  }

  renderPaginationInfo = () => {
    if (
      this.state.paginationFirstRowIndex === null ||
      this.state.paginationLastRowIndex === 0
    )
      return;

    return (
      <span className="text-primary">
        Showing{" "}
        <span className="text-dark">
          {this.state.paginationFirstRowIndex} -{" "}
          {this.state.paginationLastRowIndex}
        </span>{" "}
        of{" "}
        <span className="text-dark">
          {this.state.gridApi?.paginationGetRowCount()}
        </span>{" "}
        records
      </span>
    );
  };

  updatePageSize = (eventKey: string | null) => {
    this.setState({ paginationPageSize: Number(eventKey) });
    this.gridApi?.paginationSetPageSize(Number(eventKey));
  };

  onGridReady = (event: GridReadyEvent) => {
    const api = event.api ?? undefined;

    this.setState(
      { gridApi: api, columnApi: event.columnApi },
      this.onWindowResize
    );
  };

  onPaginationChange = (event: PaginationChangedEvent) => {
    const paginationCurrentPage = event.api.paginationGetCurrentPage();
    const paginationFirstRowIndex = (event.api.getFirstDisplayedRow() ?? 0) + 1;
    const paginationLastRowIndex = (event.api.getLastDisplayedRow() ?? 0) + 1;

    this.setState({
      paginationCurrentPage,
      paginationFirstRowIndex,
      paginationLastRowIndex,
    });
  };

  onPaginateNavigationChange = (selectedItem: { selected: number }) => {
    // NOTE: This call will trigger AG to call `onPaginationChange`. DO NOT SET STATE HERE.
    this.gridApi?.paginationGoToPage(selectedItem.selected);
  };
}
