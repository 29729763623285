import { FormikErrors } from "formik";
import * as Yup from "yup";
import { REQUIRED_ERROR } from "./constants";

/**
 * react-number-format always returns a string of the max length no matter what has been entered.
 * In order to make sure the user has inputted the correct amount of digits we need to pluck out all non integer
 * values and remove them, then we check that the amount of digits equals the number of digits a field
 * requires.
 */

export const PASSWORD_REGEX = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{12,}$/;

export interface MaskLengthOpts {
  message?: string;
  isRequired?: boolean;
}

export const formatPath = (path?: string) => {
  return path?.replace(/[_]/gi, " ");
};

export const maskLength = (maskType: string, opts?: MaskLengthOpts) => {
  const maskTypeLengths: any = {
    tax: 9,
    phone: 14,
    fax: 10,
    zip: 5,
    npi: 10,
  };
  return Yup.string().test({
    name: "maskLength",
    message: (options: Partial<any>) =>
      opts?.message ??
      `${formatPath(options?.path)} must have ${
        maskTypeLengths[maskType]
      } digits`,
    test: (value: unknown) => {
      if (maskType === "phone") {
        return opts?.isRequired || value
          ? String(value).match(/^\(\d{3}\) \d{3}-\d{4}$/) !== null &&
              String(value).length === maskTypeLengths[maskType]
          : true;
      }
      return opts?.isRequired || value
        ? String(value).match(/^[0-9]+$/) != null &&
            String(value).length === maskTypeLengths[maskType]
        : true;
    },
  });
};

Yup.addMethod<Yup.StringSchema>(Yup.string, "maskLength", maskLength);

const makeRequired = (schema: Yup.AnySchema): Yup.AnySchema =>
  schema.required();

const makeNotRequired = (schema: Yup.AnySchema): Yup.AnySchema =>
  schema.notRequired();

const isRequiredMap: {
  [key: string]: (
    schema: Yup.AnySchema,
    defaultValue?: unknown
  ) => Yup.AnySchema;
} = {
  true: makeRequired,
  false: makeNotRequired,
};

const npiValidation = (message?: string) =>
  Yup.string()
    .maskLength("npi", { message: "NPI must be 10 numbers" })
    .required(message);

const phoneValidation = (message?: string) =>
  Yup.string()
    .maskLength("phone", { message: "Phone Number must have 10 digits" })
    .required(message);

const faxValidation = (message?: string) =>
  Yup.string()
    .required(message);

const zipValidation = () =>
  makeRequired(Yup.string().length(5).maskLength("zip")).label("Zip Code");

const passwordMatchValidation = (
  password: string,
  confirm_password: string
) => {
  return Yup.object().shape({
    password: Yup.string().required("Password is a required.")
        .matches(PASSWORD_REGEX, 'Password requirements is not met.'),
    confirm_password: Yup.string()
      .when("password", {
        is: (val: any) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Both passwords need to be the same"
        ),
      })
      .required("Confirm Password is a required."),
  });
};

export const validations = {
  npiValidation,
  phoneValidation,
  zipValidation,
  faxValidation,
  passwordMatchValidation,
};

export const validationRequiredError = (values: any, fields: any) => {
  let errors: FormikErrors<any> = {};
  fields.forEach((field: string) => {
    if (!values?.[`${field}`]) {
      errors[`${field}`] = REQUIRED_ERROR;
    }
  });
  return errors;
};
