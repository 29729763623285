import { useEffect } from "react";
import { Button } from "react-bootstrap";

function setPageSize(cssPageSize: any) {
    const style = document.createElement('style');
    style.innerHTML = `@page {size: ${cssPageSize}}`;
    style.id = 'page-orientation';
    document.head.appendChild(style);
}

function PrintButton({ orientation = 'landscape' }) {
    // Set orientation of page being printed
    useEffect(() => {
        setPageSize(orientation);
        return () => {
            const child = document.getElementById('page-orientation');
            child?.parentNode?.removeChild(child);
        };
    }, [orientation]);

    return (
        <Button className="button button--primary button--dense" type="button" onClick={() => window.print()}>
            Print
        </Button>
    );
}

export default PrintButton;