// React Library
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "../../app/hooks";
import AssistanceBox from "../../templates/AssistanceBox";
import { Footer } from "../../templates/Footer";
import Sidebar from "../../templates/Sidebar";
import { CreatePasswordForm } from "./CreatePasswordForm";
import { useVerifyResetTokenMutation } from "../../services/verifyResetTokenApi";
import { APPLICATION_NAME } from "../../utilities/constants";
import EligibilitySpinner from "../../templates/EligibilitySpinner";
import {ContactUs} from "../ContactUs";
import {ViewRenderer} from "../ViewRenderer";

export const CreatePasswordWrapper = () => {
  const [tokenStatus, setTokenStatus] = useState("");
  const { token } = useParams<{ token: string }>();
  const [verifyResetToken, { data, isSuccess, isError, error, isLoading }] =
    useVerifyResetTokenMutation();
  const verifyToken = async () => {
    const result = await verifyResetToken({
      applicationName: APPLICATION_NAME,
      resetToken: token || "",
    });
  };

  useEffect(() => {
    if (isSuccess) {
      setTokenStatus(data.tokenStatus);
    }
  }, [isSuccess]);

  useEffectOnce(() => {
    if (token) {
      verifyToken();
    }
  });

  if (isLoading) return <EligibilitySpinner />;

  if (error) {
    return (
      <p className="text-center">
        There is an error in fetching details. Please reload the screen
      </p>
    );
  }

  if (tokenStatus === null)
    return <h2 className="text-danger text-center">Token Expired</h2>;
  return (
    <>
      <ViewRenderer bodyComponent={(
        <>
          <CreatePasswordForm />
          <div className="mt-5">
            <ContactUs />
          </div>
        </>
        )}
      />
    </>
  );
};
