// React Library
import React, { useState } from "react";
import { Link } from "react-router-dom";
// React Bootstrap Library
import Col from "react-bootstrap/Col";
import { Button, Card, Form } from "react-bootstrap";
import { ExclamationCircleFill } from "react-bootstrap-icons";

// Other Library
import { Field, Formik, FormikErrors, FormikHelpers } from "formik";
import axios from "axios";

// Project File Imports
import { ForgotPasswordEmailInterface } from "../Interfaces/Interfaces";
import { isObjectEmpty } from "../../utilities/utilities";
import {
  APPLICATION_NAME,
  FORGOT_PASSWORD_RESPONSE,
  NO_SPACE_ERROR,
  REQUIRED_ERROR,
} from "../../utilities/constants";
import { GATEWAY_API_URL } from "../../api";
import { ForgotPasswordEmailSchema } from "../ValidationSchemas/ValidationSchemas";
import { TextInputField } from "../Forms/FormFields";

const ForgotPasswordURL = `${GATEWAY_API_URL}/api/User/ForgotPassword`;

const initialValues = {
  username: "",
};

/**
 * This method will handle the submittion of email form
 * @param values
 * @returns Promise which is having the status of user's sending email activity
 */
const handleSubmit = async (values: ForgotPasswordEmailInterface) => {
  const headers = {
    Accept: "text/plain",
    "Content-Type": "application/json",
  };
  const response = await axios.get(ForgotPasswordURL, {
    params: { applicationName: APPLICATION_NAME, userName: values.username },
  });
  const { data } = response;
  return data?.loginResponse?.status;
};

const ForgotPasswordEmailForm = () => {
  const [emailResponse, setEmailResponse] = useState("");
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ForgotPasswordEmailSchema}
      onSubmit={(values) => {
        handleSubmit(values);
        setEmailResponse(FORGOT_PASSWORD_RESPONSE);
      }}
    >
      {({ touched, errors, handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <div className="col-12">
            <TextInputField name="username" label="Username:" type="text" />
          </div>
          <div className="col-6">
            <button
              className="btn button button--primary"
              disabled={!isObjectEmpty(errors) || !touched}
              type="submit"
            >
              Send Email
            </button>
          </div>
          <div className="col-12">
            {emailResponse.length > 0 && (
              <p className="text-danger mt-3">
                <ExclamationCircleFill /> {emailResponse}
              </p>
            )}
            </div>
        </Form>
      )}
    </Formik>
  );
};

export default ForgotPasswordEmailForm;
