import React, { useEffect, useState } from "react";
import { ROUTE_PATHS } from "../../utilities/constants";

const TermsAndCondition = (props: any) => {
  return (
    <div className="container link-text">
      <span className="link-paragraph-text">
        <p>Legal Terms of Use for the CareMetx Website, March 2022</p>
        <p>
          Welcome to the CareMetx website located at&nbsp;&nbsp;
          <a href="http://oncasparportal.com/" target='_blank' style={{color: "#0d6efd" , textDecoration: "none"}} rel="noreferrer">
            OncasparPortal.com
          </a>
          &nbsp; (the “Website”). Please read these Terms of Use (the “Terms”)
          and our Privacy Policy&nbsp;&nbsp;
          <a href={ROUTE_PATHS.privacyPolicy} target='_blank' style={{color: "#0d6efd" , textDecoration: "none"}} rel="noreferrer">
            (click here to view)&nbsp;
          </a>
          carefully because they govern your use of our Website and our online
          portal that we provide. To make these Terms easier to read, the Site
          and our services are collectively called the “Services.”
        </p>
        <p>
          These Terms constitute a binding legal agreement between you, as a
          user of the Services, and CareMetx, LLC (referred to as “CareMetx”,
          “we” or “us”).
        </p>
        <p>
          Carefully read these terms of use before using this Website. Your
          access to, and use of, the Website and its materials is conditioned
          upon your acceptance and compliance with these terms in full. If you
          do not accept these terms, you should leave the Website immediately
          and cease any further use of any materials you have obtained from the
          Website or any Services available on the Website.
        </p>
        <p>
          You should review these terms and conditions periodically as they may
          be revised from time to time. When you continue to use this Website
          after we have made a change to our Terms of Use and made it available
          on the Website, you are agreeing and consenting to those changes.
        </p>
        <ol style={{paddingLeft: "1rem"}}>
          <li>
            <strong>Copyright and Usage of Content.</strong>
            <br />
            The copyrights and other rights to the materials on this Website are
            owned by CareMetx or other third-party clients or service providers
            of CareMetx. You are authorized to view, download and reproduce the
            materials at this Website only for your internal information
            provided that you 1) retain all notices contained in the original
            materials 2) only use images with surrounding text relating to the
            images, and 3) include the appropriate copyright notice, including
            the following copyright notice for CareMetx: © CareMetx, LLC All
            rights reserved.
            <br />
            No further publication, circulation, or commercial or other use of
            any kind may be made of the materials on this Website. You may not
            make any part of this Website available as part of another web site
            whether by hyperlink framing on the internet or otherwise. This
            Website and its content may not be used to construct a database of
            any kind nor may the same be stored (in whole or part) in databases
            for access by you or any third party or to distribute any database
            containing all or part of the Website or its content.
            <br />
            <br />
          </li>
          <li>
            <strong>Eligibility</strong>
            <br />
            By using this website you represent that you are not a person barred
            from receiving the Services under the laws of the United States or
            any of its states or any other applicable jurisdictions. You also
            represent that you are 18 years of age or older. &nbsp;This Website
            is not intended for use by any person under the age of 18, and if
            you are under age 18, please do not use, create an account with, or
            provide any personal information to the Website.
            <br />
            <br />
          </li>
          <li>
            <strong>Accuracy of Information</strong>
            <br />
            CareMetx, LLC cannot and does not guarantee that the information
            made available through this Website will be accurate, timely or
            complete. Further, you agree that CareMetx is not responsible for
            any consequences from your reliance on the accuracy of any materials
            and information available through this Website.
            <br />
            <br />
          </li>
          <li>
            <strong>Other Terms and Notices May Apply</strong>
            <br />
            Additional terms and conditions may apply to business transactions
            conducted by CareMetx via this Website. Nothing contained in these
            Terms is intended to modify or amend any current or future agreement
            in effect between you and CareMetx or other third-party, as
            applicable. Although these Terms are intended to apply generally to
            all content located under this Website, some content may contain
            other proprietary notices and conditions of use, the terms of which
            must also be observed and followed as they apply to the particular
            portions of this Website for which they are intended. CareMetx may
            revoke or modify any such terms and conditions by updating the
            applicable page.
            <br />
            <br />
          </li>
          <li>
            <strong>Accessing the Website and Account Security</strong>
            <br />
            We reserve the right to withdraw or amend this Website, and any
            service or material we provide on the Website, in our sole
            discretion without notice. We will not be liable if for any reason
            all or any part of the Website is unavailable at any time or for any
            period. From time to time, we may restrict access to some parts of
            the Website, or the entire Website, to users, including registered
            users, ay any time and at the sole discretion of CareMetx.
            <br />
            <br />
            You may browse the Website without registering. However, in order to
            access some portions and features of the Website you will be
            required to register with and/or sign into the Website. If you do
            so, you are responsible for maintaining the confidentiality of the
            password and username, and are fully responsible for all activities
            that occur under your password or username. In addition, it is a
            condition of your use of the Website that all the information you
            provide on the Website is correct, current and complete. You agree
            that all information you provide, including but not limited to
            through the use of any interactive features on the Website, is
            governed by our Privacy Policy linked to above and you consent to
            all actions we take with respect to information you provide to us,
            in accordance with our Privacy Policy. Please immediately notify us
            of any unauthorized use of your password or username or any other
            breach of security by contacting us at:
            <br />
            <br />
            Email:{" "}
            <a href="mailto:privacy@caremetx.com">privacy@caremetx.com</a>
            <br />
            Phone: 1-877-690-0220 (Toll Free) <br />
            <br />
          </li>
          <li>
            <strong>Prohibited Uses</strong>
            <br />
            You may use the Website only for lawful purposes and in accordance
            with these Terms. You agree not to use the Website:
            <ul>
              <li>
                In any way that violates any applicable federal, state, local or
                international law or regulation (including, without limitation,
                any laws regarding the export of data or software to and from
                the US or other countries).
              </li>
              <li>
                For the purpose of exploiting, harming or attempting to exploit
                or harm minors in any way by exposing them to inappropriate
                content, asking for personally identifiable information or
                otherwise.
              </li>
              <li>
                To send, knowingly receive, upload, download, use or re-use any
                material which does not comply with these Terms.
              </li>
              <li>
                To impersonate or attempt to impersonate CareMetx, a CareMetx
                employee, another user or any other person or entity (including,
                without limitation, by using e-mail addresses associated with
                any of the foregoing).
              </li>
              <li>
                To engage in any other conduct that restricts or inhibits
                anyone’s use or enjoyment of the Website, or which, as
                determined by us, may harm the Company or users of the Website
                or expose them to liability.
              </li>
            </ul>
            <br />
            <br />
            Additionally, you agree not to:
            <ul>
              <li>
                Use the Website in any manner that could disable, overburden,
                damage, or impair the website or interfere with any other
                party’s use of the Website, including their ability to engage in
                real time activities through the Website.
              </li>
              <li>
                Use any robot, spider or other automatic device, process or
                means to access the Website for any purpose, including
                monitoring or copying any of the material on the Website.
              </li>
              <li>
                Use any manual process to monitor or copy any of the material on
                the Website or for any other unauthorized purpose without our
                prior written consent.
              </li>
              <li>
                Use any device, software or routine that interferes with the
                proper working of the Website.
              </li>
              <li>
                Introduce any viruses, trojan horses, worms, logic bombs or
                other material which is malicious or technologically harmful.
              </li>
              <li>
                Attempt to gain unauthorized access to, interfere with, damage
                or disrupt any parts of the Website, the server on which the
                Website is stored, or any server, computer or database connected
                to the Website.{" "}
              </li>
              <li>
                Attack the Website via a denial-of-service attack or a
                distributed denial-of-service attack.
              </li>
              <li>
                Otherwise attempt to interfere with the proper working of the
                Website.
              </li>
            </ul>
            <br />
            <br />
            Finally, you are solely responsible for:
            <ul>
              <li>
                Making all arrangements necessary for you to have access to the
                Website.
              </li>
              <li>
                Ensuring that all persons who access the Website through your
                internet connection are aware of these Terms and comply with
                them.
              </li>
              
              <br />
            </ul>
          </li>
          <li>
            <strong>Links from the Website</strong>
            <br />
            If the Website contains links to other sites and resources provided
            by third parties, these links are provided for your convenience
            only. This includes links contained in advertisements, including
            banner advertisements and sponsored links. We have no control over
            the contents of those sites or resources, and accept no
            responsibility for them or for any loss or damage that may arise
            from your use of them. If you decide to access any of the third
            party websites linked to this Website, you do so entirely at your
            own risk and subject to the terms and conditions of use for such
            websites.
            <br />
            <br />
          </li>
          <li>
            <strong>Geographic Restrictions</strong>
            <br />
            CareMetx is based in the state of Maryland in the United States. We
            provide this Website for use only by persons located in the United
            States. We make no claims that the Website or any of its content is
            accessible or appropriate outside of the United States. Access to
            the Website may not be legal by certain persons or in certain
            countries. If you access the Website from outside the United States,
            you do so on your own initiative and are responsible for compliance
            with local laws.
            <br />
            <br />
          </li>
          <li>
            <strong>Other Intellectual Property Rights.</strong>
            <br />
            The Website and its entire contents, features and functionality are
            owned by CareMetx, its licensors or other providers of such material
            and are protected by United States and international copyright,
            trademark, patent, trade secret and other intellectual property or
            proprietary rights laws.
            <br />
            <br />
            CareMetx and its associated logos are our trademarks and/or service
            marks. Other trademarks, service marks, and logos used on or through
            the Website are the trademarks, service marks or logos of their
            respective owners. You are granted no right or license with respect
            to, or any title or interest of any kind in, any of the aforesaid
            trademarks, service marks or logos.
            <br />
            <br />
            All materials available on or through the Website are protected by
            copyright, trademark, patent, trade secret and/or other laws, and we
            reserve and retain all rights in our materials and the Website. We
            hereby grant you a limited, non-exclusive, non-assignable license to
            access and make personal use of this Website and not to download
            (other than page caching) or share with other persons or modify it,
            or any portion of it. This license does not include any resale or
            commercial use of this Website or its contents or any derivative use
            of this Website or its contents.
            <br />
            <br />
            The above described license is conditioned on your compliance with
            these Terms, and shall terminate upon termination of these Terms. If
            you breach any provision of these Terms, any license you have
            obtained will be automatically rescinded and terminated. You must
            not remove, delete, or alter any watermark or other digital rights
            management technology or other information, including where it is
            attached to a copy of a photograph or video, related to the
            photograph or video’s identification number, transactional terms,
            copyright owner, licensee, licensor, author, or date of creation or
            publication. Such conduct is prohibited by law.
            <br />
            <br />
          </li>
          <li>
            <strong>U.S. Government Users</strong>
            <br />
            To the extent applicable, use, duplication, or disclosure by the
            United States Government is subject to the restrictions set forth in
            DFARS 252.227-7013(c) and FAR 52.227-19 and any other successor
            regulations that may be applicable.
            <br />
            <br />
          </li>
          <li>
            <strong>Disclaimers</strong>
            <br />
            You understand that we cannot and do not guarantee or warrant that
            files available for downloading from the internet or the Website
            will be free of viruses or other destructive code. You are
            responsible for implementing sufficient procedures and checkpoints
            to satisfy your particular requirements for anti-virus protection
            and accuracy of data input and output, and for maintaining a means
            external to our Website for any reconstruction of any lost data. WE
            WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED
            DENIAL-OF-SERVICE ATTACK, VIRUSES OR OTHER TECHNOLOGICALLY HARMFUL
            MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS,
            DATA OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE WEBSITE OR
            ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE OR TO YOUR
            DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITE LINKED
            TO IT.
            <br />
            <br />
            YOUR USE OF THE WEBSITE, ITS CONTENT AND ANY SERVICES OR ITEMS
            OBTAINED THROUGH THE WEBSITE IS AT YOUR OWN RISK. THE WEBSITE, ITS
            CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE ARE
            PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT ANY
            WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER THE
            COMPANY NOR ANY PERSON ASSOCIATED WITH THE COMPANY MAKES ANY
            WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS,
            SECURITY, RELIABILITY, QUALITY, ACCURACY OR AVAILABILITY OF THE
            WEBSITE. WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY NOR
            ANYONE ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS THAT THE
            WEBSITE, ITS CONTENT OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE
            WEBSITE WILL BE ACCURATE, RELIABLE, ERROR-FREE OR UNINTERRUPTED,
            THAT DEFECTS WILL BE CORRECTED, THAT OUR SITE OR THE SERVER THAT
            MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS
            OR THAT THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE
            WEBSITE WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
            <br />
            <br />
            THE COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER
            EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT
            LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT AND
            FITNESS FOR PARTICULAR PURPOSE.
            <br />
            <br />
            THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE
            EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
            <br />
            <br />
            The material on this Website could contain technical inaccuracies or
            typographical errors, and information will be changed, updated and
            deleted without notice. CareMetx may make improvements and/or
            changes in the products and/or the programs described in this
            material at any time. CareMetx makes no warranties that this Website
            will operate uninterrupted or error free or that defects will be
            corrected. CareMetx does not warrant that this Website is compatible
            with your computer equipment or that this Website or its server is
            free of errors or viruses, worms or “Trojan horses” and CareMetx is
            not liable for any damage you may suffer as a result of such
            destructive features. Additionally, CareMetx makes no
            representations or warranties whatsoever about any other Website
            which you may choose to access through this Website. Links provided
            by CareMetx to such Websites are provided solely for your
            convenience and should not be deemed to imply that CareMetx endorses
            those Websites or any content therein.
            <br />
            <br />
          </li>
          <li>
            <strong>Limitation on Liability</strong>
            <br />
            IN NO EVENT WILL CAREMETX, ITS AFFILIATES OR THEIR LICENSORS,
            SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS OR DIRECTORS BE
            LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT
            OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE WEBSITE,
            ANY WEBSITES LINKED TO IT, ANY CONTENT ON THE WEBSITE OR SUCH OTHER
            WEBSITES OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE OR
            SUCH OTHER WEBSITES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL,
            INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING BUT NOT
            LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS,
            LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED
            SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER
            CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT OR
            OTHERWISE, EVEN IF FORESEEABLE. CAREMETX’S MAXIMUM LIABILITY TO ANY
            USER OF THE WEBSITE WILL NOT EXCEED $100.00.
            <br />
            <br />
          </li>
          <li>
            <strong>Indemnification</strong>
            <br />
            You agree to defend, indemnify and hold harmless CareMetx, its
            affiliates, licensors and service providers, and its and their
            respective officers, directors, employees, contractors, agents,
            licensors, suppliers, successors and assigns from and against any
            claims, liabilities, damages, judgments, awards, losses, costs,
            expenses or fees (including reasonable attorneys’ fees) arising out
            of or relating to your violation of these Terms or your use of the
            Website, including, but not limited to, any use of the Website’s
            content, services (including, without limitation, the Services) and
            products other than as expressly authorized in these Terms or your
            use of any information obtained from the Website.
            <br />
            <br />
          </li>
          <li>
            <strong>Governing Law and Jurisdiction</strong>
            <br />
            All matters relating to the Website and these Terms of Use and any
            dispute or claim arising therefrom or related thereto (in each case,
            including non-contractual disputes or claims), shall be governed by
            and construed in accordance with the internal laws of the State of
            Maryland without giving effect to any choice or conflict of law
            provision or rule (whether of the State of Maryland or any other
            jurisdiction).
            <br />
            <br />
            Any legal suit, action or proceeding arising out of, or related to,
            these Terms or the Website shall be instituted exclusively in the
            federal courts of the United States or the courts of the State of
            Maryland although we retain the right to bring any suit, action or
            proceeding against you for breach of these Terms in your country of
            residence or any other relevant state, country or jurisdiction. You
            waive any and all objections to the exercise of jurisdiction over
            you by such courts and to venue in such courts.
          </li>
        </ol>
      </span>
    </div>
  );
};

export default TermsAndCondition;
