import * as Yup from "yup";
import {PASSWORD_REGEX, validations} from "../../utilities/validations";
import { NO_SPACE_ERROR } from "../../utilities/constants";
import parse from "date-fns/parse";
import moment from "moment";


export const RegistrationSchema = Yup.object().shape({
    organizationNPI: validations.npiValidation("Organization NPI is required"),
    organizationName: Yup.string()
        .required("Organization Name is required"),
    address: Yup.string()
        .required("Address is required"),
    city: Yup.string()
        .required("City is required"),
    state: Yup.string()
        .required("State is required"),
    zipCode: validations.zipValidation(),
    organizationPhoneNumber: validations.phoneValidation("Phone Number is required"),
    faxNumber: validations.faxValidation("Fax Number is required")
});

export const UserSchema = Yup.object().shape({
    firstName: Yup.string()
        .required("First Name is required"),
    lastName: Yup.string()
        .required("Last Name is required"),
    emailAddress: Yup.string()
        .email("Invalid email address format")
        .required("Email Address is required"),
    userPhoneNumber: validations.phoneValidation("Phone Number is required"),
    username: Yup.string()
        .test('len', 'Username must be less than 20 characters', val => val ? val.length < 20 : true)
        .test('no-space', `Username ${NO_SPACE_ERROR}`, val => val ? !val.includes(" ") : true)
        .required("Username is required")
});

export const ChangePasswordSchema = Yup.object().shape({
    password: Yup.string()
        .required("Current Password is required"),
    newPassword: Yup.string().required("Password is a required.")
        .matches(PASSWORD_REGEX, 'Password requirements is not met.'),
    confirmPassword: Yup.string()
        .when("newPassword", {
            is: (val: any) => (val && val.length > 0 ? true : false),
            then: Yup.string().oneOf(
                [Yup.ref("newPassword")],
                "Both passwords need to be the same"
            ),
        })
        .required("Confirm Password is required")
});

export const ForgotPasswordEmailSchema = Yup.object().shape({
    username: Yup.string()
        .required("Username is required")
        .test('no-space', `Username ${NO_SPACE_ERROR}`, val => val && val.includes(" ") ? false : true)

});

export const ForgotUsernameEmailSchema = Yup.object().shape({
    emailaddress: Yup.string()
        .required("This is required field.")
        .email("Invalid email address format")
        .test('no-space', `Emailaddress ${NO_SPACE_ERROR}`, val => val && val.includes(" ") ? false : true)

});

export const EligibilitySchema = Yup.object().shape({
    patientHospitalId: Yup.string()
        .required("Patient Hospital ID is required"),
    patientDOB: Yup.date()
    .transform(function (value, originalValue) {
      if (this.isType(value)) {
        return value;
      }
      const result = parse(originalValue, "YYYY-MM-DD", new Date());
      return result;
    })
    .typeError("Patient DOB is invalid")
    .required("Patient DOB is required and should be a valid date")
    .max(moment().format("YYYY-MM-DD"), "Patient DOB is invalid"),
    // priorAdministered: Yup.string()
    //     .required("Prior Administered is required"),
    // initialDoseDate: Yup.string()
    //     .when('priorAdministered', {
    //         is: 'yes',
    //         then: Yup.string().required("Initial Dose Date is required")
    //     }),
    totalAnticipatedVials: Yup.string()
        .required("Total Anticipated Vial(s) is required")
        .test('check', 'Total Anticipated Vial(s) is required', val => val ? !val.startsWith("Select"):true )
});