import React, {createContext, useState} from "react";
import {RegistrationForm} from "./RegistrationForm";
import {RegistrationBreadcrumb} from "./RegistrationBreadcrumb";
import logo from "./../assets/images/Logo.png"
import groupImage from "../assets/images/Group21.png"
import {BreadcrumbInterface} from "./Interfaces/Interfaces";
import {ContactUs} from "./ContactUs";
import {Link} from "react-router-dom";
export const FormContext = createContext<BreadcrumbInterface>({step: 0});

export const RegistrationSubmitted = () => {
    return (
        <div className='registration-form__wrapper'>
            <div>
                <h2>Registration submitted</h2>
                <p>Your registration has been successfully submitted for verification.</p>
                <p>If you do not receive an email regarding your registration status within 1 business day, please contact us.</p>
            </div>
            <div className='registration__contact-wrapper'><ContactUs/></div>
            <div className='registration__success-image'>
                <img src={groupImage} alt="group image"/>
            </div>
        </div>
    )
}