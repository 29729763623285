import React, {useContext, useState} from "react";
import errorImg from "./../assets/images/eligibility-error.png"
import {ContactUs} from "./ContactUs";

export const EligibilityError = () => {
    return (
        <div className="eligibility-error">
            <div className="eligibility-error__content">
                <div className="eligibility-error__image">
                    <img src={errorImg} alt="Eligibility Error Icon"/>
                </div>
                <h2 className="eligibility-error__title">We've run into an error while processing your request</h2>
                <p>If you need immediate assistance, please call us at (800) 813-5905 and one of our representatives will be glad to help.</p>
            </div>
            <ContactUs/>
        </div>
    )
}