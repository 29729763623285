import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { checkUniqueEmail } from "../../services/helper";
import { useUpdateUserMutation } from "../../services/usersApi";
import EligibilitySpinner from "../../templates/EligibilitySpinner";
import { APPLICATION_NAME, DEFAULT_UPDATE_USER_ERROR, EMAIL_EXISTS } from "../../utilities/constants";
import {
  formatPhoneNumber,
  emailValidationHelper,
} from "../../utilities/helper";

const EditUserDetails = (props: any) => {
  const { handleClose, data, error } = props;
  const [email, setEmail] = useState(data?.email);
  const [phone, setPhone] = useState(data?.cellNumber);
  const [phoneError, setPhoneError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [apiError, setApiError] = useState(false);
  const [apiErrorMessage, setApiErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [
    updateUser,
    {
      data: updateUserData,
      isSuccess: updateUserSuccess,
      isError: updateUserError,
      isLoading
    },
  ] = useUpdateUserMutation();

  useEffect(() => {
    handlePhoneInput(phone);
  }, []);

  useEffect(() => {
    if (updateUserSuccess && updateUserData?.isSuccess) window.location.reload();
    if (updateUserData?.errors?.length) {
      setLoading(false);
      setApiError(true);
      setApiErrorMessage(updateUserData?.errors?.[0]?.description);
    }
    if(updateUserError) {
      setLoading(false);
      setApiError(true);
      setApiErrorMessage(DEFAULT_UPDATE_USER_ERROR);
    }
  }, [updateUserSuccess, updateUserError, updateUserData])

  const handleSubmit = async () => {
    setLoading(true);
    const applicationName = APPLICATION_NAME;
    try {
    await updateUser({ emailAddress: email, cellNumber: phone, userName: data?.userName, applicationName });
    setLoading(false);
    }
    catch (error: any) {
      setApiErrorMessage(error?.message);
      setApiError(true);
      setLoading(false);
    }
  };

  const handlePhoneInput = (value: string) => {
    setApiError(false);
    const formattedPhoneNumber = formatPhoneNumber(value);
    if (formattedPhoneNumber?.length && formattedPhoneNumber?.length < 14) {
      setPhoneError("Phone number must have 10 digits");
    } else if (formattedPhoneNumber?.length === 0) {
      setPhoneError("");
    } else {
      setPhoneError("");
    }
    setPhone(formattedPhoneNumber);
  };

  const handleEmailInput = (value: string) => {
    setApiError(false);
    if (value === "") {
      setEmailError("Email is a required field");
    } else if (emailValidationHelper(value)) {
      setEmailError("Email is invalid");
    } else setEmailError("");
    setEmail(value);
  };

  return (
    <div className="popup-box">
      <div className="popup-box-container">
        <h2 className="popup-header">Edit User</h2>
        {error && <p className="input-feedback">{error}</p>}
        <div className="row mb-4">
          <div className="col-6">
            <label className="form-label">Email Address:</label>
            <input
              type="text"
              className={
                emailError ? "form-control is-invalid" : "form-control"
              }
              value={email}
              onChange={(e) => handleEmailInput(e.target.value)}
            />
            {emailError && <p className="invalid-feedback">{emailError}</p>}
          </div>
          <div className="col-6">
            <label className="form-label">Phone Number:</label>
            <input
              type="text"
              className={
                phoneError ? "form-control is-invalid" : "form-control"
              }
              value={phone}
              onChange={(e) => handlePhoneInput(e.target.value)}
              placeholder="(XXX) XXX-XXXX"
            />
            {phoneError && (
              <p className="invalid-feedback"> {phoneError} </p>
            )}
          </div>
        </div>
        
        {apiError && (
          <div className="row">
              <div className="col-8">
                <p className="alert alert-warning">{apiErrorMessage}</p>
              </div>
          </div>          
        )}
        <div className="row">
          <div className="d-flex flex-column flex-lg-row justify-content-md-start col-md-6 col-12">
            <button
              className="btn button button--primary"
              type="submit"
              disabled={emailError.length + phoneError.length !== 0 || loading || apiError}
              onClick={handleSubmit}
            >
              Save Changes {loading && <Spinner animation="border" variant="primary" size="sm"/>}
            </button>
          </div>
          <div className="d-flex justify-content-md-end col-md-6 col-12">
            <span
              className="text-danger mt-3 mt-md-0 btn btn-transparent"
              onClick={handleClose}
            >
              CANCEL
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditUserDetails;
