import { useOktaAuth } from '@okta/okta-react';
import React, { useCallback, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { logout } from '../features/authSlice';
import { useSessionTracker } from '../hooks/use-session-tracker';
import { TimeCountdown } from './TimeCountdown';
import { TimeExpirationModal } from './TimeExpirationModal';
import { useHistory} from "react-router-dom";
import { REACT_APP_SESSION_TIMEOUT_IN_MINUTES,REACT_APP_SESSION_WARNING_IN_MINUTES } from '../utilities/constants';

const minutesConversion = 60 * 1000;

const defaultSessionTimeoutInMinutes = 15;

const sessionTimeoutInMinutes = Number(
  window.hasOwnProperty('REACT_APP_SESSION_TIMEOUT_IN_MINUTES') ? 
      REACT_APP_SESSION_TIMEOUT_IN_MINUTES : defaultSessionTimeoutInMinutes
);
const sessionTimeout = sessionTimeoutInMinutes * minutesConversion;

const defaultWarningTimeoutInMinutes = 14;
const warningTimeoutInMinutes = Number(
  window.hasOwnProperty('REACT_APP_SESSION_WARNING_IN_MINUTES') ? 
      REACT_APP_SESSION_WARNING_IN_MINUTES : defaultWarningTimeoutInMinutes
);
const warningTimeout = warningTimeoutInMinutes * minutesConversion;

const timeoutDiff = sessionTimeout - warningTimeout;

if (warningTimeout === sessionTimeout) {
  // eslint-disable-next-line no-console
  console.warn(`[Eligibility] Session Timeout (${sessionTimeout}) and Idle Timeout (${warningTimeout}) are the same.`);
} else if (sessionTimeout < warningTimeout) {
  // eslint-disable-next-line no-console
  console.error(
    `[Eligibility] Session Timeout (${sessionTimeout}) is lower than the Idle Timeout (${warningTimeout}). This is a configuration error.`
  );
}

export const EligibilitySessionManager = () => {
  const dispatch = useDispatch();
  const [isWarned, setIsWarned] = useState(false);
  const { authState } = useOktaAuth();
  const { oktaAuth } = useOktaAuth();

  const history = useHistory();

  const onWarn = useCallback(() => {
    setIsWarned(true);
  }, [setIsWarned]);

  const onSessionTimeout = useCallback(() => {
    // Keep this around to clear the modal (prevents an error)
    setIsWarned(false);
    // Next tick the actual logout (prevents an error)
    setTimeout(() => dispatch(logout({oktaAuth, history})), 0);
    // setTimeout(() => dispatch(logout()), 0);
  }, [dispatch]);

  const onReset = useCallback(async () => {
    // if (isWarned) dispatch(renewSession({oktaAuth, history}));
    if (isWarned) {
      try {
        await oktaAuth?.session?.refresh();
      } catch (err) {
        console.error("Error refreshing token:", err);
      }
    }
    setIsWarned(false);
  }, [dispatch, isWarned, setIsWarned]);

  useSessionTracker({
    isActive: authState.isAuthenticated ?? false,
    onSessionTimeout,
    onWarn,
    // convert to minutes in ms
    sessionTimeout,
    // convert to minutes in ms
    warningTimeout,
  });

  return (
    <TimeExpirationModal
      body={`You have been idle for ${warningTimeoutInMinutes} minutes.`}
      footer={
        <>
          <TimeCountdown className='mr-3 text-muted' timeout={timeoutDiff} onTimeout={onSessionTimeout} />
          <Button onClick={onSessionTimeout} style={{backgroundColor : '#1c2474'}}>
            Sign Out
          </Button>
          <Button onClick={onReset} style={{backgroundColor : '#92c461'}}>
            Stay Signed In
          </Button>
        </>
      }
      header={<h4 className='m-0 p-0'>Are you still there?</h4>}
      modalProps={{ show: isWarned, onHide: () => null }}
    />
  );
};


