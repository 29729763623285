import React, {useContext} from "react";

import {FormContext} from "./RegistrationWrapper";

import orgRegImageGreen from '../assets/images/Org-Reg-Green.png';
import orgRegImageBlue from '../assets/images/Org-Reg-blue.png';
import orgRegImageOrange from '../assets/images/Org-Reg-Orange.png';
import userRegImageGrey from '../assets/images/User-Reg-grey.png';
import userRegImageBlue from '../assets/images/User-Reg-Blue.png';

export const STEP_0_IMAGES = [orgRegImageBlue, orgRegImageOrange];
export const STEP_1_IMAGES = [userRegImageGrey, userRegImageBlue];

export const RegistrationBreadcrumb = () => {
    const {step} = useContext(FormContext);
    return (
        <ul className='registration__breadcrumb'>
            <li><b>Step {step + 1} of 2</b></li>
            <li className={step === 0 ? 'registration__active-step' : 'registration__success'}>
                <BreadcrumContent step={step} text='Register Organization' images={STEP_0_IMAGES} />
            </li>
            <li className={step === 1 ? 'registration__active-step' : ''}>
                <BreadcrumContent step={step} text='Register User' images={STEP_1_IMAGES} />
            </li>
        </ul>
    )
}

interface BreadcrumContentProps {
    step: number,
    text: string,
    images: string[]
}

const BreadcrumContent = (props: BreadcrumContentProps) => {
    const { step, images, text } = props;
    return (
        <div className="registration__content">
            <div className="registration__image-container">
                <img src={images[step]} />
            </div>
            <span>{text}</span>
        </div>
    );
}
