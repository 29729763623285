import React from 'react';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';

import { REACT_APP_INSIGHTS_CONN_STR } from '../utilities/constants';

export const AppInsights = () => {
  const browserHistory = createBrowserHistory({ basename: '' });
  const connectionString = REACT_APP_INSIGHTS_CONN_STR;
  if (connectionString && connectionString?.trim()?.length > 0) {
    const reactPlugin = new ReactPlugin();
    const appInsights = new ApplicationInsights({
      config: {
        connectionString: connectionString,
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: { history: browserHistory }
        }
      }
    });
    appInsights.loadAppInsights();
  }

  return <></>;
};
