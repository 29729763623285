// React Library
import React from "react";
import Col from "react-bootstrap/Col";
import logo_image from '../../assets/images/Logo.png';

export const LoginLeftPanel = () => {
  return (
    <div>
      <div className="logo_image">
        <img src={logo_image} alt="Logo"/>
      </div>
      <div className='row'>
        <div className="col-7 mb-5">
          <h2>Welcome to the ServierONE Eligibility Portal</h2>
          <p>
            Servier Pharmaceuticals, like you, prioritizes patients. We are
            pleased to provide ONCASPAR® (pegaspargase) to qualified
            patients.
          </p>
          <p>
            Please log in/register and follow the simple steps to find out
            whether your patient is eligible.
          </p>
          <h3><u>Eligibility Criteria</u>:</h3>
          <ul>
            <li className="eligibility-criteria-section-text-color">
              Patient must be less than 1 month (31 days) or 21.5 years old
              or older.
            </li>
            {/* <li className="eligibility-criteria-section-text-color">
              Patients who have been administered ONCASPAR prior to December
              1, 2022,
              <br /> will continue to receive ONCASPAR for the remainder of
              their
              <br /> current treatment cycle.
            </li> */}
          </ul>
          <p>
            For questions, please call ServierONE Patient Support
            Services at (800) 813-5905. A representative is happy to
            assist you Monday - Friday 8am to 8pm (ET). If you prefer
            email, please contact us at&nbsp;
            <a href='mailto:USPatientServices@servier.com' className="hyperlink-color">USPatientServices@servier.com</a>.
          </p>
        </div>
      </div>
    </div>
  );
};
