import * as React from "react";
import {Card} from "react-bootstrap";

import {PasswordFormHeaderInterface} from "../components/Interfaces/Interfaces";

const PasswordFormHeader = (props: PasswordFormHeaderInterface) => {
    const {title, subtitle, htmlText} = props;
    return (
        <>
            <h2>{title}</h2>
            <p>
                {subtitle}
                <i>{htmlText}</i>
            </p>
        </>
    );
};

export default PasswordFormHeader;
