// React Imports
import * as React from "react";
import { Card } from "react-bootstrap";
// Project file imports
import ForgotPasswordEmailForm from "./ForgotPasswordEmailForm";
import PasswordFormHeader from "../../templates/PasswordFormHeader";
import {PasswordComplexity} from "../PasswordComplexity";

export const ForgotPasswordForm = () => {
  const title = "Forgot your password?";
  const subtitle = `Please enter your username below and click Send Email. You will receive an email with a link to reset your password. Click that link and follow the directions provided to continue. `;
  const htmlText = `All fields are required unless noted as optional.`;
  return (
    <div>
      <PasswordFormHeader title={title} subtitle={subtitle} htmlText={htmlText} />
      <div className="row">
        <div className="col-6">
          <ForgotPasswordEmailForm />
        </div>
      </div>
    </div>
  );
};
