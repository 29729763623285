// React Library
import React from "react";

// React Bootstrap Library
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { CreatePasswordWrapper} from "../components/CreatePassword";

export const CreatePassword = () => {
  return (
    <Container fluid>
      <Row>
        <CreatePasswordWrapper />
      </Row>
    </Container>
  );
};
