import { AnyType } from './../components/Interfaces/Interfaces';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import { RootState } from '../app/store';

export interface AuthState {
    user: any | null,
    token: string | null
    authMappingDetail: AnyType;
}

const initialState: AuthState = {
    user: null,
    token: null,
    authMappingDetail: null,
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setUser: (state, action: PayloadAction<{user: any, token: string }>) => {
            localStorage.setItem(
                "user", JSON.stringify({
                    user: action.payload.user,
                    token: action.payload.token
                })
            );
            state.user = action.payload.user;
            state.token = action.payload.token;
        },
        logout: (state: any, action: PayloadAction<any>) => {
            try {
                sessionStorage.clear();
                localStorage.clear();
                action.payload?.oktaAuth.tokenManager.clear();
                action.payload?.oktaAuth?.signOut({ postLogoutRedirectUri: window.location.origin + '/login' });
            }
            catch {
                action.payload?.history?.push("/login");
            }
        },
        setAuthDetails: (state, action: PayloadAction<{authMappingDetail: any}>) => {
            state.authMappingDetail = action.payload;
        }
    },
});

export const selectAuth = (state: RootState) => state.auth;

export const { setUser, logout, setAuthDetails } = authSlice.actions;

export default authSlice.reducer;
