import React from "react";

export const PasswordComplexity = () => {
    return <div className='pc'>
        <h3>Password Requirements:</h3>
        <ul className='pc__list'>
            <li>Minimum 12 characters in length</li>
            <li>At least 1 upper case letter (Alphabet)</li>
            <li>At least 1 numeric character (Number)</li>
            <li>At least 1 special character (!,#,@.$ etc)</li>
        </ul>

        <p className="password-requirements">
            Password should not have first name and/or last name. <br/>
            You can not use any of your previous 5 passwords.
        </p>
    </div>
}