import React from "react";
import {ViewRenderer} from "../components/ViewRenderer";
import {ForgotUsernameForm} from "../components/ForgotUsername/ForgotUsernameForm";
import {ContactUs} from "../components/ContactUs";

export const ForgotUsername = () => {
  return (
    <ViewRenderer bodyComponent={(
        <>
            <ForgotUsernameForm />
            <div className="mt-5">
                <ContactUs />
            </div>
        </>
    )} />
  );
};
