import React from "react";
import {ViewRenderer} from "../components/ViewRenderer";
import {ForgotPasswordForm} from "../components/ForgotPassword/ForgotPasswordForm";
import {ContactUs} from "../components/ContactUs";

export const ForgotPassword = () => {
  return (
    <ViewRenderer bodyComponent={(
        <>
            <ForgotPasswordForm />
            <div className="mt-5">
                <ContactUs />
            </div>
        </>
    )} />
  );
};
