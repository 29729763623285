// React Library
import React, { useState } from "react";
import { Link } from "react-router-dom";
// React Bootstrap Library
import Col from "react-bootstrap/Col";
import { Button, Card, Form } from "react-bootstrap";
import { ExclamationCircleFill } from "react-bootstrap-icons";

// Other Library
import { Field, Formik, FormikErrors, FormikHelpers } from "formik";
import axios from "axios";

// Project File Imports
import { ForgotUsernameEmailInterface } from "../Interfaces/Interfaces";
import { isObjectEmpty } from "../../utilities/utilities";
import {
    APPLICATION_NAME,
    FORGOT_USERNAME_RESPONSE,
    NO_SPACE_ERROR,
    REQUIRED_ERROR,
} from "../../utilities/constants";
import { GATEWAY_API_URL } from "../../api";
import { ForgotUsernameEmailSchema } from "../ValidationSchemas/ValidationSchemas";
import { TextInputField } from "../Forms/FormFields";

const ForgotUsernameURL = `${GATEWAY_API_URL}/api/User/ForgotUsername`;

const initialValues = {
    emailaddress: "",
};

/**
 * This method will handle the submittion of email form
 * @param values
 * @returns Promise which is having the status of user's sending email activity
 */
const handleSubmit = async (values: ForgotUsernameEmailInterface) => {
    const headers = {
        Accept: "text/plain",
        "Content-Type": "application/json",
    };
    const response = await axios.get(ForgotUsernameURL, {
        params: { applicationName: APPLICATION_NAME, emailId: values.emailaddress },
    });
    const { data } = response;
    return data?.loginResponse?.status;
};

const ForgotUsernameEmailForm = () => {
    const [emailResponse, setEmailResponse] = useState("");
    return (
        <Formik
            initialValues={initialValues}
            validationSchema={ForgotUsernameEmailSchema}
            onSubmit={(values) => {
                handleSubmit(values);
                setEmailResponse(FORGOT_USERNAME_RESPONSE);
            }}
        >
            {({ touched, errors, handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                    <div className="col-12">
                        <TextInputField name="emailaddress" label="Email Address:" type="text" />
                    </div>
                    <div className="col-6">
                        <button
                            className="btn button button--primary"
                            disabled={!isObjectEmpty(errors) || !touched}
                            type="submit"
                        >
                            Send Email
                        </button>
                    </div>
                    <div className="col-12">
                        {emailResponse.length > 0 && (
                            <p className="text-danger mt-3">
                                <ExclamationCircleFill /> {emailResponse}
                            </p>
                        )}
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default ForgotUsernameEmailForm;
