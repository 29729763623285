import { ViewRenderer } from "../components/ViewRenderer";
import React from "react";
import { PatientEligibilityList } from "../components/PatientEligibilityList";
import { SideMenu } from "../components/SideMenu";
import { PrivateLayout } from "../components/PrivateLayout";

export const PagePatientEligibility = () => {
  return (
    <PrivateLayout>
      <ViewRenderer
        bodyComponent={<PatientEligibilityList />}
        navComponent={<SideMenu />}
      />
    </PrivateLayout>
  );
};
