import {Formik, FormikHelpers} from "formik";
import * as React from "react";
import {useState} from "react";
import {Col, Form, Row} from "react-bootstrap";
import {APPLICATION_NAME, REQUIRED_ERROR} from "../../utilities/constants";
import {isObjectEmpty} from "../../utilities/utilities";
import {AddUserInterface, ChangePasswordInterface} from "../Interfaces/Interfaces";
import {
    CURRENT_PASSWORD_AS_NEW,
    DEFAULT_ERROR_MESSAGE,
    NEW_PASSWORD_NOT_MATCHED,
} from "../../utilities/constants";
import {TextInputField} from "../Forms/FormFields";
import {ChangePasswordSchema} from "../ValidationSchemas/ValidationSchemas";
import axios from "axios";
import {GATEWAY_API_URL} from "../../api";
import {logout} from "../../features/authSlice";
import {useOktaAuth} from "@okta/okta-react";
import {useHistory} from "react-router-dom";
import {useAppDispatch} from "../../app/hooks";
import {PasswordComplexity} from "../PasswordComplexity";
import { PrivateLayout } from "../PrivateLayout";

const ChangePassword = () => {
    const dispatch = useAppDispatch();
    const {oktaAuth} = useOktaAuth();
    const history = useHistory();
    const [apiError, setApiError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const initialValues = {
        password: "",
        newPassword: "",
        confirmPassword: ""
    };
    const [changePasswordErrorMsg, setChangePasswordErrorMsg] = useState<string | null>(null);
    const oktaTokenStorage = sessionStorage.getItem('okta-token-storage');
    const token = oktaTokenStorage ? JSON.parse(oktaTokenStorage || '') : null;
    if (isObjectEmpty(oktaTokenStorage)) {
        dispatch(logout({oktaAuth, history}));
    }
    const handleSubmit = async (values: ChangePasswordInterface) => {
        setChangePasswordErrorMsg(null);
        setLoading(true);
        setApiError(null);
        const headers = {
            'Accept': 'text/plain',
            'Content-Type': 'application/json',
            'authorization': token?.accessToken?.tokenType + ' ' + token?.accessToken?.value
        };
        const payload = {
            applicationName: APPLICATION_NAME,
            oldPassword: values.password,
            newPassword: values.newPassword
        }
        axios.post(
            GATEWAY_API_URL + '/api/User/ChangePassword',
            payload,
            {
                headers: headers
            })
            .then((response: any) => {
                if (response?.data?.isSuccess && response?.data?.errors?.length < 1) {
                    setApiError(null);
                    dispatch(logout({oktaAuth, history}));
                    return;
                } else {
                    const error = response?.data?.errors?.map((e: any) => e?.description).join(' ') || DEFAULT_ERROR_MESSAGE;
                    setApiError(error);
                }
            }).catch((e) => {
            setApiError(DEFAULT_ERROR_MESSAGE);
        }).finally(() => {
            setLoading(false);
        });
    };

    const hasError = (values: ChangePasswordInterface) => {
        const {password, newPassword, confirmPassword} = values;
        if (newPassword !== confirmPassword) {
            setChangePasswordErrorMsg(NEW_PASSWORD_NOT_MATCHED)
            return true;
        }
        if (newPassword === password) {
            setChangePasswordErrorMsg(CURRENT_PASSWORD_AS_NEW)
            return true;
        }
        return false;
    }

    const resetError = () => {
        setChangePasswordErrorMsg(null);
        setApiError(null);
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={ChangePasswordSchema}
            onSubmit={(
                values: ChangePasswordInterface,
                {setSubmitting}: FormikHelpers<ChangePasswordInterface>
            ) => {
                hasError(values)
                    ? setSubmitting(false)
                    : handleSubmit(values);
            }}
        >
            {(props) => {
                const {
                    values,
                    touched,
                    errors,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                } = props;
                return (
                    <Form onSubmit={handleSubmit}>
                        <h2>Change your Password</h2>
                        <div className='row'>
                            <div className="col-5">
                                <div className="row">
                                    <div>
                                        <TextInputField
                                            name="password"
                                            label="Current Password:"
                                            type="password"
                                            onChange={(e) => {
                                                handleChange(e);
                                                resetError()
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <TextInputField
                                            name="newPassword"
                                            label="New Password:"
                                            type="password"
                                            onChange={(e) => {
                                                handleChange(e);
                                                resetError()
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <TextInputField
                                            name="confirmPassword"
                                            label="Confirm New Password:"
                                            type="password"
                                            onChange={(e) => {
                                                handleChange(e);
                                                resetError()
                                            }}
                                        />
                                    </div>
                                    {changePasswordErrorMsg &&
                                        <p className='d-block invalid-feedback'>{changePasswordErrorMsg}</p>}
                                    {apiError &&
                                        <p className='d-block invalid-feedback'>{apiError}</p>}
                                    <div>
                                        <button
                                            className="btn button button--primary"
                                            type="submit"
                                            disabled={!isObjectEmpty(errors) || !touched || loading}
                                        >
                                            Change Password
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="offset-1 col-6">
                                <PasswordComplexity/>
                            </div>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default ChangePassword;
