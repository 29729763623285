// React Imports
import axios from "axios";
import { Field, Formik, FormikErrors, FormikHelpers } from "formik";
import * as React from "react";
import { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useCreateUserMutation } from "../../services/createUserApi";
import { checkUniqueEmail, checkUniqueUsername } from "../../services/helper";
import { APPLICATION_NAME, DEFAULT_ADD_USER_ERROR, DEFAULT_ERROR_MESSAGE, EMAIL_EXISTS, USERNAME_EXISTS } from "../../utilities/constants";
import { suffix } from "../../utilities/data";
import { isObjectEmpty } from "../../utilities/utilities";
import { validationRequiredError } from "../../utilities/validations";
import {FormattedPhoneNumber, SelectField, TextInputField} from "../Forms/FormFields";
import { AddUserInterface } from "../Interfaces/Interfaces";
import { PrivateLayout } from "../PrivateLayout";
import { UserSchema } from "../ValidationSchemas/ValidationSchemas";


const initialValues = {
  firstName: "",
  lastName: "",
  suffix: "",
  emailAddress: "",
  userPhoneNumber: "",
  username: "",
  setAsAnAdministrator: false,
};

const AddUser = () => {
  const [addUserError, setAddUserError] = useState(false);
  const [addUserErrorMessage, setAddUserErrorMessage] = useState("");
  const [
    createUser,
    {
      data: createUserData,
      isSuccess: createUserSuccess,
      isError: createUserError,
      error: createUserErrorMessage,
      isLoading
    },
  ] = useCreateUserMutation();
  

  /**
   * This method will handle the submittion of login form
   * @param values
   * @returns Promise which is having the status of user's login activity
   */
   const handleSubmit = async (values: AddUserInterface, setSubmitting: any) => {
    let { firstName, lastName, suffix, emailAddress, userPhoneNumber, username, setAsAnAdministrator } = values;
    setAddUserErrorMessage("");
    setAddUserError(false);
    const applicationName = APPLICATION_NAME;
    const userType = 'Contact'
    const userRole = setAsAnAdministrator ? 'Admin' : "User";
    const npi = "";
    const faxNumber = "";
    const externalUserId = "";
    const cellNumber = userPhoneNumber;
    const userName = username;
    

    const emailResponse = await checkUniqueEmail(values?.emailAddress);
    if (emailResponse?.data?.isUniqueEmail) {
      const usernameResponse = await checkUniqueUsername(values?.username);
      if (usernameResponse?.data?.isUniqueUserName) {
        await createUser({applicationName, userName, emailAddress, firstName, lastName, userType, userRole, npi, faxNumber, cellNumber, externalUserId });
      } else {
        setAddUserErrorMessage(USERNAME_EXISTS);
        setAddUserError(true)
        return;
      }
    } else {
      setAddUserErrorMessage(EMAIL_EXISTS);
      setAddUserError(true);
    }
  };

  useEffect(() => {
    if (createUserSuccess) {
      if (createUserData?.userId !== null) {
        window.location.reload();
        setAddUserErrorMessage("");
        setAddUserError(false);
      } else {
        setAddUserError(true);
        setAddUserErrorMessage(createUserData?.errors?.[0]?.description);
      }
    } else if (createUserError) {
      setAddUserErrorMessage(DEFAULT_ADD_USER_ERROR);
      setAddUserError(true)
    };
  }, [createUserSuccess, createUserData, createUserError]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={UserSchema}
      onSubmit={(
        values: any,
        { setSubmitting, resetForm }: FormikHelpers<any>
      ) => {
        setSubmitting(true);
        handleSubmit(values, setSubmitting)
        .finally(() => {setSubmitting(false);
        });
      }}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
        } = props;

        return (
          <div className="container mt-4">
            <Form onSubmit={handleSubmit} onChange={() => setAddUserError(false)}>
              <div className="row">
                <div className="col p-0">
                    <h2>Add User</h2>
                </div>
              </div>
              <div className="row">
                <div className="col-4 p-0">
                <TextInputField 
                  name="firstName"
                  label="First Name:"
                  type="text"
                />
                </div>
                <div className="col-4">
                <TextInputField 
                  name="lastName"
                  label="Last Name:"
                  type="text"
                />  
                </div>
                <div className="col-4" style={{paddingLeft: "0px"}}>
                  <SelectField
                    name="suffix"
                    label="Suffix:"
                    options={suffix}
                    optional={true}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-4 p-0">
                <TextInputField 
                  name="emailAddress"
                  label="Email Address:"
                  type="text"
                />
                </div>
                <div className="col-4">
                <FormattedPhoneNumber 
                  name="userPhoneNumber"
                  label="Phone Number:"
                  type="text"
                />
                </div>
              </div>
              <div className="row">
                <div className="col-4 p-0">
                <TextInputField 
                  name="username"
                  label="Username:"
                  type="text"
                />
                </div>
                <div className="col-6">
                  <Form.Group className="mb-3">
                    <label>
                      <Field
                        type="checkbox"
                        name="setAsAnAdministrator"
                        style={{
                          position: "relative",
                          top: "30px",
                          marginRight: "10px",
                        }}
                      />
                      <span
                        style={{ position: "relative", top: "12px", fontSize: "16px" }}
                      >
                        Set as an Administrator
                      </span>
                    </label>
                  </Form.Group>
                </div>
              </div>
              <div className="row">
                <div className="col p-0">
                  {addUserError && (
                    <p className='alert alert-warning'>{addUserErrorMessage}</p>
                  )}
                  <button
                    className="btn button button--primary"
                    type="submit"
                    disabled={!isObjectEmpty(errors) || isSubmitting}
                  >
                    {isSubmitting ? "Adding User..." : "Add User"}
                  </button>
                </div>
              </div>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

export default AddUser;
