import {createApi,fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import { GATEWAY_API_URL } from '../api';
import { getAuthorizationHeader } from '../utilities/utilities';

export const usersApi = createApi({
    reducerPath: 'usersApi',
    baseQuery: fetchBaseQuery({
        baseUrl: GATEWAY_API_URL,
        prepareHeaders: (headers, { getState }) => {
          const token = getAuthorizationHeader();
          if (token) headers.set("authorization", `${token}`);
          return headers;
        },
      }),
      endpoints: (builder) => ({
        getUsers: builder.query({
            query: (arg) => `/api/User/GetUsers`,
          }),
        updateUser: builder.mutation({
          query: (body: {
            applicationName: string,
            emailAddress: string,
            userName: string,
            cellNumber: string
          }) => {
              return {
                  url: '/api/User/UpdateUser',
                  method: 'post',
                  body
              };
          },
        }),
      }),
});

export const {useGetUsersQuery, useUpdateUserMutation} = usersApi;