import React, { useCallback, useEffect, useState } from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { CheckSquare, Square} from 'react-bootstrap-icons';
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {APPLICATION_NAME, DEFAULT_ERROR_MESSAGE, DEFAULT_UPDATE_USER_ERROR} from "../constants";
import axios from "axios";
import {GATEWAY_API_URL} from "../../api";
import {Spinner} from 'react-bootstrap';

import * as renderers from './renderers';


const EditUserRenderer = (params: ICellRendererParams) => {
  return (
    <div className="font-weight-normal text-left text-secondary text-underline">
      <u>EDIT</u>
    </div>
  );
};

const IsAdminRenderer = (params: ICellRendererParams) => {
  const isAdmin = params.data.isAdmin;
  const StatusIcon = isAdmin ? CheckSquare : Square;
  const userProfile = useAppSelector((state) => state?.auth?.user?.profile);
  const isCurrentUser = userProfile?.login === params.data.userName;
  const applicationName = APPLICATION_NAME;
  const token = JSON.parse(sessionStorage.getItem('okta-token-storage') || '');
  const dispatch = useAppDispatch();
  const [apiError, setApiError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const onClick = useCallback(async () => {
    if (isCurrentUser) return;
    
    if (
      window.confirm(
        `Are you sure you want to convert ${params.data.firstName} ${params.data.lastName} ${
          isAdmin ? 'from' : 'to'
        } Admin?`
      )
    ) {
      const newValue = !isAdmin;
      const headers = {
        'Accept': 'text/plain',
        'Content-Type': 'application/json',
        'authorization': token?.accessToken?.tokenType + ' ' + token?.accessToken?.value
      };
      const payload = {
          applicationName: applicationName,
          userName: params.data.userName,
          isAdmin: newValue
      };
      setLoading(true);
      setTimeout(() => {
        axios.post(
            GATEWAY_API_URL + '/api/User/SetRole',
            payload,
            {
                headers: headers
            })
            .then((response: any) => {
                if (response?.data?.success && response?.data?.errors?.length < 1) {
                    setApiError(null);
                    params.setValue(newValue);
                    return;
                } else {
                    const error = response?.data?.errors?.map((e: any) => e?.description).join(' ') || DEFAULT_UPDATE_USER_ERROR;
                    alert(error);
                }
            }).catch((e) => {
              if (window.confirm("There is an issue while changing the role of the user. Please reload the screen and try again.")) {
                window.location.reload();
                setApiError(DEFAULT_UPDATE_USER_ERROR);
               }
        }).finally(() => {
            setLoading(false);
        });

      }, 5000)
    }
  }, [isCurrentUser, isAdmin]);

  if(loading) {
    return  (
      <div>
        <StatusIcon className='mr-2 text-info' size={18}/>&nbsp; <Spinner animation='border' style={{position: "absolute", top: "12px"}} size='sm' />
      </div>
    )
  }

  return (
    <div onClick={onClick}>
      <StatusIcon className='mr-2 text-info' size={18}/> 
    </div>
  );
};

export const eligibilityFrameworkComponents = {
  ...renderers,
  EditUserRenderer,
  IsAdminRenderer
};
