import {ColDef, RowNode, ValueGetterParams, ValueSetterParams} from 'ag-grid-community';
import moment from "moment";

export const DEFAULT_DATE_FORMAT = 'MM/DD/YYYY';
export const globalDefaultColDefs: ColDef = {
    sortable: true,
    filter: true,
    resizable: true,
    autoHeight: true
};

export const gridDateFormatter = (keyAndFormat: { key: string; format?: string }) => (params: ValueGetterParams) => {
    return dateTableFormatAnother(params.data[keyAndFormat.key], keyAndFormat?.format) ?? '';
};

export function dateTableFormatAnother(date?: string | null, format?: string | null): string | null {
    if (!date) return null;

    return moment(date).format(format ?? DEFAULT_DATE_FORMAT);
}

export const dateColumnDefinition = (field: string, headerName?: string): ColDef => ({
    headerName,
    field,
    filter: 'agDateColumnFilter',
    valueGetter: gridDateFormatter({ key: field }),
    filterParams: {
        ...dateFilterParams
    }
});

export const dateFilterParams = {
    comparator(filterLocaleDate: Date, cellValue: string) {
        if (cellValue === null) return 0;

        const cellDate = moment(cellValue).utc();
        const filterDate = moment(filterLocaleDate).utc();

        return cellDate.isBefore(filterDate) ? -1 : cellDate.isAfter(filterDate) ? 1 : 0;
    }
};