import React from "react";
import { EligibilitySessionManager } from "./EligibilitySessionManager";

interface PrivateLayoutProps extends React.PropsWithChildren<unknown> {
  className?: string;
  fullWidth?: boolean;
  pageTitle?: string;
  subtoolbarChildren?: React.ReactNode;
  disableViewContainer?: boolean;
  contentKey?: string;
}

export const PrivateLayout = ({ children }: PrivateLayoutProps) => {
  return (
    <>
      <div>
        <EligibilitySessionManager />
        {children}
      </div>
    </>
  );
};
