import React, { useContext, useState, useEffect } from "react";
import moment from 'moment';
import { Form, Formik, FormikValues } from "formik";
import { getAuthorizationHeader, isObjectEmpty } from "../utilities/utilities";
import { EligibilitySchema } from "./ValidationSchemas/ValidationSchemas";
import { vialOptions } from "../utilities/data";
import { RadioField, SelectField, TextInputField, DOBDateInputField, InitialDoseDateInputField } from "./Forms/FormFields";
import { EligibilityFormContext } from "../pages/Homepage";
import {
  EligibilityResult,
  EligibilityValuesInterface,
} from "./Interfaces/Interfaces";
import {
  DEFAULT_ERROR_MESSAGE,
  LAST_DOSE,
  ROUTE_PATHS,
} from "../utilities/constants";
import axios from "axios";
import { GATEWAY_API_URL } from "../api";
import { useAppDispatch } from "../app/hooks";
import { useOktaAuth } from "@okta/okta-react";
import { useHistory } from "react-router-dom";

interface EliibilityRequest {
  hospitalId: string,
  dataOfBirth: string,
  isAdministered: boolean,
  initialDoseDate?: string,
  totalAnticipatedDose: string,
}

export const EligibilityForm = () => {
  const [isValidDOB, setIsValidDOB] = useState(true);
  const [isValidInitialDose, setIsValidInitialDose] = useState(true);
  const [isInitialDoseCorrect, setIsInitialDoseCorrect] = useState(true);
  const [initialDoseError, setInitialDoseError] = useState('');
  const isValidDate = isValidDOB && isValidInitialDose
  //const isValidDate = isValidDOB
  const dispatch = useAppDispatch();
  const { oktaAuth } = useOktaAuth();
  const history = useHistory();
  const token = JSON.parse(sessionStorage.getItem("okta-token-storage") || "");
  const { step, setStep } = useContext(EligibilityFormContext);
  const [apiError, setApiError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const redirectToErrorPage = () => {
    history.push(ROUTE_PATHS.eligibilityError);
  }
  const initialValues = {
    patientHospitalId: "",
    patientDOB: "",
    priorAdministered: "",
    initialDoseDate: "",
    totalAnticipatedVials: "",
  };
  const preparePayload = (values: EligibilityValuesInterface) => {
    let payload: EliibilityRequest = {
      hospitalId: values?.patientHospitalId,
      dataOfBirth: values?.patientDOB,
      isAdministered: values?.priorAdministered === "yes",
      totalAnticipatedDose: values?.totalAnticipatedVials
    };
    if (values.priorAdministered === "yes") {
      payload.initialDoseDate = values?.initialDoseDate;
    }
    return payload;
  };
  const checkEligibility = async (payload: EliibilityRequest) => {
    if (payload.isAdministered && typeof payload.initialDoseDate === "string" && payload.initialDoseDate.trim().length === 0) {
      setInitialDoseError('Date of Initial Dose is required and should be a valid date');
      setIsInitialDoseCorrect(false);
      return;
    }
    else if (payload.isAdministered && new Date(payload.initialDoseDate ?? new Date()) < new Date(payload.dataOfBirth)) {
      setInitialDoseError('Initial Dose Date should not be before Patient\'s Date of Birth');
      setIsInitialDoseCorrect(false);
      return;
    }
    else {
      setIsInitialDoseCorrect(true);
      setInitialDoseError('');
    }

    setLoading(false);
    setApiError(null);
    const headers = {
      Accept: "text/plain",
      "Content-Type": "application/json",
      Authorization: getAuthorizationHeader() || "",
    };
    axios
      .post(GATEWAY_API_URL + "/api/User/IsPatientEligible", payload, {
        headers: headers,
      })
      .then((response: any) => {
        if (response?.data?.isSuccess && response?.data?.errors?.length < 1) {
          setApiError(null);
          if (setStep) {
            setStep(step + 1);
          }
          history.push(ROUTE_PATHS.eligibilityResult, {
            formData: payload,
            eligibilityResult: response?.data,
          });
          return;
        } else {
          const error =
            response?.data?.errors?.map((e: any) => e?.description).join(" ") ||
            DEFAULT_ERROR_MESSAGE;
          setApiError(error);
        }
      })
      .catch((e) => {
        setApiError(DEFAULT_ERROR_MESSAGE);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    if (apiError) {
      redirectToErrorPage();
    }
  }, [apiError])
  return (
    <>
      <h2>Answer the questions below to find out if a patient is eligible</h2>
      <Formik
        initialValues={initialValues}
        validationSchema={EligibilitySchema}
        onSubmit={(values, { setFieldTouched }) => {
          checkEligibility(preparePayload(values));
        }}
      >
        {({ touched, errors, isSubmitting, values }) => (
          <Form>
            <div className="col-8">
              <TextInputField
                name="patientHospitalId"
                label="Patient's Hospital ID:"
                type="text"
              />
            </div>
            <div className="col-8">
              <DOBDateInputField
                name="patientDOB"
                label="Patient's Date of Birth:"
                type="date"
                isValidDOB={isValidDOB}
                setIsValidDOB={setIsValidDOB}
              />
            </div>
            <div className="col-8">
              <RadioField
                name="priorAdministered"
                label="Was the Patient administered ONCASPAR® within 31 days from their Date Of Birth?"
                options={[
                  { label: "Yes", value: "yes" },
                  { label: "No", value: "no" },
                ]}
              />
            </div>
            {values?.priorAdministered === "yes" && (
              <div className="col-8">
                <InitialDoseDateInputField
                  name="initialDoseDate"
                  label="Date of Initial Dose"
                  type="date"
                  className={!isInitialDoseCorrect ? "form-control is-invalid" : "form-control"}
                  isValidInitialDose={isValidInitialDose}
                  setIsValidInitialDose={setIsValidInitialDose}
                />
                {!isInitialDoseCorrect &&
                  <p style={{ color: 'red' }}>{initialDoseError}</p>}
              </div>
            )}
            <div className="col-8">
              <SelectField
                name="totalAnticipatedVials"
                label="Total Anticipated Vial(s) for Duration of Therapy:"
                options={vialOptions}
                tooltipContent={LAST_DOSE}
                selectDisplayText='Select Vial(s)'
              />
            </div>
            <p>
              NOTE: When selecting this, you are validating this to be the
              accurate amount of vials, which is important to ensure all
              patients are able to access the drug.
            </p>
            <button
              className="btn button button--primary"
              disabled={!isObjectEmpty(errors) || !isValidDate}
              type="submit"
            >
              Check Eligibility
            </button>
          </Form>
        )}
      </Formik>
    </>
  );
};
