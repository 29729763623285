import "react-app-polyfill/ie11";
import * as React from "react";
import { Formik, Form, FormikHelpers } from "formik";
import { HospitalPharmacyInfoForm } from "./HospitalPharmacyInfoForm";
import { UserInformation } from "./UserInformation";
import { useContext, useState } from "react";
import {
  RegistrationSchema,
  UserSchema,
} from "./ValidationSchemas/ValidationSchemas";
import { isObjectEmpty } from "../utilities/utilities";
import { RegistrationValuesInterface } from "./Interfaces/Interfaces";
import { FormContext } from "./RegistrationWrapper";
import axios from "axios";
import { GATEWAY_API_URL } from "../api";
import { useHistory } from "react-router-dom";
import {
  DEFAULT_ERROR_MESSAGE,
  EMAIL_EXISTS,
  PROGRAM_ID,
  USERNAME_EXISTS,
  NPI_EXISTS,
} from "../utilities/constants";
import EligibilitySpinner from "../templates/EligibilitySpinner";
import { checkUniqueNpi, checkUniqueEmail, checkUniqueUsername } from "../services/helper";

export const registrationFlow = ["HospitalPharmacyInfo", "UserInfo"];

export const RegistrationForm = () => {
  const history = useHistory();
  const { step, setStep } = useContext(FormContext);
  const lastStep = registrationFlow.length - 1;
  const [apiError, setApiError] = useState<string | null>(null);
  const [isUniqueNpi, setIsUniqueNpi] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const initialValues = {
    organizationNPI: "",
    organizationName: "",
    organizationTaxId: "",
    address: "",
    address2: "",
    city: "",
    state: "",
    zipCode: "",
    organizationPhoneNumber: "",
    faxNumber: "",
    firstName: "",
    lastName: "",
    suffix: "",
    emailAddress: "",
    userPhoneNumber: "",
    username: "",
  };

  const [formValidationSchema, setFormValidationSchema] = useState<any>({});

  const changeStep = () => {
    if (setStep) {
      setStep(step + 1);
    }
  };

  const submitRegistration = (values: any) => {
    setLoading(true);
    setApiError(null);

    const payload = {
      PracticeInfo: {
        ProgramId: PROGRAM_ID,
        PracticeName: values?.organizationName,
        TaxId: values?.organizationTaxId,
        NPI: values?.organizationNPI,
        AddressLine1: values?.address,
        AddressLine2: values?.address2,
        City: values?.city,
        State: values?.state,
        Zip: values?.zipCode?.toString(),
        PhoneNumber: values?.organizationPhoneNumber?.toString(),
        FaxNumber: values?.faxNumber?.toString(),
      },
      AdministratorInfo: {
        IsPhysician: 0,
        NPI: values?.organizationNPI,
        FirstName: values?.firstName,
        LastName: values?.lastName,
        Suffix: values?.Suffix,
        PhoneNumber: values?.userPhoneNumber?.toString(),
        FaxNumber: values?.faxNumber?.toString(),
        EMail: values?.emailAddress,
        UserName: values?.username,
      },
      TermsAndConditionsAcknowledgement: true,
      BAAAcknowledgement: true,
      BAALanguageAcknowledgement: true,
      BAASignatureText: "",
      BAASignatureDate: new Date(),
    };

    checkUniqueNpi(values?.organizationNPI)
      .then((response: any) => {
        console.log(response);
        console.log(response?.data);
        console.log(response?.data?.isUniqueNpi);
        if (response?.data?.isUniqueNpi) {
          checkUniqueEmail(values?.emailAddress)
            .then((response: any) => {
              if (response?.data?.isUniqueEmail) {
                checkUniqueUsername(values?.username).then((response: any) => {
                  if (response?.data?.isUniqueUserName) {
                    axios
                      .post(GATEWAY_API_URL + "/api/Registration", payload)
                      .then((response: any) => {
                        if (
                          response?.data?.isSuccess &&
                          response?.data?.errors?.length < 1
                        ) {
                          setApiError(null);
                          history.push("/registration-submitted");
                          return;
                        } else {
                          const error =
                            response.errors
                              ?.map((e: any) => e?.description)
                              .join(" ") || DEFAULT_ERROR_MESSAGE;
                          setApiError(error);
                        }
                      });
                  } else {
                    setApiError(USERNAME_EXISTS);
                    return;
                  }
                });
              } else {
                setApiError(EMAIL_EXISTS);
                return;
              }
            })
        } else {
          setApiError(NPI_EXISTS);
          return;
        }
      })
      .catch((e) => {
        setApiError(DEFAULT_ERROR_MESSAGE);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSubmit = (values: RegistrationValuesInterface) => {
    step < lastStep ? changeStep() : submitRegistration(values);
  };

  const _renderSteps = (formState: {
    touched: any;
    errors: any;
    isSubmitting: any;
    values: any;
    setIsUniqueNpi: any;
  }) => {
    switch (step) {
      case 0:
        setFormValidationSchema(RegistrationSchema);
        return <HospitalPharmacyInfoForm {...formState} />;
      case 1:
        setFormValidationSchema(UserSchema);
        return <UserInformation {...formState} />;
      default:
        setFormValidationSchema({});
        return <div>Not Found</div>;
    }
  };

  return (
    <div>
      {loading && <EligibilitySpinner />}
      <div className="registration-form__wrapper">
        <Formik
          initialValues={initialValues}
          validationSchema={formValidationSchema}
          validateOnMount={false}
          onSubmit={(
            values: RegistrationValuesInterface,
            { setFieldTouched }: FormikHelpers<RegistrationValuesInterface>
          ) => {
            if (step < lastStep) {
              setFieldTouched("firstName", false);
              setFieldTouched("lastName", false);
              setFieldTouched("suffix", false);
              setFieldTouched("emailAddress", false);
              setFieldTouched("userPhoneNumber", false);
              setFieldTouched("username", false);
            }
            handleSubmit(values);
          }}
        >
          {({ touched, errors, isSubmitting, values }) => (
            <Form>
              {_renderSteps({ touched, errors, isSubmitting, values, setIsUniqueNpi })}
              {apiError && <p className="alert alert-warning">{apiError}</p>}
              <button
                className={`btn button button--primary ${!isObjectEmpty(errors) ? "disabled" : ""
                  }`}
                disabled={loading || !isUniqueNpi}
                type="submit"
              >
                {step === lastStep ? "Submit Registration" : "Next"}
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
