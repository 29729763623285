import React, { useEffect } from 'react';

export const LuckyOrange = () => {
  useScript('https://tools.luckyorange.com/core/lo.js?site-id=1474e2ef');

  return <></>;
};
const useScript = (url: string) => {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = url;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [url]);
};
