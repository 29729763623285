// React Library
import React from "react";
// React Bootstrap Library
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
// Project File Imports
import { ResetPasswordWrapper } from "../components/ResetPassword";
import { SideMenu } from "../components/SideMenu";
import { ViewRenderer } from "../components/ViewRenderer";

export const ResetPassword = () => {
  return <ResetPasswordWrapper />;
};
