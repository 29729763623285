// React Imports
import * as React from "react";
import { Card } from "react-bootstrap";
// Project file imports
import ForgotUsernameEmailForm from "./ForgotUsernameEmailForm";
import UsernameFormHeader from "../../templates/PasswordFormHeader";

export const ForgotUsernameForm = () => {
  const title = "Forgot your username?";
  const subtitle = `Please enter your email address below and click Send Email. If you are recognized as a registered user in our system, you'll receive an email with your username. `;
  const htmlText = `All fields are required unless noted as optional.`;
  return (
    <div>
      <UsernameFormHeader title={title} subtitle={subtitle} htmlText={htmlText} />
      <div className="row">
        <div className="col-6">
          <ForgotUsernameEmailForm />
        </div>
      </div>
    </div>
  );
};
