import React from "react";
import {RegistrationWrapper} from "../components/RegistrationWrapper";
import { Footer } from "../templates/Footer";

export const PageRegistration = () => {
    return (
        <>
            <div style={{position: "relative"}}>
                <RegistrationWrapper />
            </div>
        </>
        
    )
}