import { userInfoApi } from "./../services/userInfoApi";
import { createUserApi } from "./../services/createUserApi";
import { authApi } from "./../services/authApi";
import { verifyResetTokenApi } from "./../services/verifyResetTokenApi";
import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query/react";
import authReducer from "../features/authSlice";
import userProfileReducer from "../features/userProfileSlice";
import { usersApi } from "../services/usersApi";
import {usersEligibilityListApi} from "../services/usersEligibilityListApi";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    userProfile: userProfileReducer,
    [authApi.reducerPath]: authApi.reducer,
    [verifyResetTokenApi.reducerPath]: verifyResetTokenApi.reducer,
    [createUserApi.reducerPath]: createUserApi.reducer,
    [userInfoApi.reducerPath]: userInfoApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [usersEligibilityListApi.reducerPath]: usersEligibilityListApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authApi.middleware,
      verifyResetTokenApi.middleware,
      createUserApi.middleware,
      userInfoApi.middleware,
      usersApi.middleware,
      usersEligibilityListApi.middleware
    ),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
setupListeners(store.dispatch);
