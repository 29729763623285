import React, { useEffect, useState } from "react";

const PopupBox = (props: any) => {
  const {heading, handleClose} = props;

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
        document.body.style.overflow = 'auto';
    }
  }, []);
  return (
    <div className="popup-box">
      <div className="popup-box-container link-box" style={{padding: "10px 10px 0px 10px", overflowX: "hidden"}}>
        <div className="row popup-box-header">
          <h2 className="popup-box-heading">{heading}</h2>
            <span className="cross-button" onClick={handleClose}>X</span>
        </div>
        <div className="row popup-box-body">
            {props.children}
        </div>
        <div className="row popup-box-footer">
          <button className="btn button button--primary" onClick={handleClose}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default PopupBox;
