import React from "react";
import home from "../assets/images/home.png";
import patientEligibility from "../assets/images/Patient-Eligibility-icon.png";
import settings from "../assets/images/settings.png";
import logoutImg from "../assets/images/logout.png";
import {useAppDispatch} from "../app/hooks";
import {logout} from "../features/authSlice";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {Link, useHistory} from "react-router-dom";
import {useOktaAuth} from "@okta/okta-react";
import {ROUTE_PATHS} from "../utilities/constants";

export const SideMenu = () => {
    const dispatch = useAppDispatch();
    const {oktaAuth} = useOktaAuth();
    const history = useHistory();
    return (<div className='app-nav'>
            <ul className="nav flex-column side-nav">
                <li className="nav-item">
                    <Link to="/eligibility">
                        <div className='nav-item__icon-wrapper'>
                            <img src={home}/>
                        </div>
                        <span className="nav-link">
                        Home
                    </span>
                    </Link>
                </li>
                <li className="nav-item">
                    <Link to={ROUTE_PATHS.patientEligibility}>
                        <div className='nav-item__icon-wrapper'>
                            <img src={patientEligibility}/>
                        </div>
                        <span className="nav-link">
                        Patient Eligibility List
                    </span>
                    </Link>
                </li>
                <li className="nav-item">
                    <Link to="/user-management">
                        <div className='nav-item__icon-wrapper'>
                            <img src={settings}/>
                        </div>
                        <span className="nav-link">
                        User Administration
                    </span>
                    </Link>
                </li>
                <li className="nav-item nav-item__logout" onClick={() => dispatch(logout({oktaAuth, history}))}>
                    <div style={{marginLeft: "4px"}}>
                        <div className='nav-item__icon-wrapper'>
                            <img src={logoutImg}/>
                        </div>
                        <span className="nav-link-logout">
                      Log Out
                    </span>
                    </div>
                    <FontAwesomeIcon className='nav-item__logout-icon' icon={faChevronRight}/>
                </li>
            </ul>
        </div>
    );
};
