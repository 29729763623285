import React from "react";

import {AdminUserWrapper} from "../components/AdminUser";
import {ViewRenderer} from "../components/ViewRenderer";
import {SideMenu} from "../components/SideMenu";
import { PrivateLayout } from "../components/PrivateLayout";

export const AdminUser = () => {
    return (
        <PrivateLayout>
            <ViewRenderer
                bodyComponent={<AdminUserWrapper/>}
                navComponent={<SideMenu/>}
            />
        </PrivateLayout>
    );
};
