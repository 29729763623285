import React from "react";
import assistance from "../assets/images/assistance@2x.png";
import { useLocation } from 'react-router-dom';

export const NeedHelp = () => {
    const {state} = useLocation<any>();
    const eligibilityResult = state?.eligibilityResult;

    return <div>
            {eligibilityResult?.isEligible.toString()=='false' ? 
                <div className='need-help-negative-scenario alert'>
                    <div className='need-help-negative-scenario__header'>
                        <div className='need-help-negative-scenario__image-wrap'>
                            <img src={assistance} alt="need assistance image"/>
                        </div>
                        <h2>Need help?</h2>
                    </div>
                    <p>Call one of our Patient Experience Managers at (800) 813-5905.</p>
                </div>
            :
            <div className='need-help alert'>
                <div className='need-help__header'>
                    <div className='need-help__image-wrap'>
                        <img src={assistance} alt="need assistance image"/>
                    </div>
                    <h2>Need help?</h2>
                </div>
                
                <p>Call one of our Patient Experience Managers at (800) 813-5905.</p>
            </div>}
        </div>
}