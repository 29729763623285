import React, { useEffect, useState } from "react";

const PrivacyPolicy = (props: any) => {
  const isContainer = props?.popup ? 'container link-text':'container';
  return (
    <>
    <div className={isContainer}>
    {!props?.popup && (<span><br /><h2>Privacy Statement</h2></span>)}
      <span className="link-paragraph-text">
        CareMetx Privacy Policy, Effective Date of January 1, 2020, Updated
        February 28, 2022
        <br />
        <br />
        <strong>General Privacy Policy</strong>
        <br />
        <br />
        CareMetx, LLC (“CareMetx”), is committed to protecting the privacy of
        your information collected by CareMetx systems, websites, portals, other
        interfaces (individually and collectively referred to as “CareMetx
        Systems”).
        <br />
        <br />
        This Privacy Policy (the “Policy”) describes CareMetx’s privacy
        practices and how CareMetx uses the information you provide when using
        our CareMetx Systems. By using CareMetx Systems, you agree to and
        consent to the collection and use of your information as described
        below. &nbsp;CareMetx is also referred to in this Policy as “We” and
        “Us.”
        <br />
        <br />
        If you do not want Us to use your information as described in this
        Policy, please do not use CareMetx Systems. Additionally, and in
        accordance with applicable law, you may opt out of use of your
        information as described in this Policy by contacting CareMetx as
        described below.
        <br />
        <br />
        <strong>Your Information Is Never Sold</strong>
        <br />
        <br />
        CareMetx does not sell your information, and will only use it as
        necessary for the program for which it is collected, or as otherwise
        described in this Policy.
        <br />
        <br />
        <ol>
          <li>
            <strong>About Us</strong>
            <br />
            <br />
            CareMetx, LLC, operates and manages its proprietary CareMetx Systems
            to perform the services (collectively, the “Services”) you are
            accessing. Before We share any information you provide, We will
            endeavor to ensure that you understand who will receive your
            information and why they are requesting it. If you do not want to
            share your information in the way described, you may decline to
            complete any agreement or authorization process, or opt out. You
            understand that if you do not agree or authorize the disclosure(s)
            related to a program, you may not be able to participate.
            <br />
            <br />
            CareMetx Systems may provide you with links to websites of other
            organizations and companies (collectively, “Third Party Sites”).
            &nbsp;Third Party Sites may offer you materials and services as well
            as links to other sites. CareMetx does not accept, and hereby
            disclaims, any responsibility or liability for any use of personal
            data collected or which otherwise may arise from any use or access
            of any Third Party Sites. We strongly recommend that you read the
            privacy policies and any other terms and conditions before using or
            submitting any personal information or other data to any Third Party
            Sites.
            <br />
            <br />
          </li>
          <li>
            <strong>Information Collected</strong>
            <br />
            <br />
            We collect information from individuals who visit CareMetx Systems
            (“Visitors”) and individuals who subscribe to use the Services
            (“Customers”). In order to be able to use the Services, Customers
            may have to electronically submit data or information (“Customer
            Data”). When signing up to use the Services, We may ask Customers
            for certain identifying information so that We can verify the
            Customer’s identity. The Customer Data that We may ask for may
            include, but is not necessarily limited to, name, birth date,
            address, practice name, specialty, address, email address, fax
            number, NPI number, DEA number, and Tax ID number. We may also
            request that you provide Us with a verification code that We send to
            you via email or other means. In some cases, We may request
            additional information to help verify your identity. If you choose
            to complete any agreement or attestation through any CareMetx
            Systems, We may request that you submit an electronic signature.
            When expressing an interest in obtaining additional information
            about the Services, We will require you to provide Us with personal
            contact information, such as name, address, phone number, email
            address, and other similar information, as necessary and applicable
            (“Required Contact Information”). As you navigate CareMetx Systems,
            We may also collect information through the use of commonly used
            information-gathering tools, such as cookies (“Website Navigational
            Information”). Website Navigational Information includes standard
            information from your web browser (such as browser type and browser
            language), your Internet Protocol (“IP”) address, and the actions
            you take on CareMetx Systems (such as, but not necessarily limited
            to, pages viewed and the links clicked).
            <br />
            <br />
          </li>
          <li>
            <strong>Use of Information Collected</strong>
            <br />
            <br />
            We use the information you provide to Us to perform the Services
            offered through the CareMetx Systems and as otherwise necessary or
            required by the program. Additionally, we may use Website
            Navigational Information and other Customer Data provided to operate
            and improve CareMetx Systems, as permitted by applicable law.
            <br />
            <br />
          </li>
          <li>
            <strong>Website Navigational Information</strong>
            <br />
            <br />
            We use commonly used information-gathering tools to collect Website
            Navigational Information. This section describes the types of
            Website Navigational Information that may be collected by CareMetx
            websites and portals (collectively referred to as “Site”) and how
            this information may be used.
            <br />
            <br />
            <strong>Cookies</strong>
            <br />
            <br />
            We use cookies to make interactions with our Site easy and
            meaningful. When you visit our Site, one of our servers may send a
            cookie to your computer. Standing alone, cookies do not personally
            identify you. They merely recognize your web browser. We use cookies
            that are session-based and persistent. Session cookies exist only
            during one session. They disappear from your computer when you close
            your browser software or turn off your computer. Persistent cookies
            remain on your computer after you close your browser or turn off
            your computer. If you have chosen to identify yourself to Us, then
            We use session cookies containing encrypted information to allow Us
            to uniquely identify you. Each time you log into the Services, a
            session cookie containing an encrypted unique identifier that is
            tied to your account is placed your browser. These session cookies
            allow Us to uniquely identify you when you are logged into the
            Services and to process your online transactions and requests.
            Session cookies are required to use the Services. We use persistent
            cookies that we can read and use to identify browsers that have
            previously visited our Site. If you disable your web browser’s
            ability to accept cookies, you will be able to navigate the Site,
            but you may not be able to successfully use the Services. We may use
            information from session and persistent cookies in combination with
            Data about our Customers to provide you with information and operate
            the Services.
            <br />
            <br />
            <strong>IP Addresses</strong>
            <br />
            <br />
            When you visit a Site, we collect your IP addresses to track and
            aggregate non-personal information. For example, We use IP addresses
            to monitor the regions from which Customers and Visitors navigate
            our website. We also collect IP addresses from Customers when they
            log into the Services as part of the Company’s security features.
            <br />
            <br />
            <strong>Third Party Cookies</strong>
            <br />
            <br />
            From time-to-time, We engage third parties to track and analyze
            usage and volume statistical information from individuals who visit
            our website. This information will not contain personal information
            or Customer Data.
            <br />
            <br />
          </li>
          <li>
            <strong>Sharing of Information Collected</strong>
            <br />
            <br />
            We may share Data about Customers with our service providers: (i) to
            ensure the quality of information provided; (ii) where required by
            the applicable program; or (iii) to perform the Services, as
            applicable. Unless described in this privacy statement, or the
            agreement/authorization that you may sign, We do not share, sell,
            rent, or trade any information provided with third parties for their
            promotional purposes. If you agree/authorize the sharing of your
            information, We will share your information with the party or
            parties described in that agreement/authorization.
            <br />
            <br />
            We reserve the right to use or disclose information provided if
            required by law or if We reasonably believe that use or disclosure
            is necessary to protect our rights and/or to comply with a judicial
            proceeding, court order, or legal process. We may share information
            We collect to another entity as part of a sale, merger, or
            reorganization, if applicable. If you do not wish Us to share
            personally identifiable information you provide as described herein,
            or if you wish to examine or update any personal information you may
            have provided to Us, you may contact Us in accordance with Section
            10 below.
            <br />
            <br />
          </li>
          <li>
            <strong>Customer Data</strong>
            <br />
            <br />
            Customers may electronically submit Customer Data to the Services
            for hosting and processing purposes (“Customer Data”). Such Customer
            Data will be used for the performance of Services or as required by
            the applicable program.
            <br />
            <br />
          </li>
          <li>
            <strong>Security</strong>
            <br />
            <br />
            We implement an ISO 27001 aligned and SOC2 Type 2 Audited security
            program, and a defense-in-depth control strategy. This includes but
            is not limited to:
            <br />
            <br />
            <ul style={{ listStyleType: "disc" }}>
              <li>Policies and procedures governing Our security program;</li>
              <li>
                Encryption of all sensitive or regulated communications with TLS
                in transit and AES at rest;
              </li>
              <li>
                Strong Access controls on all sensitive or regulated systems.
                All infrastructure is hardened and tested via vulnerability
                scanning and penetration testing;
              </li>
              <li>
                Risk management policies and procedures including internal and
                external security testing and risk assessments; and{" "}
              </li>
              <li>
                Additional controls including but not limited to firewalls, WAF,
                IDS, and Anti-Malware.
              </li>
            </ul>
            <br />
            This notwithstanding, nothing in this Policy constitutes a guarantee
            of security. Customers are responsible for maintaining the security
            and confidentiality of any verification codes that they may receive
            and of the systems and devices they use to access any Services or
            Sites.
            <br />
            <br />
          </li>
          <li>
            <strong>Collection of Information from Minors</strong>
            <br />
            <br />
            In accordance with the U.S. Children’s Online Privacy Protection Act
            of 1998, you are notified that We do not knowingly collect
            identifiable information from anyone under the age of 13 through the
            Site, nor is the Site intended for users younger than 13 years of
            age. If you are under 13, please do not use the Site or otherwise
            provide any information that would allow Us to identify you. We
            encourage parents and legal guardians to monitor their children’s
            internet usage and to help enforce this Policy by instructing their
            children to never provide identifying information through any
            website without their permission. Please contact Us in accordance
            with Section 10 if you discover an individual under 13 has submitted
            their information contrary to this Policy.
            <br />
            <br />
          </li>
          <li>
            <strong>Changes to this Privacy Statement</strong>
            <br />
            <br />
            We reserve the right to change this Policy, and when updated, the
            effective date of the new version will be at the top of this policy.
            <br />
            <br />
          </li>
          <li>
            <strong>Contacting Us</strong>
            <br />
            <br />
            If you have any questions, comments or requests regarding this
            Policy or our processing of your information, please contact: <br />
            <br />
            Email:{" "}
            <span>
                <a href="mailto:privacyrequests@caremetx.com">
                  privacyrequests@caremetx.com
                </a>
            </span>
            <br />
            <br />
            Phone: 1-877-690-0220 (Toll Free)
            <br />
            <br />
          </li>
          <li>
            <strong>Supplemental Privacy Notices</strong>
            <br />
            <br />
            Some states have specific requirements related to consumer privacy.
            If you would like more information regarding our support of the
            relevant policies for your state, please reference your state below:
            <br />
            <br />
            <strong>California</strong>
            <br />
            <br />
            CareMetx permits residents of California to use and access the Site
            and therefore, it is our intent to comply with the California
            Business and Professions Code §22575-22579, the California Consumer
            Privacy Act of 2018 (“CCPA”) and California Civil Code § 1798.83,
            known as the “Shine the Light” law. If you are a California
            resident, you may request certain information regarding our
            disclosure of Personal Information to any third parties for their
            direct marketing purposes. You may also contact Us at either method
            provided in Section 10 with any questions or to exercise your rights
            as a California Resident, or resident of another state where
            applicable law requires. Any terms defined in the CCPA have the same
            meaning when used in this notice.
            <br />
            <br />
            <span style={{ textDecoration: "underline" }}>
              Information We Collect
            </span>
            <br />
            <br />
            We collect information that identifies, relates to, describes,
            references, is capable of being associated with, or could reasonably
            be linked, directly or indirectly, with a particular consumer or
            device (“Personal Information”). In particular, We have collected
            the following categories of Personal Information from consumers
            within the last twelve (12) months:
            <br />
            <br />
            <img
              height="469"
              width="100%"
              src="https://caremetx.com/wp-content/uploads/2021/08/Table.png"
            ></img>
            <br />
            <br />
            Personal information does not include:
            <br />
            <br />
            <ul style={{ listStyleType: "disc" }}>
              <li>Publicly available information from government records.</li>
              <li>De-identified or aggregated consumer information.</li>
              <li>
                Information excluded from the CCPA’s scope, such as:
                <br />
                <ul style={{ listStyleType: "circle" }}>
                  <li>
                    Health or medical information covered by the Health
                    Insurance Portability and Accountability Act of 1996 (HIPAA)
                    and the California Confidentiality of Medical Information
                    Act (CMIA) or clinical trial data.
                  </li>
                  <li>
                    Financial Information covered by the Gramm-Leach-Bliley Act,
                    and implementing regulations.
                  </li>
                </ul>
              </li>
            </ul>
            <br />
            <br />
            We obtain the categories of personal information listed above from
            the following categories of sources:
            <br />
            <br />
            <ul style={{ listStyleType: "disc" }}>
              <li>
                Directly and indirectly from activity on our Site. For example,
                from submissions through our website portals or websites usage
                details collected automatically. Indirectly from you when you
                visit and interact with our Site.
              </li>
              <li>
                Directly from you when you submit information to Us. For
                example, if you submit a form to Us, or provide Us with
                information over the telephone, that contains your personal
                information in connection to your use of our Services.
              </li>
            </ul>
            <br />
            <br />
            <span style={{ textDecoration: "underline" }}>
              Use of Personal Information
              <br />
              <br />
            </span>
            We may use or disclose the personal information We collect for one
            or more of the following business purposes:
            <ul style={{ listStyleType: "disc" }}>
              <li>
                To provide you with information, products or services that you
                request from Us.
              </li>
              <li>
                To provide you with email alerts, event registrations and other
                notices concerning our products or services, or events or news,
                that may be of interest to you.
              </li>
              <li>To improve our Site and present its contents to you.</li>
              <li>For testing, research, analysis and product development.</li>
              <li>
                As necessary or appropriate to protect the rights, property or
                safety of CareMetx, CareMetx’s clients, or others.
              </li>
              <li>
                To respond to law enforcement requests and as required by
                applicable law, court order, or governmental regulations.
              </li>
              <li>
                As described to you when collecting your personal information or
                as otherwise set forth in the CCPA.
              </li>
              <li>
                To evaluate or conduct a merger, divestiture, restructuring,
                reorganization, dissolution, or other sale or transfer of some
                or all of our assets, whether as a going concern or as part of
                bankruptcy, liquidation, or similar proceeding, in which
                personal information held by Us is among the assets transferred.
              </li>
            </ul>
            <br />
            <br />
            We will not collect additional categories of personal information or
            use the personal information We collected for materially different,
            unrelated, or incompatible purposes without providing you notice.
            <br />
            <br />
            <span style={{ textDecoration: "underline" }}>
              Disclosure of Personal Information
            </span>
            <br />
            <br />
            <p>
              We may disclose your personal information to a third party for a
              business purpose. When We disclose personal information for a
              business purpose, We enter a contract that describes the purpose
              and requires the recipient to both keep that personal information
              confidential and not use it for any purpose except performing the
              contract.
            </p>
            <p>
              In the preceding twelve (12) months, We may have disclosed the
              following categories of personal information for a business
              purpose:
            </p>
            <ul style={{ listStyleType: "disc" }}>
              <li>
                Unique personal identifier, Name, Date of birth, Signature,
                Address, Telephone number, Insurance policy number, Financial
                information, Medical information, or Health insurance
                information.
              </li>
            </ul>
            <br />
            <p>
              We may disclose your personal information for a business purpose
              to the following categories of third parties:
            </p>
            <ul style={{ listStyleType: "disc" }}>
              <li>
                Pharmaceutical company that manufactures the medication you are
                taking
              </li>
              <li>Contracted Business Partners</li>
              <li>Contracted Third Party Vendors</li>
            </ul>
            <br />
            <p>
              <span style={{ textDecoration: "underline" }}>
                Your Rights and Choices
              </span>
            </p>
            <p>
              The CCPA provides consumers (California residents) with specific
              rights regarding their personal information. This section
              describes your CCPA rights and explains how to exercise those
              rights.
            </p>
            <p>Access to Specific Information and Data Portability Rights</p>
            <p>
              You have the right to request that We disclose certain information
              to you about Our collection and use of your personal information
              over the past 12 months. Once We receive and confirm your
              verifiable consumer request, We will disclose to you any of the
              following, as requested:
            </p>
            <ul style={{ listStyleType: "disc" }}>
              <li>
                The categories of personal information We collected about you.
              </li>
              <li>
                The categories of sources for the personal information We
                collected about you.
              </li>
              <li>
                Our business or commercial purpose for collecting that personal
                information.
              </li>
              <li>
                The categories of third parties with whom We share that personal
                information.
              </li>
              <li>
                The specific pieces of personal information We collected about
                you.
              </li>
              <li>
                If we disclosed your personal information and identify the
                personal information categories that each category of recipient
                obtained.
              </li>
            </ul>
            <br />
            <p>
              <span style={{ textDecoration: "underline" }}>
                Deletion Request Rights
              </span>
            </p>
            <p>
              You have the right to request that We delete any of your personal
              information that We collected from you and retained, subject to
              certain exceptions. Once We receive and confirm your verifiable
              consumer request, We will delete (and direct our service providers
              to delete) your personal information from our records, unless an
              exception applies.
            </p>
            <p>
              We may deny your deletion request if retaining the information is
              necessary for Us or our service providers to:
            </p>
            <ol>
              <li>Comply with legal obligations.</li>
              <li>
                Detect security incidents, protect against malicious, deceptive,
                fraudulent, or illegal activity, or prosecute those responsible
                for such activities.
              </li>
              <li>
                Debug products to identify and repair errors that impair
                existing intended functionality.
              </li>
              <li>
                Make other internal and lawful uses of that information that are
                compatible with the context in which you provided it
              </li>
            </ol>
            <br />
            <p>
              <span style={{ textDecoration: "underline" }}>
                Response Timing and Format
              </span>
            </p>
            <p>
              We endeavor to respond to a verifiable consumer request within 45
              days of its receipt. If We require more time (up to 90 days), We
              will inform you of the reason and extension period in writing. If
              you have an account with Us, We will deliver our written response
              to that account. If you do not have an account with Us, We will
              deliver our written response by mail or electronically, at your
              option. Any disclosures We provide will only cover the 12-month
              period preceding the verifiable consumer request’s receipt. The
              response We provide will also explain the reasons We cannot comply
              with a request, if applicable.
            </p>
            <p>
              <span style={{ textDecoration: "underline" }}>
                Non-Discrimination
              </span>
            </p>
            <p>
              We will not discriminate against you for exercising any of your
              CCPA rights. Unless permitted by the CCPA, We will not:
            </p>
            <ul style={{ listStyleType: "disc" }}>
              <li>Deny you goods or services.</li>
              <li>
                Charge you different prices or rates for goods or services,
                including through granting discounts or other benefits, or
                imposing penalties.
              </li>
              <li>
                Provide you a different level or quality of goods or services.
              </li>
              <li>
                Suggest that you may receive a different price or rate for goods
                or services or a different level or quality of goods or
                services.
              </li>
            </ul>
            <br />
            <p>
              <span style={{ textDecoration: "underline" }}>
                Exercising Your Rights under CCPA
              </span>
            </p>
            <p>
              If you wish to exercise your rights under California law, please
              do not hesitate to contact us at:
            </p>
            <p>
              Email:{" "}
              <a href="mailto:privacy@caremetx.com">privacy@caremetx.com</a>
            </p>
            <p>Phone: 1-877-690-0220 (Toll Free)</p>
            <p>
              Only you or a person registered with the California Secretary of
              State that you authorize to act on your behalf, may make a
              verifiable consumer request related to your personal information.
            </p>
            <p>
              You may only make a verifiable consumer request for access or data
              portability twice within a 12-month period. The verifiable
              consumer request must:
            </p>
            <ul style={{ listStyleType: "disc" }}>
              <li>
                Provide sufficient information that allows Us to reasonably
                verify you are the person about whom we collected personal
                information or an authorized representative.
              </li>
              <li>
                Describe your request with sufficient detail that allow us to
                properly understand, evaluate and respond to it.
              </li>
            </ul>
            <br />
            <br />
            <p>
              Email:{" "}
              <span>
                <a href="mailto:privacyrequests@caremetx.com">
                  privacyrequests@caremetx.com
                </a>
              </span>
            </p>
            <br />
            <p>Phone: 1-877-690-0220 (Toll Free)</p>
            <p>
              Only you or a person registered with the California Secretary of
              State that you authorize to act on your behalf, may make a
              verifiable consumer request related to your personal information.
              <br />
              You may only make a verifiable consumer request for access or data
              portability twice within a 12-month period. The verifiable
              consumer request must:
            </p>
            <ul style={{ listStyleType: "disc" }}>
              <li>
                Provide sufficient information that allows Us to reasonably
                verify you are the person about whom we collected personal
                information or an authorized representative.
              </li>
              <li>
                Describe your request with sufficient detail that allow us to
                properly understand, evaluate and respond to it.
              </li>
            </ul>
          </li>
        </ol>
      </span>
    </div>
    </>
  );
};

export default PrivacyPolicy;
