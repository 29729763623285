import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GATEWAY_API_URL, REACT_APP_OCTA_URL } from "../api";
import { createUserInterface } from "../components/Interfaces/Interfaces";
import { getAuthorizationHeader } from "../utilities/utilities";

export const userInfoApi = createApi({
  reducerPath: "userInfo",
  baseQuery: fetchBaseQuery({
    baseUrl: REACT_APP_OCTA_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = getAuthorizationHeader();
      if (token) headers.set("authorization", `${token}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    userInfo: builder.query({
        query: (arg) => `/oauth2/default/v1/userinfo`,
      }),
  }),
});

export const { useUserInfoQuery } = userInfoApi;
