import {useEffect, useState} from "react";
import {AgGridColumn} from "ag-grid-react";
import * as React from "react";
import {EligibilityGrid} from "../utilities/EligibilityGrid";
import EligibilitySpinner from "../templates/EligibilitySpinner";
import {DEFAULT_GET_ELIGIBILITY_ERROR, DEFAULT_GET_USER_ERROR} from "../utilities/constants";
import {useEligibilityListQuery} from "../services/usersEligibilityListApi";
import {dateColumnDefinition} from "../utilities/ag-grid";
import {EligibilityError} from "./EligibilityError";
import {skipToken} from "@reduxjs/toolkit/query";

export const PatientEligibilityList = () => {
    const [loading, setLoading] = useState(false);

    const {
        data: userEligibilityList,
        isLoading,
        isFetching,
        isSuccess,
        isError,
        error,
        refetch
    } = useEligibilityListQuery("");

    useEffect(() => {
        refetch();
    }, [])

    if(isLoading) return <EligibilitySpinner />

    if(isError || error) return <EligibilityError />

    return (
        <>
            <h2>Patient Eligibility List</h2>
            <div className="ag-theme-alpine" >
                <EligibilityGrid
                    rowData={JSON.parse(JSON.stringify(userEligibilityList?.patientEligibilityDetails || []))}
                    pagination>
                    <AgGridColumn field='requestBy' headerName="Requested By" />
                    <AgGridColumn field='patientId' headerName="Patient ID" />
                    <AgGridColumn {...dateColumnDefinition('patientDOB')} headerName="Patient DOB"/>
                    {/* <AgGridColumn {...dateColumnDefinition('initialDose')} headerName="Initial Dose"/> */}
                    <AgGridColumn field='status' headerName="Status"/>
                    <AgGridColumn field='totalDoseDuration' headerName="Requested Vials"/>
                    <AgGridColumn field='order' headerName="Ordering Code"/>
                    <AgGridColumn {...dateColumnDefinition('eligibilityCheckDate')} headerName="Eligibility Check Date"/>
                    <AgGridColumn {...dateColumnDefinition('orderingCodeExpirationDate')} headerName="Ordering Code Expiration Date"/>

                </EligibilityGrid>
            </div>
            <p className='mt-4'>If you received an Ordering Code directly through a phone call to ServierONE, it will not be displayed in this list. Please call ServierONE at (800) 813-5905 if you need help locating an Ordering Code not displayed in this list.</p>
        </>
    );
}

