import * as React from "react";
import { Card } from "react-bootstrap";
import PasswordFormHeader from "../../templates/PasswordFormHeader";
import PasswordForm from "./PasswordForm";
import {PasswordComplexity} from "../PasswordComplexity";

export const CreatePasswordForm = () => {
  const title = 'Thanks for verifying your email address'
  const subtitle = `Now it's time to set up your password. Please enter your desired
  password and then confirm it. Once you have created your password,
  your account setup will be complete. `
  const htmlText = `All fields are required unless noted as optional.`
  return (
    <div>
      <PasswordFormHeader title={title} subtitle={subtitle} htmlText={htmlText} />
      <div className="row">
        <div className="col-5">
          <PasswordForm />
        </div>
        <div className="offset-1 col-6">
          <PasswordComplexity />
        </div>
      </div>
    </div>
  );
};
