import React, {useContext} from "react";
import {EligibilityFormContext} from "../pages/Homepage";
import questionIcon from "./../assets/images/question-blue.png"
// import questionIconGreen from "./../assets/images/questions@2x.png"
import questionIconOrange from "./../assets/images/questionsOrange@2x.png"
import resultIcon from "./../assets/images/Result@2x.png"
import resultBlueIcon from "./../assets/images/Result-blue.png"

export const EligibilityBreadcrumb = () => {
    const {step} = useContext(EligibilityFormContext);
    return (
        <ul className='eligibility registration__breadcrumb'>
            <li><b>Step {step + 1} of 2:</b></li>
            <li className={`registration__active-step registration__step ${step > 0 ? 'registration__success' : ''}`}>
                <div className='registration__icon'>
                    <img src={step === 0 ? questionIcon : questionIconOrange} alt="questions icon"/>
                </div>
                Eligibility Questions
            </li>
            <li className={step > 0 ? 'registration__active-step registration__step' : 'registration__step'}>
                <div className='registration__icon'>
                    <img src={step > 0 ? resultBlueIcon : resultIcon} alt="results icon"/>
                </div>
                Results
            </li>
        </ul>
    )
}